
// Existing import statements and component definition
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { SubmitCreateUser, getAllUsers, SubmitCreatenewUser } from "../../services/UserService";
import { Alert, IconButton, InputAdornment, Snackbar } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { JSEncrypt } from "jsencrypt";
interface FormInputs {
  FirstName: string;
  LastName: string;
  EmailId: string;
  Password: string;
  ConfirmPassword: string;
  ContactNumber: string;
  UserType: string;
}
const publicKey = process.env.REACT_APP_PUBLIC_ENCRYPT_KEY; 
const AdminPage = () => {
  const [models, setModels] = useState<FormInputs[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState<FormInputs>({
    FirstName: '',
    LastName: '',
    EmailId: '',
    Password: '',
    ConfirmPassword: '',
    ContactNumber: '',
    UserType:'Admin'
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [errors, setErrors] = useState<Partial<FormInputs>>({});
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();
  const [containerHeight, setContainerHeight] = useState('507px');
  const [buttonMarginTop, setButtonMarginTop] = useState('28px');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  
  const encryptData = (password: string) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey?publicKey:"");
    const encryptedPassword = encrypt.encrypt(password);
    return encryptedPassword;
  };
  
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setContainerHeight('514px');
      setButtonMarginTop('29px');
    } else {
      setContainerHeight('507px');
      setButtonMarginTop('28px');
    }

    if (formValid) {
      setContainerHeight('507px');
      setButtonMarginTop('29px');
    }
  }, [errors, formValid]);

  const [contactNumbers, setContactNumbers] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    };
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const users = await getAllUsers();
        if (users) {
          const numbers = users.map(user => user.ContactNumber);
          const userEmails = users.map(user => user.EmailId);
          setContactNumbers(numbers);
          setEmails(userEmails);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs(prevInputs => ({
      ...prevInputs,
      [name]: value
    }));
  
    validateField(name as keyof FormInputs, value);
  };

  const validateField = (fieldName: keyof FormInputs, value: string) => {
    const newErrors: Partial<FormInputs> = { ...errors };

    switch (fieldName) {
      case 'FirstName':
        if (value.length < 5 || value.length > 50) {
          newErrors.FirstName = 'First Name should contain 5-50 characters';
        }  else if (!/^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(value)) {
          newErrors.FirstName = 'First Name can only contain alphabets, spaces, apostrophes, and hyphens';
      } 
        else {
          newErrors.FirstName = '';
        }
        break;
      case 'LastName':
         if (value.length < 3 || value.length > 50) {
          newErrors.LastName = 'Last Name should contain 3-50 characters';
        } 
        else if (!/^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(value)) {
          newErrors.LastName = 'Last Name can only contain alphabets, spaces, apostrophes, and hyphens';
      } 
        else {
          newErrors.LastName = '';
        }
        break;
      case 'EmailId':
        if (value.length == 0)
          newErrors.EmailId = 'Email is Required';
        else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/.test(value)) {
          newErrors.EmailId = 'Email is invalid';
        } else if (emails.includes(value)) {
          newErrors.EmailId = 'Email already exists';
        } else {
          newErrors.EmailId = '';
        }
        break;
      case 'ContactNumber':
        if (!/^\d+$/.test(value)) {
          newErrors.ContactNumber = 'Contact Number should contain only numbers';
        } else if (value.length !== 10) {
          newErrors.ContactNumber = 'Contact Number should be 10 digits';
        } else if (contactNumbers.includes(value)) {
          newErrors.ContactNumber = 'Contact Number already exists';
        } else {
          newErrors.ContactNumber = '';
        }
        break;
      case 'Password':
        
        if (value.length < 8 || value.length > 12) {
          newErrors.Password = 'Password must be 8-12 characters long';
        } else if (!/[A-Z]/.test(value)) {
          newErrors.Password = 'Password must contain one uppercase letter';
        } else if (!/[a-z]/.test(value)) {
          newErrors.Password = 'Password must contain one lowercase letter';
        } else if (!/\d/.test(value)) {
          newErrors.Password = 'Password must contain one number';
        } else if (!/[!@#$%^&*]/.test(value)) {
          newErrors.Password = 'Password must contain one special character';
        } else {
          newErrors.Password = '';
        }
        break;
      case 'ConfirmPassword':
        if (value !== inputs.Password) {
          newErrors.ConfirmPassword = 'Passwords do not match';
        } else {
          newErrors.ConfirmPassword = '';
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
    setFormValid(Object.keys(newErrors).every(key => newErrors[key as keyof FormInputs] === ''));
  };

  const validateInputs = () => {
    const { FirstName, LastName, EmailId, ContactNumber, Password, ConfirmPassword } = inputs;
    const newErrors: Partial<FormInputs> = {};

    // First Name Validation
   
    if (FirstName.length < 5 || FirstName.length > 50) {
      newErrors.FirstName = 'First Name should contain 5-50 characters';
    }  else if (!/^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(FirstName)) {
      newErrors.FirstName = 'First Name can only contain alphabets, spaces, apostrophes, and hyphens';
  }  else {
      newErrors.FirstName = '';
    }

    // Last Name Validation
  
     if (LastName.length < 3 || LastName.length > 50) {
      newErrors.LastName = 'Last Name should contain 3-50 characters';
    }  else if (!/^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(LastName)) {
      newErrors.LastName = 'Last Name can only contain alphabets, spaces, apostrophes, and hyphens';
  } else {
      newErrors.LastName = '';
    }

    // Email Validation
    if (EmailId.length == 0) {
      newErrors.EmailId = 'Email is Required';
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/.test(EmailId)) {
      newErrors.EmailId = 'Email is invalid';
    } else if (emails.includes(EmailId)) {
      newErrors.EmailId = 'Email already exists';
    } else {
      newErrors.EmailId = '';
    }

    // Contact Number Validation
    if (!/^\d+$/.test(ContactNumber)) {
      newErrors.ContactNumber = 'Contact Number should contain only numbers';
    } else if (ContactNumber.length !== 10) {
      newErrors.ContactNumber = 'Contact Number should be 10 digits';
    } else if (contactNumbers.includes(ContactNumber)) {
      newErrors.ContactNumber = 'Contact Number already exists';
    } else {
      newErrors.ContactNumber = '';
    }

    // Password Validation
    if (Password.length < 8 || Password.length > 12) {
      newErrors.Password = 'Password must be 8-12 characters long';
    } else if (!/[A-Z]/.test(Password)) {
      newErrors.Password = 'Password must contain one uppercase letter';
    } else if (!/[a-z]/.test(Password)) {
      newErrors.Password = 'Password must contain one lowercase letter';
    } else if (!/\d/.test(Password)) {
      newErrors.Password = 'Password must contain one number';
    } else if (!/[!@#$%^&*]/.test(Password)) {
      newErrors.Password = 'Password must contain one special character';
    } else {
      newErrors.Password = '';
    }

    // Confirm Password Validation
    if (ConfirmPassword !== Password) {
      newErrors.ConfirmPassword = 'Passwords do not match';
    } else {
      newErrors.ConfirmPassword = '';
    }

    setErrors(newErrors);
    setFormValid(Object.keys(newErrors).every(key => newErrors[key as keyof FormInputs] === ''));
	
    return Object.keys(newErrors).every(key => newErrors[key as keyof FormInputs] === '');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const { organizationId } = useParams();
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateInputs()) {
      return;
    }
    const encryptedPassword = encryptData(inputs.Password);
    const encryptedConfirmPassword = encryptData(inputs.ConfirmPassword);
    try {
      await SubmitCreatenewUser(
        setLoading,
        setShowPopup,
        setModels,
        setShowEditPopup,
        {
          ...inputs,
          Password: encryptedPassword,
          ConfirmPassword: encryptedConfirmPassword,
          OrganizationId: organizationId 
        },
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        () => {
          // Success callback
          setTimeout(() => {
            navigate('/superadmin/organizations');
          }, 1000); // 3 seconds delay to allow snackbar to show// Navigate after snackbar delay
      }
        
    );
      
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
    <div className='addadmin-heading'>Add Admin</div>
    <div className={`addadmin ${formValid ? '' : 'has-errors'}`} style={{ height: containerHeight }}>
  
      <form onSubmit={handleSubmit}>
        <div style={{
    marginLeft: 50,
        marginTop: 14,
          height:44,
    width: 500}}>
        <TextField
            name="FirstName"
            style={{height:44,width:500}}
          label="First Name"
          value={inputs.FirstName}
          onChange={handleChange}
          error={Boolean(errors.FirstName)}
              helperText={errors.FirstName}
              sx={{
                              
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused': {
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                  },
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'grey',
                                  borderWidth:'1px',
                                
                                },
                              },
                              '& .MuiInputLabel-outlined': {
                                color: 'black',
                              },
                              '& .MuiOutlinedInput-input': {
                                color: 'black',
                                padding: '9px',
                                height: '30px', // Adjusted height
                                 // Adjusted width
                              },
                            }}
          />
          </div>
          <div style={{
    marginLeft: 50,
        marginTop: 35,
          height:44,
    width: 500}}>
        <TextField
          name="LastName"
              label="Last Name"
              style={{height:44,width:500}}
          value={inputs.LastName}
          onChange={handleChange}
          error={Boolean(errors.LastName)}
            helperText={errors.LastName}
            sx={{
                              
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey',
                  borderWidth:'1px',
                
                },
              },
              '& .MuiInputLabel-outlined': {
                color: 'black',
              },
              '& .MuiOutlinedInput-input': {
                color: 'black',
                padding: '9px',
                height: '30px', // Adjusted height
                 // Adjusted width
              },
            }}
            
          />
          </div>
          <div style={{
    marginLeft: 50,
        marginTop: 35,
          height:44,
    width: 500}}>
        <TextField
          name="EmailId"
              label="Email Id"
              style={{height:44,width:500}}
          value={inputs.EmailId}
          onChange={handleChange}
          error={Boolean(errors.EmailId)}
            helperText={errors.EmailId}
            sx={{
                              
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey',
                  borderWidth:'1px',
                
                },
              },
              '& .MuiInputLabel-outlined': {
                color: 'black',
              },
              '& .MuiOutlinedInput-input': {
                color: 'black',
                padding: '9px',
                height: '30px', // Adjusted height
                 // Adjusted width
              },
            }}
            />
          </div>
          <div style={{
    marginLeft: 50,
        marginTop: 35,
          height:44,
    width: 500}}>
        <TextField
          name="ContactNumber"
          label="Contact Number"
          value={inputs.ContactNumber}
            onChange={handleChange}
            style={{height:44,width:500}}
          error={Boolean(errors.ContactNumber)}
            helperText={errors.ContactNumber}
            sx={{
                              
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey',
                  borderWidth:'1px',
                
                },
              },
              '& .MuiInputLabel-outlined': {
                color: 'black',
              },
              '& .MuiOutlinedInput-input': {
                color: 'black',
                padding: '9px',
                height: '30px', // Adjusted height
                 // Adjusted width
              },
            }}
            />
            </div>
          <div style={{
    marginLeft: 50,
        marginTop: 35,
          height:44,
    width: 500}}>
        <TextField
          name="Password"
              label="Password"
              style={{height:44,width:500}}
          type={showPassword ? 'text' : 'password'}
          value={inputs.Password}
          onChange={handleChange}
          error={Boolean(errors.Password)}
            helperText={errors.Password}
            sx={{
                              
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey',
                  borderWidth:'1px',
                
                },
              },
              '& .MuiInputLabel-outlined': {
                color: 'black',
              },
              '& .MuiOutlinedInput-input': {
                color: 'black',
                padding: '9px',
                height: '30px', // Adjusted height
                 // Adjusted width
              },
            }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
            />
          </div>
          <div style={{
    marginLeft: 50,
        marginTop: 35,
          height:44,
    width: 500}}>
        <TextField
          name="ConfirmPassword"
            label="Confirm Password"
            style={{height:44,width:500}}
          type={showConfirmPassword ? 'text' : 'password'}
          value={inputs.ConfirmPassword}
          onChange={handleChange}
          error={Boolean(errors.ConfirmPassword)}
            helperText={errors.ConfirmPassword}
            sx={{
                              
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey',
                  borderWidth:'1px',
                
                },
              },
              '& .MuiInputLabel-outlined': {
                color: 'black',
              },
              '& .MuiOutlinedInput-input': {
                color: 'black',
                padding: '9px',
                height: '30px', // Adjusted height
                 // Adjusted width
              },
            }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
            />
          </div>
        
        <button type="submit" style={{width:70,height:31,backgroundColor:'blue',color:'white', marginTop: buttonMarginTop }}>Submit</button>
      </form>
      </div>

      <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={handleSnackbarClose}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    sx={{ zIndex: 10000 ,marginTop:4}} // Optionally adjust the zIndex if needed
>
    <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
    >
        {snackbarMessage}
    </Alert>
</Snackbar>


      </div>
    
  );
};

export default AdminPage;




















import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, TextField, Button, Link, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import { useAuth } from '../auth/AuthProvider';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { superAdminLogin } from "../../services/SuperAdminLoginService"
import { encryptPassword } from '../../services/UserService';
import { JSEncrypt } from 'jsencrypt';
import { useNavigate } from "react-router-dom";
import { GetUserDetailsById } from '../../services/UserService';
import userManager from '../auth/UseAuth';
import { LoginUserInfo } from '../../models/LoginUserInfo';
import jwt_decode from "jwt-decode";
import { getAllUsers } from '../../services/UserService';
import { getOrganizationById } from '../../services/OrganizationService';
import { Organization } from "../../models/Organization";
import { User } from "../../models/User";
import { User as Users, UserManager } from 'oidc-client-ts';
import { FormHelperText } from '@mui/material';
let SAinitialsData: string;
export default function LoginPage() {
    const { user, userDetails } = useAuth();
    let UserId: string;
    let OrganizationId: string;
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [users, setUser] = useState<Users | null>(null);
    const [error, setError] = useState<string | null>(null);
    console.log(user, "this is user")
    const [loginUserInfo, setLoginUserInfo] = useState<LoginUserInfo | undefined>(undefined);
    const [response, setresponse] = useState('');
    const [userIds, setuserId] = useState("");
    const [organizationDetails, setOrganizationDetails] = useState<Organization | null>(null);
    const [loginUserDetails, setUserDetails] = useState<User | null>(null);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const { signIn, signOut, loggedOut } = useAuth();

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };
    const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
            const publicKey = process.env.REACT_APP_PUBLIC_ENCRYPT_KEY ? process.env.REACT_APP_PUBLIC_ENCRYPT_KEY : "";
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(publicKey);
            const encryptedPassword = encrypt.encrypt(password);

            if (!encryptedPassword) {
                throw new Error('Password encryption failed');
            }
            const encrypteDpassword = encryptedPassword;
            const organizationId = "1";
            const rememberLogin = true;
            const returnUrl = ""
            const response = await superAdminLogin(email, encrypteDpassword, organizationId, rememberLogin, returnUrl);
            console.log(response);
            if (response.success) {
                // 
                UserId = response.data;
                setresponse(response);
                const userResponse = await GetUserDetailsById(UserId);
                setUserDetails(userResponse ? userResponse.data : null);
                const firstNameInitials =  userResponse?.data.FirstName &&  userResponse?.data.FirstName ? userResponse?.data.FirstName.substring(0, 1).toUpperCase() : '';
                const lastNameinitials =  userResponse?.data &&  userResponse?.data.LastName ? userResponse?.data.LastName.substring(0, 1).toUpperCase() : '';
                const finalData = firstNameInitials + lastNameinitials;
                SAinitialsData = finalData;
                localStorage.setItem("userType", userResponse?.data?.UserType ?? "");
              

                navigate("/superadmin/organizations", {
                    state: { userDetail: userResponse?.data, organizationDetails: userResponse?.data.OrganizationId }
                });
                //userManager.signinRedirect();
                //     userManager.events.addUserLoaded(async (loadedUser) => {
                //         if (loggedOut) return;  
                //         setUser(loadedUser);
                //         if (loadedUser.access_token) {
                //             const decodedToken = jwt_decode<LoginUserInfo>(loadedUser.access_token);
                //         }
                // })
                // userManager.signinRedirect();

                //navigate("/login");

                // const userResponse = await GetUserDetailsById(UserId);
                // localStorage.setItem("userType", userResponse?.data?.UserType ?? "");
                // setError(null); 
                // const token = user?.access_token || localStorage.getItem('access_token');
                // if (token) {
                //     const loggedinUserInfo = jwt_decode<LoginUserInfo>(token);
                //     setLoginUserInfo(loggedinUserInfo);
                //     await GetLoginUserInfo(loggedinUserInfo.UserId);
                // }
                // navigate("/superadmin/organizations",{
                //     state: { userDetails: userResponse?.data, organizationDetails: userResponse?.data.OrganizationId }
                // });
            } else {
                setError(response.errorMessage);
            }

        }
        catch (error) {
            console.error('Login failed:');
            setError('An unexpected error occurred.'); // Handle unexpected errors
        }
    };
    useEffect(() =>
    {
        const firstNameInitials = userDetails?.FirstName &&  userDetails?.FirstName ? userDetails?.FirstName.substring(0, 1).toUpperCase() : '';
        const lastNameinitials =  userDetails?.LastName &&  userDetails?.LastName ? userDetails?.LastName.substring(0, 1).toUpperCase() : '';
        const finalData = firstNameInitials + lastNameinitials;
        SAinitialsData = finalData;
    }, [userDetails])
    
    async function GetLoginUserInfo(UserId: string) {
        try {
            const userResponse = await GetUserDetailsById(UserId);
            const firstNameInitials =  userResponse?.data.FirstName &&  userResponse?.data.FirstName ? userResponse?.data.FirstName.substring(0, 1).toUpperCase() : '';
            const lastNameinitials =  userResponse?.data &&  userResponse?.data.LastName ? userResponse?.data.LastName.substring(0, 1).toUpperCase() : '';
            const finalData = firstNameInitials + lastNameinitials;
            SAinitialsData = finalData;
            localStorage.setItem("userType", userResponse?.data?.UserType ?? "");
        } catch (error) {
            console.error('Error fetching user or organization details', error);
            userManager.signoutRedirect();
        }
    }
    useEffect(() => {
        const token = user?.access_token || localStorage.getItem('access_token');
        if (token) {
            try {
                const loggedinUserInfo = jwt_decode<LoginUserInfo>(token);
                setLoginUserInfo(loggedinUserInfo);
                GetLoginUserInfo(loggedinUserInfo.UserId);
                UserId = loggedinUserInfo.UserId;
                console.log('User details fetched');
            } catch (error) {
                console.log(error);
                userManager.signoutRedirect();
            }
        }
        else {
            //userManager.signoutRedirect();
        }

    }, [user]);
    console.log(response);

    return (
        <Container maxWidth="sm" className="qadpt-superadminlogin">
            <Box mb={4} className="qadpt-brand-logo">
                <Typography variant="h3" className="qadpt-brand-logo-text">
                    QUICKADOPT
                </Typography>
            </Box>

            <Box className="qadpt-welcome-message">
                <Typography variant="h4" className="qadpt-welcome-message-text">
                    Welcome back
                </Typography>
            </Box>

            <Box className="qadpt-login-form">
                <Typography className="qadpt-form-label">
                    Email
                </Typography>
                <TextField
                    required
                    fullWidth
                    type="email"
                    id="email"
                    name="Email"
                    autoComplete="Email"
                    autoFocus
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="eg, name@example.com"
                    className="qadpt-custom-input"
                />

                <Typography className="qadpt-form-label">
                    Password
                </Typography>
                <TextField
                    required
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    autoComplete="password"
                    autoFocus
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Enter your password"
                    className="qadpt-custom-input"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    helperText={error && (
                        <FormHelperText error className="qadpt-text-danger">
                            {error}
                        </FormHelperText>
                    )}
                />

                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    className="qadpt-btn-default"
                    onClick={handleSubmit}
                > 
                        Continue
                </Button>
            </Box>

            <Box mt={12} className="qadpt-login-footer">
                <Typography variant="body2" className="qadpt-footer-text">
                    <Link href="/terms-of-use" className="qadpt-footer-link">Terms of use</Link> | 
                    <Link href="/privacy-policy" className="qadpt-footer-link">Privacy Policy</Link>
                </Typography>
            </Box>
        </Container>
    );
}
export {SAinitialsData}
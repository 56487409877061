import React, { useEffect, useRef, useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { translateText } from "./Translator";
import DeleteIcon from '@mui/icons-material/Delete';
import LanguageIcon from '@mui/icons-material/Language';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
//import i18next from 'i18next';
import { isSidebarOpen, subscribe } from "../components/adminMenu/sidemenustate";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, ListItemText, NativeSelect, OutlinedInput, TextField, Theme, Typography, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";
// import Cookies from "js-cookie";
// import { useTranslation } from "react-i18next";
// import { initReactI18next } from 'react-i18next';
// import HttpApi from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import lang from './../languages';
import axios from "axios";

import { getLanguages, getPlatformLabels, saveOrganizationLanguages } from "../services/MultilingualService";
//import { PlatformLabels } from "./settings/ProfileSettings";

interface Language {
  LanguageId: string;
  Language: string;
  LanguageCode: string;
  FlagIcon: string;
}
interface language {
  id: number;
  name: string;
  code: string;
}

export const availableLanguages: language[] = [
  { id: 1, name: "English", code: "en" },
  { id: 2, name: "Telugu", code: "te" },
  { id: 3, name: "Hindi", code: "hi" },
  { id: 4, name: "Arabic", code: "ar" },
];
type OrganizationLanguage = {
  OrganizationId: string;
  LanguageId: string;
  Language: string;
};

interface LanguageMap {
  [key: string]: string;
}
const ORGANIZATION_ID = '02072024-114039124-a8bcf86d-cef6-4e0c-9ecf-cb5455b6a029'; 

const Translater = ({ selectedLanguageProp }: { selectedLanguageProp: string }) => {
  // const [organizationLanguages, setOrganizationLanguages] = useState<OrganizationLanguage[]>([]);
  const [organizationLanguages, setOrganizationLanguages] = useState<OrganizationLanguage[]>(() => {
    // Retrieve from localStorage if available
    const savedLanguages = localStorage.getItem('organizationLanguages');
    return savedLanguages ? JSON.parse(savedLanguages) : [];
  });
  const [labels, setLabels] = useState<any>({});
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  const [ageTwo, setAgeTwo] = useState(30);
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [personName, setPersonName] = useState<string[]>([]);
  const [toText, setToText] = useState<string[]>([]);
  const [toLanguage, setToLanguage] = useState<string>('English');
  const [languages, setLanguages] = useState<Language[]>([]);
  const [translatedTitle, setTranslatedTitle] = useState(""); // State to hold translated title
  const [loading, setLoading] = useState(true);
  const [editLabelKey, setEditLabelKey] = useState(null); // Track the label being edited
  const [editLabelValue, setEditLabelValue] = useState("");

  const selectRef = useRef<HTMLDivElement>(null);
  const getLanguageName = (languageCode: string): string => {
    const language = languages.find(lang => lang.LanguageCode === languageCode);
    return language ? language.Language : "Unknown";
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const ToLanguageNew=getLanguageName(toLanguage)

  useEffect(() => {
   
    const fetchLabels = async (toLanguage: string) => {
      try {
        const data = await getPlatformLabels(toLanguage);
        if (data) {
          let parsedLabels;
          if (toLanguage === "English") {
            parsedLabels = JSON.parse(data.en);
          } else if (toLanguage === "Hindi") {
            parsedLabels = JSON.parse(data.hi);
          } else if (toLanguage === "Telugu") {
            parsedLabels = JSON.parse(data.te);
          } else if (toLanguage === "Arabic") {
            parsedLabels = JSON.parse(data.ar);
          }

          if (parsedLabels) {
            setLabels(parsedLabels);
            console.log('Parsed Labels:', parsedLabels); // Debugging parsed labels
          }
        }
      } catch (error) {
        console.error('Error fetching labels:', error);
      }
    };

    fetchLabels(toLanguage);
  }, [toLanguage]);

  useEffect(() => {
    const unsubscribe = subscribe(setSidebarOpen);
    return () => unsubscribe();
  }, []);



  const fetchLanguages = async () => {
    try {
      const dataNew = await getLanguages(); // Call the getLanguages function from API.tsx
      setLanguages(dataNew);
    } catch (error) {
      console.error("Error fetching languages:", error);
    } finally {
      setLoading(false);
    }
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dialogWidth = 1000; //+ languages.length * 40; // Example calculation, adjust as needed
  const dialogHeight = 500 + languages.length * 40;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [languagesList, setLanguagesList] = useState<language[]>(availableLanguages);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [triggerAdd, setTriggerAdd] = useState(false);

  const handleAddLanguage = (selectedLanguageCodes: string[]) => {
    const newLanguages = selectedLanguageCodes.filter(
      (code) => !languagesList.find((lang) => lang.code === code)
    ).map((code, index) => ({
      id: languagesList.length + index + 1,
      name: availableLanguages.find(lang => lang.code === code)?.name ?? code,
      code: code,
    }));

    setLanguagesList([...languagesList, ...newLanguages]);
    setIsDropdownVisible(false);
  };

  const handleLanguageChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    const validSelectedValues = selectedValues.filter((code: string) => availableLanguages.some(lang => lang.code === code));
    setSelectedLanguages(validSelectedValues);

   
  };
  
  useEffect(() => {
    const savedLanguages = JSON.parse(localStorage.getItem('selectedLanguages') || '[]') as string[];
    setSelectedLanguages(savedLanguages);
  }, []);

  useEffect(() => {
    if (selectedLanguages.length > 0) {
      localStorage.setItem('selectedLanguages', JSON.stringify(selectedLanguages));
    }
  }, [selectedLanguages]);
  // Effect to add languages when triggerAdd is set to true
  useEffect(() => {
    if (triggerAdd) {
      handleAddLanguage(selectedLanguages);
      setTriggerAdd(false); // Reset trigger
    }
  }, [triggerAdd, selectedLanguages]);

  // Simulate navigation or button click to add languages
  const handleNavigationOrButtonClick = () => {
    setTriggerAdd(true); // Set trigger to add languages
  };

  // const handleDeleteLanguage = (code: string) => {
  //   setSelectedLanguages(selectedLanguages.filter((lang) => lang !== code));
  // };
 
  const handleToLanguageChange = async (e: any) => {
    const newToLanguage = e.target.value as string;
    setToLanguage(newToLanguage);
  };

 
  const handleShowDropdown = () => {
    setIsDropdownVisible(true);
  };


  const handleEditLabel = (key:any,value:any) => {
    setEditLabelKey(key);
    setEditLabelValue(value);
  };

  const handleSaveLabel = (key: any) => {
    setLabels((prevLabels: any) => {
      // Create a deep copy of the previous labels to avoid mutation
      const updatedLabels = JSON.parse(JSON.stringify(prevLabels));
      
      // Update the label value in the copied labels
      const updateLabel = (labels: any, key: any, value: any) => {
        if (labels.hasOwnProperty(key)) {
          labels[key] = value;
        } else {
          // Iterate over nested objects
          Object.keys(labels).forEach(k => {
            if (typeof labels[k] === 'object') {
              updateLabel(labels[k], key, value);
            }
          });
        }
      };
  
      updateLabel(updatedLabels, key, editLabelValue);
  
      return updatedLabels;
    });
  
    setEditLabelKey(null); // Exit edit mode
    setEditLabelValue(""); // Clear the input value
  };
  
  
  const handleCancelEdit = () => {
    setEditLabelKey(null); // Exit edit mode
    setEditLabelValue(""); // Clear the input value
  };


  const [searchTerm, setSearchTerm] = useState<string>(''); // State for search term
  const handleSearchChange = (e: any) => {
      setSearchTerm(e.target.value);
      };
      const filterLabels = (labels: any, term: string) => {
          if (!term) return labels;
      
          const lowercasedTerm = term.toLowerCase();
          const filteredLabels: any = {};
      
          Object.keys(labels).forEach(key => {
            if (typeof labels[key] === 'object') {
              const nestedLabels = filterLabels(labels[key], term);
              if (Object.keys(nestedLabels).length > 0) {
                filteredLabels[key] = nestedLabels;
              }
            } else if (key.toLowerCase().includes(lowercasedTerm) || labels[key].toLowerCase().includes(lowercasedTerm)) {
              filteredLabels[key] = labels[key];
            }
          });
      
          return filteredLabels;
        };
  
        // const renderLabels = (labels: any) => {
        //   return Object.keys(labels).map((key) => {
        //     const value = labels[key];
        //     if (typeof value === "object") {
        //       return (
        //         <ul key={key}>
        //           {Object.keys(value).map((nestedKey) => (
        //             <MenuItem key={nestedKey}>{value[nestedKey]}</MenuItem>
        //           ))}
        //         </ul>
        //       );
        //     } else {
        //       return <MenuItem key={key}>{value}</MenuItem>;
        //     }
        //   });
        // };
      
  
        const renderLabels = (labels: any) => {
          return Object.keys(labels).map(key => {
            const value = labels[key];
            if (typeof value === 'object') {
              return (
                <ul key={key}>
                  {Object.keys(value).map(nestedKey => (
                    <MenuItem key={nestedKey}>
                      {editLabelKey === nestedKey ? (
                        <Box display="flex" alignItems="center">
                          <TextField
                            value={editLabelValue}
                            onChange={e => setEditLabelValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ mr: 1 }}
                          />
                          <IconButton onClick={() => handleSaveLabel(nestedKey)}>
                            <DoneIcon />
                          </IconButton>
                          <IconButton onClick={handleCancelEdit}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box display="flex" alignItems="center">
                          {value[nestedKey]}
                          <IconButton onClick={() => handleEditLabel(nestedKey, value[nestedKey])}>
                            <EditIcon />
                          </IconButton>
                        </Box>
                      )}
                    </MenuItem>
                  ))}
                </ul>
              );
            } else {
              return (
                <MenuItem key={key}>
                  {editLabelKey === key ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        value={editLabelValue}
                        onChange={e => setEditLabelValue(e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      <IconButton onClick={() => handleSaveLabel(key)}>
                        <DoneIcon />
                      </IconButton>
                      <IconButton onClick={handleCancelEdit}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center">
                      {value}
                      <IconButton onClick={() => handleEditLabel(key, value)}>
                        <EditIcon />
                      </IconButton>
                    </Box>
                  )}
                </MenuItem>
              );
            }
          });
        };
        
      
        const renderLabelsKeys = (labels: any) => {
          return Object.keys(labels).map((key) => {
            const value = labels[key];
            if (typeof value === "object") {
              return (
                <ul key={key}>
                  {Object.keys(value).map((nestedKey) => (
                    <MenuItem key={nestedKey}>{nestedKey}</MenuItem>
                  ))}
                </ul>
              );
            } else {
              return <MenuItem key={key}>{key}</MenuItem>;
            }
          });
        };
      
        const filteredLabels = filterLabels(labels, searchTerm);
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        };
          const [showDropdown, setShowDropdown] = useState(true);
  console.log(selectedLanguages, "selectedLanguages")
  
 
  const handleSave = async () => {
    try {
      const orgLanguages = selectedLanguages.map(code => {
        const language = languages.find(lang => lang.LanguageCode === code);
        return {
          OrganizationId: ORGANIZATION_ID,
          LanguageId: language?.LanguageId?.toString() ?? '', // Convert to string and handle undefined
          Language: language?.Language ?? '', // Handle undefined
        };
      }).filter(lang => lang.LanguageId && lang.Language); // Filter out invalid entries

      if (orgLanguages.length > 0) {
        const result = await saveOrganizationLanguages(orgLanguages);
        setOrganizationLanguages(orgLanguages); // Update the state variable
        localStorage.setItem('organizationLanguages', JSON.stringify(orgLanguages)); // Save to localStorage
        console.log(orgLanguages, "organizationLanguages");
        console.log('Save successful:', result);
        // Handle successful save (e.g., show a success message)
      } else {
        console.error('No valid languages to save');
        // Handle the case where there are no valid languages to save
      }
    } catch (error) {
      console.error('Save failed:', error);
      // Handle error (e.g., show an error message)
    }
  };
  const handleDeleteLanguage = async (languageId: string) => {
    try {
      // Call the delete API
      const response = await fetch(`http://localhost:60550/api/Translation/DeleteOrganizationLanguages?id=${languageId}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        // Update the selectedLanguages state to remove the deleted language
        setSelectedLanguages((prevSelectedLanguages) =>
          prevSelectedLanguages.filter((code) => code !== languageId)
        );
      } else {
        // Handle error
        console.error('Failed to delete language');
      }
    } catch (error) {
      console.error('Error deleting language:', error);
    }
  };
  
  return (
    <div className={`smooth-transition`}
     style={{ marginTop:"70px",marginLeft: sidebarOpen ? "201px" : "27px",width:sidebarOpen?"289px":"22%" }}>
      <h2>Language Translation</h2>
      <p style={{ marginTop: -15 }}>select the secondary language to modify</p>
      <p></p>
      <button className={`smooth-transition`} style={{
        marginLeft: sidebarOpen ? "826px" : "974px",
        marginTop: "-62px",
        borderRadius: "5px",
        padding: "11px 31px",
        position: "absolute",
        //zIndex: "99999",
        cursor: "pointer",
        fontSize: "14px",
        background: "transparent",
        backgroundColor: "blue",
        color: "white",
        border: "0px solid blue",
        fontWeight: "normal"
      }}onClick={handleClickOpen}
      >Manage Languages</button>
       <button className={`smooth-transition`} style={{
        position: "absolute",
        //zIndex: "99999",
        cursor: "pointer",
        fontSize: "14px",
        background: "transparent",

        color: "black",
        fontWeight: "normal",
        border: "1px solid grey",
        marginLeft: sidebarOpen ? "1020px" : "1162px",
        marginTop: "-62px",
        borderRadius: "5px",
        padding: "10px 31px"
      }}>Save</button>

      <div style={{ marginTop: 54 }}>
        <Box sx={{ padding: 1 }}>
          <TextField
            className={`smooth-transition`}
            variant="outlined"
            placeholder="Search "
            fullWidth
            style={{ marginTop: -70, marginLeft: sidebarOpen?"867px":1013 }}
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search sx={{ color: 'black' }} />
                </InputAdornment>
              ),
              sx: {
                borderRadius: '5px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                border: '1px solid black',
                height: '45px',
                marginTop: '10px',
                width: '200px',
                color: 'black'
              },
              inputProps: {
                sx: {
                  fontSize: '0.875rem',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              '& .MuiInputBase-input': {
                color: 'black',
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'black',
                opacity: 0.7,
                fontSize: '0.850rem',
              },
            }}
          />
        </Box>
      </div>

      <div style={{ marginTop: 14, display: 'flex', justifyContent: 'space-between' }}>
       
          <div style={{ width: 630, backgroundColor: "aliceblue" }}>
            <Box>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-readonly-label"></InputLabel>
                <Select
                  ref={selectRef}
                
                style={{ width:sidebarOpen?"467px": 600 }}
                  labelId="demo-simple-select-readonly-label"
                  id="demo-simple-select-readonly"
                  value={ageTwo}
                  inputProps={{ readOnly: true }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Telugu</MenuItem>
                  <MenuItem value={20}>Hindi</MenuItem>
                  <MenuItem value={30}>English</MenuItem>
                </Select>
             
               <div>{renderLabelsKeys(filteredLabels)}</div>
                 
              </FormControl>
            </Box>
          </div>
      

       









        <div style={{ width: 698, marginLeft: 39 }}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-labels">Select Language to modify</InputLabel>
              <Select className={`smooth-transition`} style={{ width: sidebarOpen?"609px":656 }}
                ref={selectRef}
                labelId="demo-simple-select-labels"
                id="demo-simple-selects"
                defaultValue="English"
                label="Select Language to modify"
                value={toLanguage}
                onChange={handleToLanguageChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <LanguageIcon sx={{ color: 'black' }} />
                    </IconButton>
                  </InputAdornment>
                }
              >

{organizationLanguages.map((lang) => (
            <MenuItem key={lang.LanguageId} value={lang.Language}>
              {lang.Language}
            </MenuItem>
          ))}
                
              </Select>
              <div>{renderLabels(filteredLabels)}</div>
            </FormControl>
          </Box>
        </div>
      </div>

      <div style={{
            marginTop: 115,
            width: 1000,
            padding: 10,
            height: 1000
          }}>
          <Dialog open={open} onClose={handleClose} PaperProps={{ style: { width: dialogWidth, height: dialogHeight } }}>
            <DialogTitle>Manage Languages</DialogTitle>
            <br></br>
        <DialogContent>
              <TextField
                style={{width:400}}
            variant="standard"
            fullWidth
            label="Primary Language"
            value="English"
            InputProps={{
              readOnly: true,
            }}
            sx={{ mb: 2 }}
              />
              {/* <div className="add-button">
          <IconButton onClick={handleAddLanguage}>
                <AddIcon />
                <Typography>Add</Typography>
                </IconButton>
                </div>  */}
    <Button style={{marginLeft:55,marginTop:15}} variant="contained" color="primary" onClick={handleShowDropdown}>
              + Add
         
      </Button>
               {isDropdownVisible ? (
     <Box display="flex" alignItems="center" mt={2}>
     <div className="add-dropdown">
       <div style={{ width: 300 }}>
         <FormControl sx={{ m: 1, width: 300 }}>
           <InputLabel id="select-language-label">Select Language</InputLabel>
           <Select
             labelId="select-language-label"
             id="select-language"
             multiple
             value={selectedLanguages}
             onChange={handleLanguageChange}
             input={<OutlinedInput label="Select Language" />}
             renderValue={(selected) => (selected as string[]).map(code => {
               const language = languagesList.find(lang => lang.code === code);
               return language ? language.name : code;
             }).join(', ')}
           >
              {availableLanguages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    <Checkbox checked={selectedLanguages.indexOf(lang.code) > -1} />
                    <ListItemText primary={lang.name} />
                  </MenuItem>
                ))}
             {/* Add more languages as needed */}
           </Select>
         </FormControl>
       </div>
                </div>
                
                
   </Box>
       ) : (
        null
            )}
            
            {selectedLanguages.length > 0 && (
        <Box mt={2}>
          {selectedLanguages.map((code) => {
            const language = availableLanguages.find(lang => lang.code === code);
            return language ? (
              <Box key={language.id} display="flex" alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  style={{ width: 400 }}
                  variant="standard"
                  fullWidth
                  label="Selected Language"
                  value={language.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ mr: 1 }}
                />
                 <IconButton onClick={() => handleDeleteLanguage(language.code)}>
                      <DeleteIcon />
                    </IconButton>
              </Box>
            ) : null;
          })}
        </Box>
      )}
        </DialogContent>
          <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>

        </Dialog>
          </div>
    
    </div>
  );
}

export default Translater;


import { AuditLog } from "../models/Auditlog";
import { SearchParams } from "../models/SearchParams";
import {adminApiService,userApiService} from "./APIService";


// ---------------------------------Audit Logs -------------------------------




export const GetAuditLogsByOrganizationId = async (): Promise<AuditLog[]> => {
  try {
    
		const response = await adminApiService.get<AuditLog[]>(`/AuditLog/GetAuditLogsByOrganizationId`);
    	return response.data;
  }
  catch (error) {
    console.error(`Error fetching GetAuditLogsByOrganizationId :`, error);
    throw error;
  }
};
export const GetAuditLogsBySearch = async (
  skip:any, top:any, organizationId:any, orderByFields:any, filters:any, 
  fromDate:any, toDate:any, setTotalcount:any, setAuditLogs:any, type:any,
  referenceType:any,name:any,createdUser:any
) => {
  try {
    const requestBody = {
      skip,
      top,
      fromDate: fromDate || "",
      toDate: toDate || "",
      name: name || "",
      orderByFields: orderByFields || "", 
      filters: filters || "" , 
      createdUser: createdUser || "", // Add this if you are filtering by createdUser
      type: type || "", // Add this if you are filtering by type
      referenceType: referenceType || "", // Add this if you are filtering by referenceType
      organizationId: organizationId,
    };

    const response = await adminApiService.post(`/AuditLog/GetAuditLogsBySearch`, requestBody);

    if (response.data) {
      const apiData = response.data.results;
      setTotalcount(response.data.__count);
      setAuditLogs(apiData);
      return apiData; // Return data to allow for chaining
    } else {
      console.error("No data received from the server.");
      return null;
    }
  } catch (error) {
    console.error('Error fetching audit logs:', error);
    return null;
  }
};

export const fetchOrganizations = async (setOrganizations: any, setLoading: any) => {
    try {
        const response = await adminApiService.get("/Organization/GetAllOrganizations");
        const apiData = response.data;
 
        if (apiData && typeof apiData === 'object') {
          // const organizationName = apiData.Name; // Extract the Name property
          // console.log('Organization Name:', organizationName);

          setOrganizations([apiData]); // Set the organization as an array
          return apiData;
      } else {
          console.error("Invalid data format from API:", apiData);
      }
    } catch (error) {
        console.error("Error fetching organizations:", error);
       
    }
    finally {
        setLoading(false);
    }
 
};
 

export default GetAuditLogsByOrganizationId;

// LanguageContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

type LanguageContextType = {
  fromLanguage: string;
  selectedLanguage: string;
  setFromLanguage: (language: string) => void;
  setSelectedLanguage: (language: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [fromLanguage, setFromLanguage] = useState('en-GB');
  const [selectedLanguage, setSelectedLanguage] = useState('hi-IN');

  return (
    <LanguageContext.Provider value={{ fromLanguage, selectedLanguage, setFromLanguage, setSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

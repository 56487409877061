import React, { useState, useEffect } from "react";
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Menu, MenuItem, FormControlLabel, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import loader from "../../assets/loader.gif";
import CustomGrid from "../common/Grid";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import AddAdmin from "./AddAdmin";
import { DeleteUserDetails, SubmitCreatenewUser, fetchUserDataFromApi, getAllUsers } from "../../services/UserService";

interface User {
	UserId: string | null;
	FirstName: string | null;
	LastName: string | null;
	EmailId: string;
	ContactNumber: string | null;
	UserType: string;
}

const AdminList: React.FC = () => {
	const [models, setModels] = useState<User[]>([]);
	const [loading, setLoading] = useState(false);
	const [inputs, setInputs] = useState({});
	const [emailiddelete, setemailiddelete] = useState("");
	const [useridedit, setUserIdEdit] = useState("");

	const MatEdit = (number: any) => {
		const handleMailClick = (type: any) => {
			// some action
			console.log(type);
		};
		const handleDeleteClick = (emailId: string) => {
			setShowDeletePopup(true);
			console.log(emailId);
			setemailiddelete(emailId);
		};
		const handleeditclick = (userid: string) => {
			setShowEditPopup(true);
			console.log(userid);
			setUserIdEdit(userid);
		};

		return (
			<div>
				<FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => handleMailClick("mail")}
						>
							{number.emailConfirmed === true ? (
								<MarkEmailReadIcon style={{ color: "blue" }} />
							) : (
								<MailIcon style={{ color: "blue" }} />
							)}
						</IconButton>
					}
					label={""}
				/>
				<FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => {
								//openEditPopup();
								handleeditclick(number.userid);
							}}
						>
							<EditIcon style={{ color: "blue" }} />
						</IconButton>
					}
					label={""}
				/>
				<FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => handleDeleteClick(number.emailId)}
						>
							<DeleteIcon style={{ color: "blue" }} />
						</IconButton>
					}
					label={""}
				/>
			</div>
		);
	};

	const columns: GridColDef[] = [
		{ field: "FirstName", headerName: "First Name", width: 200 },
		{ field: "LastName", headerName: "Last Name", width: 200 },
		{ field: "EmailId", headerName: "Email Id", width: 300 },
		{ field: "ContactNumber", headerName: "Contact Number", width: 200 },
		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 300,
			renderCell: (params) => {
				const emailConfirmed = params.row.EmailConfirmed || false;
				const emailId = params.row.EmailId || false;
				const userid = params.row.UserId || false;
				return (
					<div
						className="d-flex justify-content-between align-items-center"
						style={{ cursor: "pointer" }}
					>
						<MatEdit
							emailConfirmed={emailConfirmed}
							emailId={emailId}
							index={params.row.id}
							userid={userid}
						/>
					</div>
				);
			},
		},
	];

	const onPageChange = (newPage: number) => {
		console.log("Page changed to:", newPage);
	};

	const onPageSizeChange = (newPageSize: number) => {
		console.log("Page size changed to:", newPageSize);
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await getAllUsers();
				if (response) {
					const usersWithIds: User[] = response.map((User, index) => ({
						UserId: User.UserId || `${index}`, // Assuming userId or index as ID
						FirstName: User.FirstName || null,
						LastName: User.LastName || null,
						EmailId: User.EmailId,
						ContactNumber: User.ContactNumber || null,
						UserType: User.UserType,
					}));
					setModels(usersWithIds);
				} else {
					console.error("Received null response from API");
				}
			} catch (error) {
				console.error("Error fetching users:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);
	useEffect(() => {
		fetchUserDataFromApi(setModels, setLoading);
	}, []);
	const [showPopup, setShowPopup] = useState(false);
	const [showeditPopup, setShowEditPopup] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);

	const openPopup = () => {
		setShowPopup(true);
	};
	const openEditPopup = () => {
		setShowEditPopup(true);
	};
	const handleDownloadExcel = async () => {
		try {
			// Handle download logic here
		} catch (error) {
			console.error("Error downloading Excel:", error);
		}
	};

	const CustomToolbar: React.FC<any> = () => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleExportMenuClose = () => {
			setAnchorEl(null);
		};

		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
				<Button
					aria-controls="export-menu"
					aria-haspopup="true"
					onClick={handleExportMenuClick}
					style={{ marginLeft: "10px" }}
					startIcon={<SaveAltIcon />}
				>
					Export
				</Button>
				<Menu
					id="export-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleExportMenuClose}
				>
					<MenuItem
						onClick={() => {
							handleDownloadExcel();
							handleExportMenuClose();
						}}
					>
						Download Excel
					</MenuItem>
				</Menu>
			</div>
		);
	};

	const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const name = event.target.name as string;
		const value = event.target.value;
		setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		console.log(inputs);
	};
	const handleSubmituser = (event: any) => {
		// 	event.preventDefault();
		// 	console.log(inputs);
		// 	SubmitCreatenewUser(setLoading, setShowPopup, setModels, setShowEditPopup, inputs);
	};
	const handleReset = () => {
		setInputs({});
	};
	const handleDeleteuser = () => {
		DeleteUserDetails(emailiddelete, setShowDeletePopup, fetchUserDataFromApi, setModels, setLoading);
	};
	return (
		<div>
			<div>
				<h1>User List</h1>
				<button
					onClick={openPopup}
					className="userButton"
				>
					Create User
				</button>
			</div>
			{loading ? (
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						zIndex: 1,
					}}
				>
					<img
						src={loader}
						alt="Spinner"
						style={{
							width: "45px",
							height: "45px",
						}}
					/>
					<br />
				</div>
			) : (
				<CustomGrid
					rows={models}
					columns={columns}
					pageSize={100}
					totalRows={models.length}
					onPageChange={onPageChange}
					onPageSizeChange={onPageSizeChange}
					rowsPerPageOptions={[10, 20, 50, 100]}
					Toolbar={CustomToolbar}
				/>
			)}

			{showeditPopup ? (
				<AddAdmin
					showEditPopup={showeditPopup}
					setShowEditPopup={setShowEditPopup}
					userId={useridedit}
					getAllUsers={getAllUsers}
					setModels={setModels}
					setLoading={setLoading}
				/>
			) : (
				""
			)}

			{showDeletePopup ? (
				<div className="userDelete-popup">
					<div>
						<svg
							onClick={() => setShowDeletePopup(false)}
							className="close-icon"
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="24"
							height="24"
							viewBox="0 0 50 50"
						>
							<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
						</svg>
					</div>
					<div style={{ width: "200px" }}>
						<p>Are you sure you want to delete User</p>
						<button
							style={{
								position: "relative",
								background: "#fff",
								color: "#007bff",
								border: "1px solid #007bff",
								marginRight: "27px",
								right: "0px",
								top: "10px",
							}}
							onClick={() => setShowDeletePopup(false)}
							className="userButton"
						>
							Cancel
						</button>

						<button
							style={{ position: "relative", right: "0px", top: "10px" }}
							className="userButton"
							onClick={handleDeleteuser}
						>
							Delete
						</button>
					</div>
				</div>
			) : (
				""
			)}

			{showPopup ? (
				<div className="user-popup">
					<div>
						{/* <h1>Create User</h1> */}
						<svg
							onClick={() => setShowPopup(false)}
							className="close-icon"
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="24"
							height="24"
							viewBox="0 0 50 50"
						>
							<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
						</svg>
					</div>
					<form onSubmit={handleSubmit}>
						<input
							type="text"
							name="FirstName"
							onChange={handleChange}
							placeholder="First Name*"
							required
						></input>
						<br></br>
						<input
							type="text"
							name="LastName"
							onChange={handleChange}
							placeholder="Last Name "
							required
						></input>
						<br></br>
						<input
							type="EmailId"
							name="EmailId"
							onChange={handleChange}
							placeholder="Email Id *"
							required
						></input>
						<br></br>
						<input
							type="password"
							name="Password"
							onChange={handleChange}
							placeholder="Password *"
						></input>

						<input
							type="password"
							name="ConfirmPassword"
							onChange={handleChange}
							placeholder="Password *"
						></input>

						<input
							type="text"
							name="ContactNumber"
							onChange={handleChange}
							placeholder="Contact Number *"
						></input>
						<br></br>
					</form>
					<div style={{ marginLeft: "115px" }}>
						<button
							style={{
								position: "relative",
								background: "#fff",
								color: "#007bff",
								border: "1px solid #007bff",
								marginRight: "27px",
								right: "0px",
							}}
							onClick={() => setShowPopup(false)}
							className="userButton"
						>
							Cancel
						</button>
						{/* <button
							style={{ position: "relative", right: "13px" }}
							className="userButton"
							onClick={handleReset}
						>
							Reset
						</button> */}
						<button
							style={{ position: "relative", right: "0px" }}
							className="userButton"
							onClick={handleSubmituser}
						>
							Saves
						</button>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default AdminList;

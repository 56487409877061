import React, { useState, useEffect, ChangeEvent, FocusEvent, FormEvent } from "react";
import {
	TextField,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	SelectChangeEvent,
	Snackbar,
	Alert,
	Box,
} from "@mui/material";
import { BlockUser } from "../../services/UserService";
import { adminUrl } from "../../services/APIService";
import { useSnackbar } from "../../SnackbarContext";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { JSEncrypt } from "jsencrypt";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import styles from "./UserStyles.module.scss";
import { useAuth } from "../auth/AuthProvider";

type InputFields = {
	Password: string;
};

type ErrorFields = Partial<InputFields>;

const Userblock = (props: any) => {
	const {
		blockUserPopup,
		setBlockUserPopup,
		userId,
		fetchUsersList,
		setModels,
		setLoading,
		skip,
		top,
		//Organizationid,
		setTotalcount,
		orderByFields,
		filters,
	} = props;
	const { openSnackbar } = useSnackbar();
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		setLoading(true);
		try {
			await BlockUser(
				setModels,
				setLoading,
				OrganizationId,
				skip,
				top,
				setTotalcount,
				userId,
				openSnackbar,
				orderByFields,
				filters
			);

			setLoading(false);
			setBlockUserPopup(false);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		blockUserPopup && (
			<div
				className={styles["qadpt-userunblockpopup"]}
				style={{ height: "37vh", right: "500px", top: "200px" }}
			>
				{" "}
				<div style={{ position: "relative", left: "0px", top: "20px" }}>
					<div
						style={{
							width: "50px",
							height: "50px",
							backgroundColor: "#f1cbcb",
							borderRadius: "50%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							margin: "0 auto",
						}}
					>
						<LockOutlinedIcon sx={{ color: "#D05353" }} />
					</div>
				</div>
				<div>
					<h3
						style={{
							margin: "10px 0",
							fontSize: "18px",
							color: "#333",
							left: "110px",
							top: "10px",
							position: "relative",
						}}
					>
						{" "}
						Block user
					</h3>
					<div style={{ width: "330px" }}>
						<p style={{ backgroundColor: "#f1cbcb", position: "relative" }}>
							Blocking this user will remove all his access from this platform.
						</p>
						<svg
							onClick={() => setBlockUserPopup(false)}
							className="close-icon"
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="24"
							height="24"
							viewBox="0 0 50 50"
						>
							<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
						</svg>
					</div>
					<form onSubmit={handleSubmit}>
						<div style={{ marginLeft: "40px", display: "flex", gap: "20px" }}>
							<button
								style={{
									position: "relative",
									background: "#fff",
									color: "#007bff",
									border: "1px solid #007bff",
									marginRight: "27px",
									right: "0px",
									top: "25px",
								}}
								onClick={() => setBlockUserPopup(false)}
								className="userButton"
							>
								Cancel
							</button>
							<button
								style={{ position: "relative", right: "-20px", top: "25px", backgroundColor: "#D05353" }}
								className="userButton"
								type="submit"
							>
								Block
							</button>
						</div>
					</form>
				</div>
			</div>
		)
	);
};

export default Userblock;

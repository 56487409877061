// axiosInterceptor.ts
import axios from 'axios';
import userManager from '../components/auth/UseAuth';


export const adminUrl = process.env.REACT_APP_ADMIN_API;
export const userUrl = process.env.REACT_APP_USER_API;
export const idsUrl = process.env.REACT_APP_IDS_API;

export type JToken = any;

const adminApiService = axios.create({    
  baseURL: adminUrl, 
});

const userApiService = axios.create({    
  baseURL: userUrl,  
});

const idsApiService = axios.create({
  baseURL:idsUrl,
})

idsApiService.interceptors.request.use(
  async (config) => {
    const user = await userManager.getUser();
    const token = user?.access_token || localStorage.getItem("access_token");
        if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

adminApiService.interceptors.request.use(
  async (config) => {
    const user = await userManager.getUser();
    const token = user?.access_token || localStorage.getItem("access_token");
        if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


userApiService.interceptors.request.use(
  async (config) => {
    const user = await userManager.getUser();
    const token = user?.access_token || localStorage.getItem("access_token");
        if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export { userApiService, adminApiService, idsApiService };
import React from "react";
import { GridColumnMenu, GridColumnMenuHideItem, GridColumnMenuProps } from "@mui/x-data-grid";
import UserCustomColumnMenuUserItem from "./UserCustomColumnMenuUserItem";

const UserCustomColumnMenu: React.FC<
	GridColumnMenuProps & {
		column: any;
		skip: any;
		top: any;
		OrganizationId: any;
		setTotalcount: any;
		orderByFields: any;
		setModels: any;
		setLoading: any;
		filters: any;
		setFilters: any;
		options: string[];
		onSearch: (value: string[]) => void;
		searchText: any;
		setSearchText: any;
		selectedOptions: any;
		setSelectedOptions: any;
	}
> = (props) => {
	const {
		column,
		skip,
		top,
		OrganizationId,
		setTotalcount,
		orderByFields,
		setModels,
		setLoading,
		filters,
		setFilters,
		options,
		onSearch,
		searchText,
		setSearchText,
		selectedOptions,
		setSelectedOptions,
		...other
	} = props;

	return (
		<GridColumnMenu
			{...other}
			slots={{
				columnMenuUserItem: (menuProps) => (
					<UserCustomColumnMenuUserItem
						column={column}
						setModels={setModels}
						setLoading={setLoading}
						skip={skip}
						top={top}
						OrganizationId={OrganizationId}
						setTotalcount={setTotalcount}
						orderByFields={orderByFields}
						filters={filters}
						setFilters={setFilters}
						options={options}
						onSearch={onSearch}
						searchText={searchText}
						setSearchText={setSearchText}
						selectedOptions={selectedOptions}
						setSelectedOptions={setSelectedOptions}
					/>
				),
				GridColumnMenuHideItem: null,
			}}
			slotProps={{
				columnMenuUserItem: {
					displayOrder: 15,
				},
			}}
		/>
	);
};

export default UserCustomColumnMenu;

import axios from "axios";
const adminUrl = process.env.REACT_APP_ADMIN_API



export const getLanguages = async () => {
    try {
      const response = await axios.get(`${adminUrl}/Translation/GetLanguages`);
      const apiData = response.data;
  
      if (Array.isArray(apiData)) {
        return apiData; // Return the array of languages
      } else {
        console.error("Invalid data format from API:", apiData);
        return []; // Return an empty array or handle error as needed
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
      return []; // Return an empty array or handle error as needed
    }
  };
  export const getPlatformLabels = async (language: string, module: string = '') => {
      const response = await axios.get(`${adminUrl}/Translation/GetPlatformLabels`, {
        params: {
          language,
          module,
        },
      });
      return response.data;
  };
  export interface OrganizationLanguages {
    Id: string;
    OrganizationId: string;
    LanguageId: string;
    Language: string;
    LanguageExpertUserId: string;
    LanguageExpertMailId: string;
  }
  
  
export const saveOrganizationLanguages = async (organizationLanguages: { OrganizationId: string, LanguageId: string, Language: string }[]) => {
	try {
	  const response = await axios.post(`${adminUrl}/Translation/SaveOrganizationLanguages`, organizationLanguages);
		
		console.log(response.data, "response.data")
		return response.data;
		
	} catch (error) {
	  console.error('Error saving organization languages:', error);
	  throw error;
	}
};
  

    
  
  interface PlatformLabel {
      Id: string;
      LabelName: string;
      Module: string;
      SecondaryLanguageLable: string;
    }
    
    // Function to update platform labels
    const updatePlatformLabels = async (labels: PlatformLabel[], language: string) => {
      try {
        const response = await axios.post('/api/UpdatePlatformLabels', { platFormLabels: labels, language });
        return response.data; // Handle response data as needed
      } catch (error) {
        console.error('Error updating platform labels:', error);
        throw error; // Optionally re-throw or handle the error
      }
    };
    
  
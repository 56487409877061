import React, { ReactEventHandler } from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

interface PopupProps {
	onClose: ReactEventHandler
	onOk: ReactEventHandler
	type: string
	title: string
	description: string
	button1: string
	button2: string
}

const Popup: React.FC<PopupProps> = ({ onClose, onOk, type, title, description, button1, button2 }) => {
	return (
		<>
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundColor: "rgba(0, 0, 0, 0.5)",
					zIndex: 998,
				}}
			></div>

			<div
				style={{
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "452px",
					height: "172px",
					backgroundColor: "white",
					boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
					zIndex: 999,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					borderRadius: "9px",
				}}
			>
				<div className="popup-title">
					<Typography
						sx={{
							fontSize: "16px",
							fontWeight: 600,
							margin: "9px",
						}}
					>
						{title}
					</Typography>
					<IconButton
						style={{ position: "absolute", right: "10px", zIndex: 10000 }}
						onClick={onClose}
					>
						<CloseIcon />
					</IconButton>
				</div>
				<Typography className="popup-desc">{description}</Typography>
				<div className="popup-buttons">
					<Button
						variant="outlined"
						onClick={onClose}
						style={{
							width: "120px",
							height: "39px",
							color: "#505052",
							borderColor: "#505052",
							backgroundColor: "#FFFFFF",
						}}
					>
						<Typography
							sx={{
								fontSize: "14px",
								color: "#505052",
								textAlign: "center",
								textTransform: "none",
							}}
						>
							{button1}
						</Typography>
					</Button>

					<Button
						variant="contained"
						onClick={onOk}
						style={{ margin: "0px 9px", width: "120px", height: "39px", backgroundColor: "#3f51b5", color: "#0565FF" }}
					>
						<Typography
							sx={{
								fontSize: "14px",
								color: "#FFFFFF",
								textAlign: "center",
								textTransform: "none",
							}}
						>
							{button2}
						</Typography>
					</Button>
				</div>
			</div>
		</>
	)
}

export default Popup

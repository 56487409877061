import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  CssBaseline,
  Divider,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { Search, ChevronLeft, ChevronRight, MoreVert } from '@mui/icons-material';
import { deleteGuide, getAllGuides } from '../../services/AccountService';
import CreateGuide from './GuideCreate';

export function GuideEdit() {
  const [open, setOpen] = useState(true);
  const [guides, setGuides] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStep, setSelectedStep] = useState<any>(null);
  const [showCreateGuide, setShowCreateGuide] = useState(false);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const fetchedGuides = await getAllGuides();
        setGuides(fetchedGuides);
      } catch (error) {
        console.error('Error fetching guides:', error);
      }
    };
    fetchGuides();
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, step: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedStep(step);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClone = () => {
    if (selectedStep) {
      const newGuide = {
        ...selectedStep,
        title: `${selectedStep.title} (Clone)`
      };
      const updatedGuides = [...guides, newGuide];
      setGuides(updatedGuides);
      // Save the updated guides if needed
      handleMenuClose();
    }
  };

  const handleDelete = async () => {
    if (selectedStep) {
      try {
        await deleteGuide(selectedStep.GuideId);
        const updatedGuides = guides.filter(guide => guide.GuideId !== selectedStep.GuideId);
        setGuides(updatedGuides);
        handleMenuClose();
      } catch (error) {
        console.error('Error deleting guide:', error);
      }
    }
  };

  const handleCreateJourneyClick = () => {
    setShowCreateGuide(true);
  };

  if (showCreateGuide) {
    return <CreateGuide />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          width: 300,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 300, boxSizing: 'border-box', backgroundColor: '#1A1A2E', color: '#FFFFFF' },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#273142', color: '#FFFFFF', justifyContent: 'center' }}>
          <Typography noWrap component="div" sx={{ fontWeight: 'bold' }}>
            QuickAdopt
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, justifyContent: 'space-between' }}>
          <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold' }}>
            Journeys
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1E88E5', color: '#FFFFFF', borderRadius: '8px', fontSize: '0.79rem', padding: '4px 8px', textTransform: 'none' }}
            onClick={handleCreateJourneyClick}
          >
            + Journey
          </Button>
        </Box>
        <Box sx={{ padding: 1 }}>
          <TextField
            variant="outlined"
            placeholder="Search for a journey"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: '#FFFFFF' }} />
                </InputAdornment>
              ),
              sx: {
                borderRadius: '8px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderColor: 'transparent',
                height: '40px',
              },
              inputProps: {
                sx: {
                  fontSize: '0.875rem',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              '& .MuiInputBase-input': {
                color: '#FFFFFF',
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#FFFFFF',
                opacity: 0.7,
                fontSize: '0.850rem',
              },
            }}
          />
        </Box>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
        <List>
          {guides.map((guide, index) => (
            <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <ListItemText primary={guide.Title} sx={{ color: '#FFFFFF' }} />
              <IconButton sx={{ color: '#FFFFFF' }} onClick={(e) => handleMenuClick(e, guide)}>
                <MoreVert />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: open ? 'calc(100% - 300px)' : '100%',
          transition: 'width 0.3s',
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            height: '50px',
            width: '25px',
            position: 'fixed',
            left: open ? 300 : 0,
            top: "50%",
            zIndex: 1300,
            backgroundColor: '#1A1A2E',
            color: '#FFFFFF',
            borderRadius: '6px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            transform: 'translateY(-50%)',
            '&:hover': {
              backgroundColor: '#1A1A2E',
            },
          }}
        >
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
        <Typography variant="h4">Main Content Area</Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#1A1A2E',
            color: '#FFFFFF',
          },
        }}
      >
        <MenuItem onClick={handleClone}>Clone</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Box>
  );
}

export default GuideEdit;

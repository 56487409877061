import React,{useEffect,useState} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../components/auth/AuthProvider";
import { useLocation } from "react-router-dom";
import { SuperAdmin } from "../models/Superadminuser";
import { logout } from "../services/AuthService";
import { User } from "../models/User";
import { adminApiService } from "../services/APIService";

interface ProtectedRouteProps {
  allowedUserTypes: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedUserTypes }) => {
  const location = useLocation();
  const { user,userDetails, signOut,signIn,loggedOut } = useAuth();
  const [AdminDetails, setUserDetail] = useState<SuperAdmin | null>(location.state?.userDetail || null);
  const [loginUserDetails, setLoginUserDetail] = useState<User|null> ();


  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'logout-event') {
        signOut();
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };

  }, [signOut]);
  
  useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString) { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					if (parsedUser) {
						setLoginUserDetail(parsedUser);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		else if (userDetails) {
			setLoginUserDetail(userDetails);				
		}
	}, []);

  
  useEffect(() => {
    if (location.pathname === "/superadmin/organizations" && AdminDetails === null) {
      setUserDetail(location.state.userDetail);
    }
  }, [location]);
  
  const loginUser = userDetails || loginUserDetails || AdminDetails || null;
  const userType = typeof loginUser === 'object' && loginUser?.UserType?.toLowerCase() || "";

  if ((!user || !loginUser) && userType !== "superadmin") {    
       
    if (loggedOut) {
      signOut();
      return <Navigate to="/login" replace />;
    }  
    
    }
    
  if (loginUser !== null && userType !== null && userType !== "SuperAdmin") {
      
    if (!allowedUserTypes.includes(userType) && !userType) {
      // If user type is not allowed, redirect to home or an unauthorized page
      return <Navigate to="/" replace />;
    }
    }


  return <Outlet />;
};

export default ProtectedRoute;

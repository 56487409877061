import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { MenuItem, ListItemText, Button, Box, Chip } from "@mui/material";
import { fetchUsersList } from "../../services/UserService";

const UserCustomColumnMenuUserItem: React.FC<{
	column: any;
	skip: any;
	top: any;
	OrganizationId: any;
	setTotalcount: any;
	orderByFields: any;
	setModels: any;
	setLoading: any;
	filters: any;
	setFilters: any;
	options: string[];
	onSearch: (value: string[]) => void;
	searchText: any;
	setSearchText: any;
	selectedOptions: any;
	setSelectedOptions: any;
}> = ({
	column,
	skip,
	top,
	OrganizationId,
	setTotalcount,
	orderByFields,
	setModels,
	setLoading,
	filters,
	setFilters,
	options,
	onSearch,
	searchText,
	setSearchText,
	selectedOptions,
	setSelectedOptions,
}) => {
	const [filteredOptions, setFilteredOptions] = useState<string[]>(options);

	useEffect(() => {
		if (column === "AdminDeactivated") {
			setFilteredOptions(["Active", "Inactive"]);
		} else {
			setFilteredOptions(options.filter((option) => option.toLowerCase().includes(searchText.toLowerCase())));
		}
	}, [searchText, options, column]);

	useEffect(() => {
		if (column !== "AdminDeactivated") {
			setFilteredOptions(options.filter((option) => option.toLowerCase().includes(searchText.toLowerCase())));
		}
	}, [options]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const handleClearSearch = () => {
		setSearchText("");
		setSelectedOptions([]);
	};

	const handleSelectAll = () => {
		const newSelectedOptions = selectedOptions.length === filteredOptions.length ? [] : filteredOptions;
		setSelectedOptions(newSelectedOptions);
	};

	const handleOptionChange = (event: React.SyntheticEvent, newValue: string[]) => {
		setSelectedOptions(newValue);
	};

	const handleSearchClick = () => {
		onSearch(selectedOptions.length ? selectedOptions : [searchText]);
		if (column === "EmailId" || column === "ContactNumber") {
			const searchValue = selectedOptions.length ? selectedOptions : [searchText];
			const combinedValue = searchValue.join(",");

			const searchCriteria = [
				{
					FieldName: column,
					ElementType: "string",
					Condition: "in",
					Value: combinedValue,
					IsCustomField: false,
				},
			];
			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, searchCriteria);
		} else if (column === "AdminDeactivated") {
			const searchValue = selectedOptions.length ? selectedOptions : [searchText];
			const booleanSearchValue = searchValue.map((val: any) => (val === "Inactive" ? "true" : "false")).join(",");

			const searchCriteria = [
				{
					FieldName: column,
					ElementType: "boolean",
					Condition: "in",
					Value: booleanSearchValue,
					IsCustomField: false,
				},
			];

			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, searchCriteria);
		}
	};

	const handleCancelClick = () => {
		setSearchText("");
		setSelectedOptions([]);
		onSearch([]);
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
	};

	return (
		<div style={{ padding: "8px 12px", display: "flex", flexDirection: "column" }}>
			<Autocomplete
				multiple
				options={filteredOptions}
				disableCloseOnSelect
				value={selectedOptions}
				onChange={handleOptionChange}
				renderOption={(props, option, { selected }) => (
					<>
						{option === filteredOptions[0] && (
							<MenuItem
								key="select-all"
								onClick={handleSelectAll}
							>
								<Checkbox
									checked={selectedOptions.length === filteredOptions.length}
									indeterminate={selectedOptions.length > 0 && selectedOptions.length < filteredOptions.length}
								/>
								<ListItemText primary="Select All" />
							</MenuItem>
						)}
						<MenuItem {...props}>
							<Checkbox checked={selected} />
							{option}
						</MenuItem>
					</>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						label="Search"
						placeholder="Search..."
						value={searchText}
						onChange={handleSearchChange}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									<div style={{ position: "absolute", right: 0 }}>
										<IconButton onClick={handleClearSearch}>
											<ClearIcon />
										</IconButton>
									</div>
								</>
							),
						}}
						sx={{
							"& .MuiAutocomplete-inputRoot": {
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								overflow: "hidden",
							},
							"& .MuiAutocomplete-input": {
								flexGrow: 1,
								overflow: "hidden",
								textOverflow: "ellipsis",
							},
							"& .MuiChip-root": {
								margin: "2px 0",
								maxWidth: "calc(100% - 48px)",
								overflow: "hidden",
								textOverflow: "ellipsis",
							},
						}}
					/>
				)}
				renderTags={(value: string[], getTagProps) =>
					value.map((option, index) => (
						<Chip
							label={option}
							{...getTagProps({ index })}
							style={{ margin: 2, maxWidth: "calc(100% - 48px)" }}
						/>
					))
				}
			/>
			<Box
				display="flex"
				justifyContent="flex-end"
				mt={2}
			>
				<Button
					variant="outlined"
					color="primary"
					onClick={handleSearchClick}
				>
					OK
				</Button>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleCancelClick}
					style={{ marginLeft: "8px" }}
				>
					Cancel
				</Button>
			</Box>
		</div>
	);
};

export default UserCustomColumnMenuUserItem;

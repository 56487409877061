import {adminApiService,userApiService} from "./APIService";
import { updateProfile, GetUserDetails } from "../models/ProfileSettingPage";
import { Status, Statuses } from "../models/Status";

export const UpdateProfile = async (userData: updateProfile): Promise<Statuses> => {
    try {
        const response = await adminApiService.post<Statuses>("/User/UpdateProfile", userData);
        return response.data;
    } catch (error) {
        console.error("Error updating profile:", error);
        throw error;
    }
};
export const GetUserDetail = async (): Promise<GetUserDetails[]> => {
	try {
		const response = await adminApiService.get<GetUserDetails[]>(`/User/GetAllUsersForAllOrganizations`);
		return response.data;
	} catch (error) {
		console.error(`Error fetching GetUserDetails :`, error);
		throw error;
	}
};

export const ChangePassword = async (UserId:any,currentPassword: any, newPassword: any): Promise<Statuses> => {
    try {
        const response = await userApiService.post<Statuses>("/User/ChangePassword", {
            UserId:UserId,
            CurrentPassword: currentPassword,
            NewPassword: newPassword,
        });
        return response.data;
    } catch (error) {
        console.error("Error changing password:", error);
        throw error;
    }
};
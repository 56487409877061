// sidebarState.js
let sidebarOpen = true;
let listeners:any = [];

export const isSidebarOpen = () => sidebarOpen;

export const setSidebarOpen = (isOpen:boolean) => {
  sidebarOpen = isOpen;
  listeners.forEach((listener:any) => listener(sidebarOpen));
};

export const subscribe = (listener:any) => {
  listeners.push(listener);
  return () => {
    listeners = listeners.filter((l:any) => l !== listener);
  };
};

import React, { useState, useEffect, ChangeEvent, FocusEvent, FormEvent } from "react";
import {
	TextField,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	SelectChangeEvent,
	Snackbar,
	Alert,
	Box,
} from "@mui/material";
import { SubmitUserDetails, changeUserPassword,encryptPassword } from "../../services/UserService";
import { adminUrl } from "../../services/APIService";
import { useSnackbar } from "../../SnackbarContext";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { JSEncrypt } from "jsencrypt";
import styles from "./UserStyles.module.scss";

type InputFields = {
	Password: string;
};

type ErrorFields = Partial<InputFields>;

const UserPasswordReset = (props: any) => {
	const {
		showResetPasswordPopup,
		setShowResetPasswordPopup,
		userId,
		fetchUsersList,
		setModels,
		setLoading,
		skip,
		top,
		OrganizationId,
		setTotalcount,
	} = props;
	const { openSnackbar } = useSnackbar();

	const [userDetails, setUserDetails] = useState({
		Password: "",
	});
	const [inputs, setInputs] = useState<InputFields>({ Password: "" });

	const [errors, setErrors] = useState<ErrorFields>({
		Password: "",
	});

	const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setInputs((prev) => ({ ...prev, [name]: value }));
		let error = "";

		if (name === "Password") {
			const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/;
			if (!passwordRegex.test(value)) {
				error =
					"Password must contain 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be 8-20 characters long.";
			}
			if (value.length < 8 || value.length > 12) {
				error = "Password must be 8-12 characters long";
			} else if (!/[A-Z]/.test(value)) {
				error = "Password must contain one uppercase letter";
			} else if (!/[a-z]/.test(value)) {
				error = "Password must contain one lowercase letter";
			} else if (!/\d/.test(value)) {
				error = "Password must contain one number";
			} else if (!/[!@#$%^&*]/.test(value)) {
				error = "Password must contain one special character";
			} else {
				error = "";
			}
		}

		setErrors((prev) => ({ ...prev, [name]: error }));
	};
	const [age, setAge] = React.useState("");
	const [showPassword, setShowPassword] = useState(false);

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleGenderChange = (event: SelectChangeEvent) => {
		setAge(event.target.value);
		const value = event.target.value;
		setInputs((prev: any) => ({
			...prev,
			Gender: value,
		}));
	};

	const handleChange = (event: SelectChangeEvent) => {
		setAge(event.target.value);
		console.log(age);
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const newErrors: ErrorFields = {};
		let isValid = true;

		(Object.keys(inputs) as (keyof InputFields)[]).forEach((key) => {
			if (!inputs[key]) {
				isValid = false;
			} else if (key === "Password") {
				const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/;
				if (!passwordRegex.test(inputs[key])) {
					newErrors[key] =
						"Password must contain 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be 8-20 characters long.";
					isValid = false;
				}
			}
		});
		if (isValid) {
			setLoading(true);
			try {
				//const encryptedPassword = encryptPassword(inputs.Password);
				await changeUserPassword(setLoading, setShowResetPasswordPopup, userId, inputs.Password, openSnackbar);

				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		showResetPasswordPopup && (
			<div
				className={styles["qadpt-userpasswordpopup"]}
				//style={{ height: "37vh", right: "500px", top: "200px" }}
			>
				<div>
					<h3>Reset User Password</h3>
					<svg
						onClick={() => setShowResetPasswordPopup(false)}
						className="close-icon"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						width="24"
						height="24"
						viewBox="0 0 50 50"
					>
						<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
					</svg>
				</div>
				<form onSubmit={handleSubmit}>
					<TextField
						name="Password"
						type={showPassword ? "text" : "password"}
						value={inputs.Password}
						onChange={handleTextFieldChange}
						label="Password"
						helperText={errors.Password}
						variant="standard"
						error={!!errors.Password}
						required
						inputProps={{ minLength: 8, maxLength: 20 }}
						sx={{
							"& .MuiFormHelperText-root": {
								color: errors.Password ? "red" : "inherit",
							},
							width: "250px",
							marginBottom: "14px",
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleTogglePasswordVisibility}
										onMouseDown={(event) => event.preventDefault()}
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<div style={{ marginLeft: "40px", display: "flex", gap: "20px" }}>
						<button
							style={{
								position: "relative",
								background: "#fff",
								color: "#007bff",
								border: "1px solid #007bff",
								marginRight: "27px",
								right: "0px",
								top: "25px",
							}}
							onClick={() => setShowResetPasswordPopup(false)}
							className="userButton"
						>
							Cancel
						</button>
						<button
							style={{ position: "relative", right: "-20px", top: "25px" }}
							className="userButton"
							type="submit"
						>
							Update
						</button>
					</div>
				</form>
			</div>
		)
	);
};

export default UserPasswordReset;

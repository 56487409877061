import JSEncrypt from 'jsencrypt';
import { adminApiService, idsApiService } from "./APIService";

export const superAdminLogin = async (
  emailId: string,
  password: string,
  organizationId: string,
  rememberLogin: boolean,
  returnUrl: string = ""
): Promise<any> => {
  try {
    console.log(emailId, password, organizationId, rememberLogin, returnUrl);

    const requestUrl = `/AdminLogin`;
    const requestBody = {
      Email: emailId,
      Password: password,
      OrganizationId: organizationId,
      RememberLogin: rememberLogin,
      ReturnUrl: returnUrl,
    };

    const response = await idsApiService.post(requestUrl, requestBody);

    console.log(response.data, "this is main");
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data.ErrorMessage;
    }
  } catch (error) {
    console.error("Error during login:", error);
    // Optionally: use snackbar or another method to notify the user of the error
    // useSnackbar().enqueueSnackbar('Login failed', { variant: 'error' });
    throw error; // Re-throw the error to be handled by the caller
  }
};

import React, { useEffect, useState } from 'react';
import {useNavigate } from "react-router-dom";
import axios from 'axios';
import { GridColDef } from '@mui/x-data-grid';
import { User } from '../../models/User';
import { SubmitUserDetail } from "../../services/UserService";
import { adminUrl } from '../../services/APIService';
const AddAdmin = (props: any) =>
{
  const { showEditPopup, setShowEditPopup, userId, fetchUserDataFromApi, setModels, setLoading } = props;
    const [inputs, setInputs] = useState({});
  const [users, setUsers] = useState<User[]>([]);
  const [userDetails, setUserDetails] = useState({
		FirstName: "",
		LastName: "",
		UserName: "",
		EmailId: "",
		Password: "",
		ContactNumber: "",
		Gender: "male",
		DateofBirth: "",
		AdminDeactivated: "",
		EmailConfirmed: Boolean,
		LoginType: "",
		ProfilePhoto: "",
		RTL: Boolean,
		TimeZone: "",
		UserId: "",
		UserType: "",
		
	});

  const [formErrors, setFormErrors] = useState({
    FirstName: '',
    LastName: '',
    EmailId: '',
    ContactNumber: '',
  });

	useEffect(() => {
		if (showEditPopup) {
			fetchUserDetails(userId);
		}
	}, [showEditPopup]);

	const fetchUserDetails = async (id: any) => {
		try {
			const response = await fetch(`${adminUrl}/User/GetUserByUserId?userId=${id}`);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();
			setUserDetails({
				FirstName: data.FirstName,
				LastName: data.LastName,
				UserName: data.UserName,
				EmailId: data.EmailId,
				Password: "**********", // Do not populate the password field for security reasons
				ContactNumber: data.ContactNumber,
				Gender: data.Gender,
				DateofBirth: data.DateofBirth,
				AdminDeactivated: data.AdminDeactivated,
				EmailConfirmed: data.EmailConfirmed,
				LoginType: data.LoginType,
				ProfilePhoto: data.ProfilePhoto,
				RTL: data.RTL,
				TimeZone: data.TimeZone,
				UserId: data.UserId,
				UserType: data.UserType,
			});
		} catch (error) {
			console.error("Failed to fetch user details:", error);
		}
	};


    const navigate = useNavigate();
    
    const handleChange = (e: any) => {
      const { name, value } = e.target;
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    };
    // const [formData, setFormData] = useState({
    //     ContactNumber: '',
    //     UserType: '', // Initialize Type with an empty string or default value
    //   });

      // const handleSubmit = async (e: any) => {
      //   e.preventDefault();
      //   SubmitUserDetails(setLoading, setModels, setShowEditPopup, userDetails);
  // };
  const validateForm = () => {
    let valid = true;
    const errors = {
      FirstName: '',
      LastName: '',
      EmailId: '',
      ContactNumber: '',
    };

    // FirstName validation
    if (!userDetails.FirstName.trim()) {
      errors.FirstName = 'First Name is required';
      valid = false;
    }

    // Email validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userDetails.EmailId)) {
      errors.EmailId = 'Email is invalid';
      valid = false;
    }

    // ContactNumber validation
    if (!/^\d{10}$/.test(userDetails.ContactNumber)) {
      errors.ContactNumber = 'Phone number is invalid (should be 10 digits)';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      // Proceed with form submission
      SubmitUserDetail(setLoading, setModels, setShowEditPopup, userDetails);
    } else {
      console.log('Form has errors. Please fix them.');
    }
  };

    return (
      showEditPopup && (
        <div className="user-popup">
          <div>
            <h1>Edit User</h1>
            <svg
              onClick={() => setShowEditPopup(false)}
              className="close-icon"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="24"
              height="24"
              viewBox="0 0 50 50"
            >
              <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
            </svg>
          </div>
          <form onSubmit={handleSubmit}>
						<input
							type="text"
              name="FirstName"
              value={userDetails.FirstName}
							onChange={handleChange}
                        placeholder="First Name*"
                        required
                    ></input>
            <br></br>
            {formErrors.FirstName && <span className="error">{formErrors.FirstName}</span>}
						<input
							type="text"
              name="LastName"
              value={userDetails.LastName}
							onChange={handleChange}
                        placeholder="Last Name "
                        required
                    ></input>
                    <br></br>
						<input
							type="EmailId"
              name="EmailId"
              value={userDetails.EmailId}
							onChange={handleChange}
                        placeholder="Email Id *"
                        required
                    ></input>
                    <br></br>
                    {formErrors.EmailId && <span className="error">{formErrors.EmailId}</span>}
						
						<input
							type="text"
              name="ContactNumber"
              value={userDetails.ContactNumber}
							onChange={handleChange}
                        placeholder="Contact Number *"
                       
                    ></input>
                    <br>
                    </br>
                    {formErrors.ContactNumber && <span className="error">{formErrors.ContactNumber}</span>}
            
            <div style={{ marginLeft: "115px" }}>
              <button
                style={{
                  position: "relative",
                  background: "#fff",
                  color: "#007bff",
                  border: "1px solid #007bff",
                  marginRight: "27px",
                  right: "0px",
                }}
                onClick={() => setShowEditPopup(false)}
                className="userButton"
              >
                Cancel
              </button>
              <button
                style={{ position: "relative", right: "0px" }}
                className="userButton"
                type='submit'
              >
                Save
              </button>
            </div>
          </form>
        </div>
      )
    );
  };
  

export default AddAdmin
import React from 'react';
import {useLocation } from 'react-router-dom';
import OrgAdminMenu from '../adminMenu/AdminMenu';

import RegistrationPage from '../RegistrationPage/RegistrationPage';

const Layout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();
  return (
    <div>
      {location.pathname==="/Registration"?(<RegistrationPage/>):(<OrgAdminMenu />)}
      <main className="qadpt-orgcont">{children}</main>
    </div>
  );
};

export default Layout;

import React, { useState, useEffect } from "react";
import {
	GridColDef,
	GridRenderCellParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	DataGrid,
	GridPaginationModel,
	GridColumnMenuProps,
	GridColumnsManagement,
} from "@mui/x-data-grid";
import {
	Button,
	Menu,
	MenuItem,
	FormControlLabel,
	Popover,
	Typography,
	IconButton,
	Switch,
	Container,
	Box,
	Snackbar,
	Alert,
	Divider,
	TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnRoundedIcon from "@mui/icons-material/ToggleOnRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import loader from "../../assets/loader.gif";
import { userData } from "./userData";
import CustomGrid from "../common/Grid";
import PersonOffSharpIcon from "@mui/icons-material/PersonOffSharp";
import axios from "axios";
import { MarkEmailRead } from "@mui/icons-material";
import EditUser from "./UserEdit";
import { useSnackbar } from "../../SnackbarContext";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyIcon from "@mui/icons-material/Key";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import UserCreate from "./UserCreate";
import type { DatePickerProps } from "antd";
import Tooltip from "@mui/material/Tooltip";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Unblockaccount, Mail, Keyvertical, Lockopen } from "../../assets/icons/icons";
import {
	DeleteUserDetails,
	SubmitCreateUser,
	Submitdisableuser,
	fetchUserDataFromApi,
	fetchUsersList,
	confirmEmail,
	BlockUser,
	UnblockUser,
	deactivateUser,
	activateUser,
} from "../../services/UserService";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserPasswordReset from "./UserPasswordReset";
import UserCustomColumnMenu from "./UserCustomColumnMenu";
import { error } from "console";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterPopup from "../settings/Filterpopup";
import UserUnblock from "./UserUnblock";
import UserEnable from "./UserEnable";
import UserDisable from "./Userdisable";
import Search from "@mui/icons-material/Search";
import styles from "./UserStyles.module.scss";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import Userblock from "./UserBlock";
import { useAuth } from "../auth/AuthProvider";

type InputFields = {
	FirstName: string;
	LastName: string;
	EmailId: string;
	UserType: string;
	Password: string;
	ConfirmPassword: string;
	ContactNumber: string;
	Gender: string;
	DateofBirth: string;
	SearchInput: string;
	//OrganizationId: string;
};

type ErrorFields = Partial<InputFields>;
interface CustomDataGridProps extends React.ComponentProps<typeof DataGrid> {
	components?: {
		columnMenu?: React.ComponentType<GridColumnMenuProps>;
	};
}
interface Model {
	FullName: string | null;
	EmailId: string;
	ContactNumber: string | null;
	CreatedOn: string;
	Manager: string;
	UserId: string;
}
let isfltr: any;
let glblsrch = false;
let fieldName: any;
let filterss: any;

const UserList: React.FC = () => {
	const [models, setModels] = useState<Model[]>([]);
	const [loading, setLoading] = useState(false);
	//const [inputs, setInputs] = useState({});
	const [emailiddelete, setemailiddelete] = useState("");
	const [useridedit, setUserIdEdit] = useState("");
	const [useridreset, setUserIdReset] = useState("");
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [users, setUsers] = useState("");
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [userName, setUserName] = useState("");
	const [helperText, setHelperText] = useState("	");
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");
	const [totalcount, setTotalcount] = useState(0);
	const { openSnackbar } = useSnackbar();
	const [usersEmails, setUsersEmails] = useState<string[]>([]);
	const [searchText, setSearchText] = useState("");
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: 15,
	});
	const [inputs, setInputs] = useState<InputFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		UserType: "User",
		Password: "",
		ConfirmPassword: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		SearchInput: "",
	});
	const [unBlockUserPopup, setUnBlockUserPopup] = useState(false);
	const [blockUserPopup, setBlockUserPopup] = useState(false);
	const [disableUserPopup, setDisableUserPopup] = useState(false);
	const [enableUserPopup, setEnableUserPopup] = useState(false);
	const [skip, setskip] = useState(0);
	const [top, settop] = useState(0);
	const [newpassword, setNewpassword] = useState("");
	const [orderByFields, setOrderByFields] = useState("");
	const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
	const [sortModel, setSortModel] = useState([]);
	const [columnname, setColumnname] = useState("");
	const [filters, setFilters] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
	const [errors, setErrors] = useState<ErrorFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		UserType: "",
		Password: "",
		ConfirmPassword: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		SearchInput: "",
		//OrganizationId: "",
	});

	useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString) { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					if (parsedUser) {
						const OrgId = parsedUser.OrganizationId ?? '';
						setOrganizationId(OrgId);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		
	}, []);
	const [selectedRows, setSelectedRows] = useState([]);

	// Handle selection change
	const handleSelectionChange = (newSelection: any) => {
		setSelectedRows(newSelection);
	};

	const handleSortModelChange = (model: any) => {
		setSortModel(model);
		const orderByField = model
			.map((item: any) =>
				item.sort === "" || item.sort === "asc" || (item.sort === "desc" && item.field === "FullName")
					? `FirstName desc`
					: item.sort === "desc" && item.field !== "FullName"
					? `${item.field} desc`
					: item.field
			)
			.join(", ");
		setOrderByFields(orderByField);
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByField, filters);
	};

	let skipss: Number;
	let topss: Number;

	const handleFirstnameFocus = () => {
		if (firstName.length < 2) {
			setHelperText("First Name is required");
		} else {
			setHelperText("");
		}
	};
	const handleLastnameFocus = () => {
		if (lastName.length < 2) {
			setHelperText("Last Name is required");
		} else {
			setHelperText("");
		}
	};
	const handleUsernameFocus = () => {
		if (userName.length < 2) {
			setHelperText("User Name is required");
		} else {
			setHelperText("");
		}
	};
	const handleEmailFocus = () => {
		if (firstName.length < 2) {
			setHelperText("User Name is required");
		} else {
			setHelperText("");
		}
	};
	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};
	const handleSearch = (value: string[]) => {
		setSearchText(value.join(" "));
	};

	const MatEdit = (data: any) => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
		const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleMenuClose = () => {
			setAnchorEl(null);
		};
		const handleActionClick = (action: string) => {
			switch (action) {
				case "confirmEmail":
					handleMailClick(data.userid);
					break;
				case "blockUser":
					Blockuser(data.userid);
					break;
				case "unblockUser":
					UnBlockuser(data.userid);
					break;
				case "activateUser":
					Activateuser(data.userid);
					break;
				case "deactivateUser":
					DeActivateuser(data.userid);
					break;
				case "resetPassword":
					ResetPassword(data.userid);
					break;
				case "deleteUser":
					handleDeleteClick(data.EmailId);
					break;
				default:
					break;
			}
			handleMenuClose();
		};
		const handleMailClick = (userid: string) => {
			try {
				if (data.emailConfirmed) {
					openSnackbar("Email is already confirmed", "error");
				} else {
					confirmEmail(
						setModels,
						setLoading,
						OrganizationId,
						skip,
						top,
						setTotalcount,
						userid,
						openSnackbar,
						orderByFields,
						filters
					);
					//inputs.SearchInput = "";
				}

				setLoading(false);
			} catch (error) {}
		};
		const Blockuser = (userid: string) => {
			setUserIdEdit(userid);
			setBlockUserPopup(true);
		};
		const UnBlockuser = (userid: string) => {
			setUserIdEdit(userid);
			setUnBlockUserPopup(true);
		};
		const Activateuser = (userid: string) => {
			setUserIdEdit(userid);
			setEnableUserPopup(true);
		};
		const DeActivateuser = (userid: string) => {
			setUserIdEdit(userid);
			setDisableUserPopup(true);
		};
		const ResetPassword = (userid: string) => {
			setUserIdEdit(userid);
			setShowResetPasswordPopup(true);
		};

		const handleDeleteClick = (emailId: string) => {
			setShowDeletePopup(true);
			console.log(emailId);
			setemailiddelete(emailId);
		};
		const handleeditclick = (userid: string) => {
			setShowEditPopup(true);
			console.log(userid);
			setUserIdEdit(userid);
		};
		const handleSwitchChange = (userid: string) => {
			console.log(userid);
			setUserIdEdit(userid);
			Submitdisableuser(setLoading, setModels, userid);
		};
		const handleClose = () => {
			setAnchorEl(null);
			setShowDeletePopup(false);
		};

		return (
			<div>
				{data.isblocked ? (
					<Tooltip
						title="Edit"
						arrow
						placement="top"
					>
						<IconButton
							className="qadpt-grdicon"
							aria-label="edit"
							onClick={() => handleeditclick(data.userid)}
							disabled
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip
						title="Edit"
						arrow
						placement="top"
					>
						<IconButton
							className="qadpt-grdicon"
							aria-label="edit"
							onClick={() => handleeditclick(data.userid)}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}

				{/* <Tooltip
					title="Edit"
					arrow
					placement="top"
				>
					
					<IconButton
						className="qadpt-grdicon"
						aria-label="edit"
						onClick={() => handleeditclick(data.userid)}
					>
						<EditIcon />
					</IconButton>
				</Tooltip> */}

				<Tooltip
					title="Actions"
					arrow
					placement="top"
				>
					<IconButton
						className="qadpt-grdicon"
						aria-label="more actions"
						onClick={handleMenuClick}
					>
						<MoreVertIcon />
					</IconButton>
				</Tooltip>

				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					PaperProps={{
						style: {
							minWidth: 170,
							borderRadius: 8,
						},
					}}
				>
					{" "}
					{!data.activateuser && !data.isblocked ? (
						<MenuItem onClick={() => handleActionClick("resetPassword")}>
							<FormControlLabel
								control={
									<Tooltip
										title="Reset Password"
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label="reset password"
											onClick={() => ResetPassword(data.userid)}
										>
											<div className="qadpt-actionpopup">
												<KeyIcon style={{ transform: "rotate(90deg)" }} />
												<span>Reset Password</span>
											</div>
										</IconButton>
									</Tooltip>
								}
								label=""
							/>
						</MenuItem>
					) : data.activateuser && !data.emailConfirmed ? (
						<MenuItem onClick={() => handleActionClick("resetPassword")}>
							<FormControlLabel
								control={
									<Tooltip
										title="Reset Password"
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label="reset password"
											onClick={() => ResetPassword(data.userid)}
										>
											<div className="qadpt-actionpopup">
												<KeyIcon style={{ transform: "rotate(90deg)" }} />
												<span>Reset Password</span>
											</div>
										</IconButton>
									</Tooltip>
								}
								label=""
							/>
						</MenuItem>
					) : (
						""
					)}
					{data.isblocked ? (
						<MenuItem onClick={() => handleActionClick("unblockUser")}>
							<FormControlLabel
								control={
									<Tooltip
										title={data.isblocked ? "Unblock User" : "Block User"}
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label={data.isblocked ? "unblock user" : "block user"}
											onClick={() => {
												if (data.isblocked) {
													UnBlockuser(data.userid);
												} else {
													Blockuser(data.userid);
												}
											}}
										>
											{" "}
											{data.isblocked ? (
												<div className="qadpt-actionpopup">
													<>
														<LockOpenOutlinedIcon
															className={styles["qadpt-iconspositions"]}
															style={{ color: "#1C1B1F" }}
														/>
														<span style={{ color: "#202224" }}> Unblock User</span>
													</>
												</div>
											) : data.emailConfirmed ? (
												""
											) : (
												<div className="qadpt-actionpopup">
													<>
														<LockOutlinedIcon
															className={styles["qadpt-iconspositions"]}
															style={{ color: "#1C1B1F" }}
														/>
														<span style={{ color: "#202224" }}>Block User</span>
													</>
												</div>
											)}
										</IconButton>
									</Tooltip>
								}
								label=""
							/>
						</MenuItem>
					) : !data.emailConfirmed ? (
						""
					) : (
						<MenuItem onClick={() => handleActionClick("blockUser")}>
							<FormControlLabel
								control={
									<Tooltip
										title={data.isblocked ? "Unblock User" : "Block User"}
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label={data.isblocked ? "unblock user" : "block user"}
											onClick={() => {
												if (data.isblocked) {
													//UnBlockuser(data.userid);
												} else {
													Blockuser(data.userid);
												}
											}}
										>
											{" "}
											{data.isblocked ? (
												// <div className="qadpt-actionpopup">
												// 	<>
												// 		<LockOpenOutlinedIcon
												// 			className={styles["qadpt-iconspositions"]}
												// 			style={{ color: "#1C1B1F" }}
												// 		/>
												// 		<span style={{ color: "#202224" }}> Unblock User</span>
												// 	</>
												// </div>
												""
											) : (
												<div className="qadpt-actionpopup">
													<>
														<LockOutlinedIcon
															className={styles["qadpt-iconspositions"]}
															style={{ color: "#1C1B1F" }}
														/>
														<span style={{ color: "#202224" }}>Block User</span>
													</>
												</div>
											)}
										</IconButton>
									</Tooltip>
								}
								label=""
							/>
						</MenuItem>
					)}
					{data.isblocked ? (
						""
					) : data.emailConfirmed ? (
						""
					) : (
						<MenuItem onClick={() => handleActionClick("confirmEmail")}>
							<FormControlLabel
								control={
									<Tooltip
										title={!data.emailConfirmed ? "Confirm Email" : "Email Confirmed"}
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label="email"
											onClick={() => handleMailClick(data.userid)}
										>
											<div className="qadpt-actionpopup">
												{/* {data.emailConfirmed ? ( */}
												<>
													<MailIcon />
													<span>confirm Email</span>
												</>
												{/* ) : (
													<>
														<DraftsIcon />
														<span>confirm Email</span>
													</>
												)} */}
											</div>
										</IconButton>
									</Tooltip>
								}
								label={""}
							/>
						</MenuItem>
					)}
					{data.isblocked ? (
						""
					) : (
						<div>
							{!data.activateuser ? (
								<MenuItem onClick={() => handleActionClick("deactivateUser")}>
									<FormControlLabel
										control={
											<Tooltip
												title={!data.activateuser ? "Deactivate User" : "Activate User"}
												arrow
												placement="top"
											>
												<IconButton
													className={styles["qadpt-iconButton"]}
													color="primary"
													aria-label={!data.activateuser ? "deactivate user" : "activate user"}
													onClick={() => {
														if (!data.activateuser) {
															DeActivateuser(data.userid);
														}
														// else {
														// 	Activateuser(data.userid);
														// }
													}}
												>
													<div className="qadpt-actionpopup">
														<>
															<NoAccountsOutlinedIcon
																className={styles["qadpt-iconspositions"]}
																style={{ color: "#1C1B1F" }}
															/>
															<span style={{ color: "#202224" }}> Disable User</span>
														</>
													</div>
													{/* ) : (
											<div className={styles["qadpt-iconsStyles"]}>
												<>
													<ToggleOnRoundedIcon
														className={styles["qadpt-iconspositions"]}
														style={{ color: "#1C1B1F" }}
													/>
													<span style={{ color: "#202224" }}> Enable User</span>
												</>
											</div>
										)} */}
												</IconButton>
											</Tooltip>
										}
										label=""
									/>
								</MenuItem>
							) : !data.emailConfirmed ? (
								""
							) : (
								<MenuItem onClick={() => handleActionClick("activateUser")}>
									<FormControlLabel
										control={
											<Tooltip
												title={!data.activateuser ? "Deactivate User" : "Activate User"}
												arrow
												placement="top"
											>
												<IconButton
													className={styles["qadpt-iconButton"]}
													color="primary"
													aria-label={!data.activateuser ? "deactivate user" : "activate user"}
													onClick={() => {
														// if (!data.activateuser) {
														// 	DeActivateuser(data.userid);
														// } else
														if (data.activateuser) {
															Activateuser(data.userid);
														}
													}}
												>
													{/* <div className={styles["qadpt-iconsStyles"]}>
											<>
												<ToggleOffOutlinedIcon
													className={styles["qadpt-iconspositions"]}
													style={{ color: "#1C1B1F" }}
												/>
												<span style={{ color: "#202224" }}> Disable User</span>
											</>
										</div>
										 */}
													<div className="qadpt-actionpopup">
														<>
															<AccountCircleOutlinedIcon
																className={styles["qadpt-iconspositions"]}
																style={{ color: "#1C1B1F" }}
															/>
															<span style={{ color: "#202224" }}> Enable User</span>
														</>
													</div>
												</IconButton>
											</Tooltip>
										}
										label=""
									/>
								</MenuItem>
							)}
						</div>
					)}
					{/* <MenuItem onClick={() => handleActionClick("deleteUser")}>
						<div
							style={{
								fontSize: "0.875rem",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
							}}
						>
							Delete User
						</div>
					</MenuItem> */}
				</Menu>
			</div>
		);
	};

	const columns: GridColDef[] = [
		{
			field: "FullName",
			headerName: "Full Name",
			// width: sidebarOpen ? 200 : 200,
			resizable: false,
			filterable: false,
			disableColumnMenu: true,
			sortable: true,
			renderCell: (params) => {
				const { FirstName, LastName } = params.row;
				return (
					<div>
						{FirstName} {LastName}
					</div>
				);
			},
		},
		{
			field: "EmailId",
			headerName: "Email Address",
			// width: sidebarOpen ? 250 : 250,
			disableColumnMenu: true,
			resizable: false,
		},
		{
			field: "ContactNumber",
			headerName: "Contact Number",
			// width: sidebarOpen ? 180 : 230,
			disableColumnMenu: true,
			resizable: false,
		},
		// {
		// 	field: "CreatedDate",
		// 	headerName: "Created On",
		// 	width: sidebarOpen ? 150 : 150,

		// 	disableColumnMenu: true,
		// 	renderCell: (params) => {
		// 		const date = params.value ? params.value.split("T")[0] : "";
		// 		return <div>{date}</div>;
		// 	},
		// },
		{
			field: "AdminDeactivated",
			headerName: " Status",
			// width: 100,
			resizable: false,
			renderCell: (params) => {
				const { AdminDeactivated, IsBlocked, EmailConfirmed } = params.row;
				return (
					<div className={IsBlocked ? "st-blo" : !EmailConfirmed ? "st-pen" : AdminDeactivated ? "st-ina" : "st-act"}>
						{IsBlocked ? "Blocked" : !EmailConfirmed ? "Pending" : AdminDeactivated ? "Inactive" : "Active"}
					</div>
				);
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			// width: 200,
			resizable: false,
			disableColumnMenu: true,
			renderCell: (params) => {
				const emailConfirmed = params.row.EmailConfirmed || false;
				const emailId = params.row.EmailId || false;
				const userid = params.row.UserId || false;
				const isblocked = params.row.IsBlocked || false;
				const activateuser = params.row.AdminDeactivated || false;
				const IsActive = params.row.IsActive || false;
				return (
					<div>
						<MatEdit
							emailConfirmed={emailConfirmed}
							emailId={emailId}
							index={params.row.id}
							userid={userid}
							isblocked={isblocked}
							activateuser={activateuser}
							IsActive={IsActive}
						/>
					</div>
				);
			},
		},
	];
	const clearSearch = () => {
		let filt: any[] = [];
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filt);
		setFilters([]);
	};

	const globalhandleSearch = (searchValue: any) => {
		console.log("Search initiated with value:", searchValue);
		const isNumber = /^\d+$/.test(searchValue);

		const filters = [
			{
				FieldName: "EmailId",
				ElementType: "string",
				Condition: "contains",
				Value: searchValue,
				IsCustomField: false,
			},
			{
				FieldName: "FirstName",
				ElementType: "string",
				Condition: "contains",
				Value: searchValue,
				IsCustomField: false,
			},
			{
				FieldName: "LastName",
				ElementType: "string",
				Condition: "contains",
				Value: searchValue,
				IsCustomField: false,
			},
			{
				FieldName: "ContactNumber",
				ElementType: "string",
				Condition: "contains",
				Value: searchValue,
				IsCustomField: false,
			},
		];

		let skips = 0;
		// let top = 0;
		// const skipCount = paginationModel.pageSize || 15;
		// const limitCount = paginationModel.page * skipCount;
		// top = paginationModel.pageSize;
		// skips = limitCount;
		// setskip(skips);
		// settop(top);
		glblsrch = true;
		filterss = filters;
		setFilters(filterss);
		try {
			fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, filters);
			//setFilters(filters);
			//inputs.SearchInput = "";
		} catch (error) {
			console.error("Failed to fetch user roles:", error);
		}
	};

	const columnNames = columns.map((col) => col.headerName);
	const actionColumns = ["Actions"];
	const filteredColumnNames = columnNames
		.filter((name): name is string => name !== undefined && !actionColumns.includes(name))
		.map((name) => (name === "EmailId" ? name.replace(/\s+/g, "") : name));

	// const handleApplyFilters = async (filters: any) => {
	// 	try {
	// 		isfltr = true;
	// 		filterss = filters;

	// 		const skipcount = paginationModel.pageSize || 15;
	// 		const top = paginationModel.pageSize;
	// 		const skips = paginationModel.page * skipcount;
	// 		setskip(skip);
	// 		settop(top);

	// 		// Refactor FieldName mapping with switch statement
	// 		const formattedFilters = filters.map((filter: any) => {
	// 			switch (filter.column) {
	// 				case "Email Address":
	// 					fieldName = "EmailId";
	// 					break;
	// 				case "Contact Number":
	// 					fieldName = "ContactNumber";
	// 					break;
	// 				case "Full Name":
	// 					fieldName = "FirstName";
	// 					break;
	// 				default:
	// 					fieldName = "LastName";
	// 					break;
	// 			}

	// 			return {
	// 				FieldName: fieldName,
	// 				ElementType: "string",
	// 				Condition: filter.operator,
	// 				Value: filter.value,
	// 				IsCustomField: false,
	// 			};
	// 		});
	// 		setFilters(formattedFilters);

	// 		fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, formattedFilters);
	// 	} catch (error) {
	// 		console.error("Failed to fetch user roles:", error);
	// 	}
	// };
	//////22222222222222222222222222
	const handleApplyFilters = async (filters: any) => {
		try {
			isfltr = true;
			filterss = filters;
	
			const skipcount = paginationModel.pageSize || 15;
			const top = paginationModel.pageSize;
			const skips = paginationModel.page * skipcount;
			setskip(skips);
			settop(top);
	
			// Refactor FieldName mapping with switch statement
			let formattedFilters = filters.flatMap((filter: any) => {
				let fieldName = '';
				let resultFilters = [];
	
				switch (filter.column) {
					case "Email Address":
						fieldName = "EmailId";
						break;
					case "Contact Number":
						fieldName = "ContactNumber";
						break;
					case "Full Name":
						// Split the Full Name value into FirstName and LastName
						const nameParts = filter.value.split(" ");
						const firstName = nameParts[0] || ""; // First part as FirstName
						const lastName = nameParts.slice(1).join(" ") || ""; // Remaining parts as LastName
	
						// Add two separate filters for FirstName and LastName
						resultFilters.push({
							FieldName: "FirstName",
							ElementType: "string",
							Condition: filter.operator,
							Value: firstName,
							IsCustomField: false,
						});
						resultFilters.push({
							FieldName: "LastName",
							ElementType: "string",
							Condition: filter.operator,
							Value: lastName,
							IsCustomField: false,
						});
						break;
					default:
						fieldName = filter.column.replace(" ", ""); // Map other columns directly
						break;
				}
	
				// Add filter if fieldName is set (excluding "Full Name")
				if (fieldName && filter.column !== "Full Name") {
					resultFilters.push({
						FieldName: fieldName,
						ElementType: "string",
						Condition: filter.operator,
						Value: filter.value,
						IsCustomField: false,
					});
				}
	
				return resultFilters;
			});
	
			setFilters(formattedFilters);
	
			fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, formattedFilters);
		} catch (error) {
			console.error("Failed to fetch user roles:", error);
		}
	};
	
	
	
	// const handleApplyFilters = async (filters: any) => {
	// 	try {
	// 		isfltr = true;
	// 		filterss = filters;
	
	// 		const skipcount = paginationModel.pageSize || 15;
	// 		const top = paginationModel.pageSize;
	// 		const skips = paginationModel.page * skipcount;
	// 		setskip(skips);
	// 		settop(top);
	
	// 		// Refactor FieldName mapping with switch statement
	// 		let formattedFilters = filters.flatMap((filter: any) => {
	// 			let fieldName = '';
	// 			let resultFilters = [];
	
	// 			switch (filter.column) {
	// 				case "Email Address":
	// 					fieldName = "EmailId";
	// 					break;
	// 				case "Contact Number":
	// 					fieldName = "ContactNumber";
	// 					break;
	// 				case "Full Name":
	// 					// Split Full Name into FirstName and LastName filters
	// 					resultFilters.push({
	// 						FieldName: "FirstName",
	// 						ElementType: "string",
	// 						Condition: filter.operator,
	// 						Value: filter.value,
	// 						IsCustomField: false,
	// 					});
	// 					resultFilters.push({
	// 						FieldName: "LastName",
	// 						ElementType: "string",
	// 						Condition: filter.operator,
	// 						Value: filter.value,
	// 						IsCustomField: false,
	// 					});
	// 					break;
	// 				default:
	// 					fieldName = "LastName";
	// 					break;
	// 			}
	
	// 			// Add filter if fieldName is set (excluding "Full Name")
	// 			if (fieldName && filter.column !== "Full Name") {
	// 				resultFilters.push({
	// 					FieldName: fieldName,
	// 					ElementType: "string",
	// 					Condition: filter.operator,
	// 					Value: filter.value,
	// 					IsCustomField: false,
	// 				});
	// 			}
	
	// 			return resultFilters;
	// 		});
	
	// 		setFilters(formattedFilters);
	
	// 		fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, formattedFilters);
	// 	} catch (error) {
	// 		console.error("Failed to fetch user roles:", error);
	// 	}
	// };
	

	if (models.length === 0 && isfltr) {
		const formattedFilters = filterss.map((filter: any) => ({
			FieldName: "LastName",
			ElementType: "string",
			Condition: filter.operator,
			Value: filter.value,
			IsCustomField: false,
		}));

		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, formattedFilters);

		isfltr = false;
	}

	const onPageChange = (newPage: number) => {};
	const onPageSizeChange = (newPageSize: number) => {};

	const fetchusers = (skip: any, top: any) => {
		setLoading(true);
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
	};

	const fetchData = async () => {
		const skipcount = paginationModel.pageSize || 15;
		//(paginationModel.pageSize, "pageSize");
		const limitcount = paginationModel.page * skipcount;
		const skips = glblsrch ? 0 : limitcount;
		const top = paginationModel.pageSize;
		//const flt = filters.length === 0 ? filterss : filters;
		setskip(skips);
		settop(top);
		await fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, filters);
		//console.log("Models after fetch:", models);
	};

	useEffect(() => {
		fetchData();
	}, [paginationModel]);

	useEffect(() => {
		if (!models.length) {
			setUsersEmails(models.map((user) => user.EmailId));
		}
	}, [models]);

	const handleNextButtonClick = () => {
		const newSkip = (paginationModel.page + 1) * paginationModel.pageSize;
		// setskip(newSkip.toString());
		// skipss = newSkip;
		const newTop = newSkip + paginationModel.pageSize;
		// setTop(newTop.toString());
		// topss = newTop;
		setPaginationModel((prev) => ({ ...prev, page: prev.page + 1 }));
		fetchusers(newSkip, newTop);
		//fetchusers(newSkip, newTop);
	};

	const handlePreviousButtonClick = () => {
		const newSkip = (paginationModel.page - 1) * paginationModel.pageSize;
		// setskip(newSkip.toString());
		// skipss = newSkip;
		const newTop = newSkip + paginationModel.pageSize;
		// setTop(newTop.toString());
		// topss = newTop;
		setPaginationModel((prev) => ({ ...prev, page: prev.page - 1 }));
		fetchusers(newSkip, newTop);
	};

	useEffect(() => {
		if (models.length !== 0) {
			setLoading(false);
		}
	}, [models]);
	const [showPopup, setShowPopup] = useState(false);
	const [showeditPopup, setShowEditPopup] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);

	const openPopup = () => {
		setShowPopup(true);
	};
	const openEditPopup = () => {
		setShowEditPopup(true);
	};
	const handleDownloadExcel = async () => {
		try {
			// const response = await fetchorganizationlistData()
			// const url = process.env.REACT_APP_BASE_URL
			// if (response.Msg_Code === "802.171.251.01" && response.File_Path) {
			// 	const downloadLink = document.createElement("a")
			// 	downloadLink.href = `${url}${response.File_Path}`
			// 	downloadLink.download = "excel_data.xlsx"
			// 	document.body.appendChild(downloadLink)
			// 	downloadLink.click()
			// 	document.body.removeChild(downloadLink)
			// } else {
			// 	console.error("Invalid response from the server:", response)
			// 	throw new Error("Invalid response from the server")
			// }
		} catch (error) {
			console.error("Error downloading Excel:", error);
		}
	};
	const CustomToolbar: React.FC<any> = () => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleExportMenuClose = () => {
			setAnchorEl(null);
		};

		const handleDownloadExcelClick = () => {
			handleExportMenuClose();
		};

		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
				<Button
					aria-controls="export-menu"
					aria-haspopup="true"
					onClick={handleExportMenuClick}
					style={{ marginLeft: "10px" }}
					startIcon={<SaveAltIcon />}
				>
					Export
				</Button>
				<Menu
					id="export-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleExportMenuClose}
				>
					<MenuItem
						onClick={() => {
							handleDownloadExcel();
							handleExportMenuClose();
						}}
					>
						Download Excel
					</MenuItem>
				</Menu>
			</div>
		);
	};

	const handleDeleteuser = () => {
		try {
			DeleteUserDetails(emailiddelete, setShowDeletePopup, fetchUserDataFromApi, setModels, setLoading);
			openSnackbar("User  Deleted successfully!", "success");
		} catch (error) {
			openSnackbar("Failed to Delete user .", "error");
		}
	};

	const handleChange = (event: any) => {
		setFirstName(event.target.value);
		handleFirstnameFocus();
		const name = event.target.name;
		const value = event.target.value;
		setInputs((values) => ({ ...values, [name]: value }));
	};
	const handleSubmituser = (event: any) => {
		event.preventDefault();
		//console.log(inputs);
		//SubmitCreateUser(setLoading, setShowPopup, setModels, setShowEditPopup, inputs);
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();
		//console.log(inputs);
	};

	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
	}, []);
	const onChange: DatePickerProps["onChange"] = (date: any, dateString: any) => {};
	const handlePageChange = (newPage: number, newPageSize: number) => {};

	const handlePageSizeChange = (newPageSize: number) => {};

	const filteredRows = models.filter((row: any) => {
		const emailId = row.EmailId || "";
		const contactnumber = row.ContactNumber || "";
		const userName = row.UserName || "";

		return (
			emailId.toLowerCase().includes(searchText.toLowerCase()) ||
			contactnumber.toLowerCase().includes(searchText.toLowerCase()) ||
			userName.toLowerCase().includes(searchText.toLowerCase())
		);
	});
	console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkk", OrganizationId);
	return (
		<Container>
			<div className="qadpt-midpart setng-box">
				<div className="qadpt-content-block">
					<div className="qadpt-head">
						<div className="qadpt-title-sec">
							<div className="qadpt-title">Team</div>
							<div className="qadpt-description">View & Manage your teams</div>
						</div>
						<div className="qadpt-right-part">
							<button
								onClick={openPopup}
								className="qadpt-memberButton"
							>
								<i className="fal fa-add-plus"></i>
								<span>Create Member</span>
							</button>
						</div>
						<div></div>
					</div>

					<div>
						<Box className="qadpt-content-box">
							<div className="qadpt-src-flt">
								<div>
									<TextField
										className={`qadpt-teamsearch ${errors.SearchInput ? "qadpt-teamsearch-error" : ""}`}
										name="SearchInput"
										value={inputs.SearchInput}
										onChange={(e) => {
											const newValue = e.target.value;
											setInputs({ ...inputs, SearchInput: newValue });
											if (newValue === "") {
												clearSearch();
												// fetchUsersList(
												// 	setModels,
												// 	setLoading,
												// 	OrganizationId,
												// 	skip,
												// 	top,
												// 	setTotalcount,
												// 	orderByFields,
												// 	filters
												// );
											}
										}}
										placeholder="Search Team"
										error={!!errors.SearchInput}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<IconButton
														aria-label="search"
														onClick={() => globalhandleSearch(inputs.SearchInput)}
														onMouseDown={(event) => event.preventDefault()}
													>
														<SearchIcon />
													</IconButton>
												</InputAdornment>
											),
											endAdornment: inputs.SearchInput && (
												<InputAdornment position="end">
													<IconButton
														aria-label="clear"
														onClick={() => {
															setInputs({ ...inputs, SearchInput: "" });
															clearSearch();
															// fetchUsersList(
															// 	setModels,
															// 	setLoading,
															// 	OrganizationId,
															// 	skip,
															// 	top,
															// 	setTotalcount,
															// 	orderByFields,
															// 	filters
															// );
														}}
													>
														<ClearIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>{" "}
								<div className="qadpt-usrfilter">
									<FilterPopup
										columns={filteredColumnNames}
										onApplyFilters={handleApplyFilters}
									/>
								</div>
							</div>
							<div style={{ position: "relative", top: "-110px" }}>
								{selectedRows.length > 0 && (
									<div style={{ marginBottom: "-15px", display: "flex", gap: "10px" }}>
										<FormControlLabel
											style={{ border: "1px black", borderRadius: "0px" }}
											control={
												<Tooltip
													title="Reset Password"
													arrow
													placement="top"
												>
													<IconButton
														color="secondary"
														aria-label="reset password"
													>
														<div
															className="qadpt-actionpopup"
															style={{ display: "flex", alignItems: "center", gap: "8px" }}
														>
															<KeyIcon style={{ transform: "rotate(90deg)" }} />
															<span>Reset Password</span>
														</div>
													</IconButton>
												</Tooltip>
											}
											label=""
										/>

										<FormControlLabel
											control={
												<Tooltip
													title="Lock Accounts"
													arrow
													placement="top"
												>
													<IconButton
														color="secondary"
														aria-label="lock accounts"
													>
														<div
															className="qadpt-actionpopup"
															style={{ display: "flex", alignItems: "center", gap: "8px" }}
														>
															<LockOutlinedIcon />
															<span>
																Lock {selectedRows.length !== 0 ? `${selectedRows.length} rows selected` : ""} user
															</span>
														</div>
													</IconButton>
												</Tooltip>
											}
											label=""
										/>

										<FormControlLabel
											control={
												<Tooltip
													title="Unblock User"
													arrow
													placement="top"
												>
													<IconButton
														color="secondary"
														aria-label="unblock user"
													>
														<div
															className="qadpt-actionpopup"
															style={{ display: "flex", alignItems: "center", gap: "8px" }}
														>
															<LockOpenOutlinedIcon />
															<span>Unblock User</span>
														</div>
													</IconButton>
												</Tooltip>
											}
											label=""
										/>

										<FormControlLabel
											control={
												<Tooltip
													title="Disable Account"
													arrow
													placement="top"
												>
													<IconButton
														color="secondary"
														aria-label="disable account"
													>
														<div
															className="qadpt-actionpopup"
															style={{ display: "flex", alignItems: "center", gap: "8px" }}
														>
															<NoAccountsOutlinedIcon />
															<span>Disable Account</span>
														</div>
													</IconButton>
												</Tooltip>
											}
											label=""
										/>
									</div>
								)}
							</div>
							<DataGrid
								className="qadpt-setting-grd"
								//checkboxSelection
								rows={models.length > 0 ? models : []}
								columns={columns}
								pagination
								paginationMode="server"
								slots={{
									columnMenu: (menuProps) => {
										if (
											menuProps.colDef.field === "EmailId" ||
											menuProps.colDef.field === "ContactNumber" ||
											menuProps.colDef.field === "AdminDeactivated"
										) {
											return (
												<UserCustomColumnMenu
													column={menuProps.colDef.field}
													setModels={setModels}
													setLoading={setLoading}
													skip={skip}
													top={top}
													OrganizationId={OrganizationId}
													setTotalcount={setTotalcount}
													orderByFields={orderByFields}
													filters={filters}
													setFilters={setFilters}
													{...menuProps}
													options={models.map((model: any) => model[menuProps.colDef.field] || "")}
													onSearch={handleSearch}
													searchText={searchText}
													setSearchText={setSearchText}
													selectedOptions={selectedOptions}
													setSelectedOptions={setSelectedOptions}
												/>
											);
										}
										return null;
									},
								}}
								getRowId={(row) =>
									row.AccountId ||
									row.Account_ID ||
									row.AccountName ||
									row.id ||
									row.Created_Date ||
									row.UserId ||
									row.Id ||
									row.OrganizationId ||
									row.ImageId
								}
								paginationModel={paginationModel}
								onPaginationModelChange={(newModel) => {
									if (models.length < paginationModel.pageSize && newModel.page > paginationModel.page) {
										return;
									}
									setPaginationModel(newModel);
								}}
								rowCount={totalcount}
								pageSizeOptions={[15, 25, 50, 100]}
								localeText={{
									MuiTablePagination: {
										labelRowsPerPage: "Records Per Page",
									},
								}}
								disableRowSelectionOnClick={true}
								//loading={loading}
								sortModel={sortModel}
								onSortModelChange={handleSortModelChange}
								onRowSelectionModelChange={handleSelectionChange}
							/>

							{models.length === 0 && isfltr && (
								<div className="qadpt-loaderstyles">
									<img
										src={loader}
										alt="Spinner"
										className="qadpt-loaderSpinnerStyles"
									/>
								</div>
							)}
						</Box>
					</div>

					{unBlockUserPopup ? (
						<UserUnblock
							unBlockUserPopup={unBlockUserPopup}
							setUnBlockUserPopup={setUnBlockUserPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}
					{blockUserPopup ? (
						<Userblock
							blockUserPopup={blockUserPopup}
							setBlockUserPopup={setBlockUserPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}
					{disableUserPopup ? (
						<UserDisable
							disableUserPopup={disableUserPopup}
							setDisableUserPopup={setDisableUserPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							filters={filters}
						/>
					) : (
						""
					)}
					{enableUserPopup ? (
						<UserEnable
							enableUserPopup={enableUserPopup}
							setEnableUserPopup={setEnableUserPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							filters={filters}
						/>
					) : (
						""
					)}

					{showPopup ? (
						<UserCreate
							setModels={setModels}
							setLoading={setLoading}
							setShowPopup={setShowPopup}
							setShowEditPopup={setShowEditPopup}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}

					{showeditPopup ? (
						<EditUser
							showEditPopup={showeditPopup}
							setShowEditPopup={setShowEditPopup}
							userId={useridedit}
							fetchUserDataFromApi={fetchUserDataFromApi}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}
					{showResetPasswordPopup ? (
						<UserPasswordReset
							showResetPasswordPopup={showResetPasswordPopup}
							setShowResetPasswordPopup={setShowResetPasswordPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							filters={filters}
						/>
					) : (
						""
					)}

					{showDeletePopup ? (
						<div>
							<div></div>
							<div className="userDelete-popup">
								<div>
									<svg
										onClick={() => setShowDeletePopup(false)}
										className="close-icon"
										xmlns="http://www.w3.org/2000/svg"
										x="0px"
										y="0px"
										width="24"
										height="24"
										viewBox="0 0 50 50"
									>
										<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
									</svg>
								</div>
								<div className="qadpt-deletepopupwidth">
									<p>Are you sure you want to delete User</p>
									<button
										className="qadpt-deletepopupCancelbutton"
										onClick={() => setShowDeletePopup(false)}
									>
										Cancel
									</button>

									<button
										className="qadpt-userButton"
										onClick={handleDeleteuser}
									>
										Delete
									</button>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</Container>
	);
};

export default UserList;

import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import Layout from "./components/layout/Layout";
import { RtlProvider } from "./RtlContext";
import { SnackbarProvider } from "./SnackbarContext";
import Routing from "./routing/Routings";
import { AuthProvider } from "./components/auth/AuthProvider";
import { useLocation } from "react-router-dom";

function App() {
	const location = useLocation();
	const noLayoutRoutes = ["/login", "/forgotpassword", "/resetpassword", "/admin/adminlogin"];

	const isNoLayoutRoute = noLayoutRoutes.includes(location.pathname);

	return (
		<AuthProvider>
			<SnackbarProvider>
				<Suspense fallback={<div>Loading...</div>}>
					<RtlProvider>
						{isNoLayoutRoute ? (
							<Routing />
						) : (
							<Layout>
								<Routing />
							</Layout>
						)}
					</RtlProvider>
				</Suspense>
			</SnackbarProvider>
		</AuthProvider>
	);
}

export default App;

import React, { useState } from 'react';
import { Container, Grid, Paper, Button, Typography, Box } from '@mui/material';
import { GuideEdit } from './GuideEdit';
import CreateGuide from './GuideCreate';

const Builder = () => {
  const [showGuideEdit, setShowGuideEdit] = useState(false);
  const [showCreateGuide, setShowCreateGuide] = useState(false);

  const handleEditClick = () => {
    setShowGuideEdit(true);
  };

  const handleCreateJourneyClick = () => {
    setShowCreateGuide(true);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      {showGuideEdit ? (
        <GuideEdit />
      ) : showCreateGuide ? (
        <CreateGuide />
      ) : (
        <>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h4">What would you like to do in the Builder?</Typography>
          </Box>

          <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Button variant="contained" color="primary" style={{ width: '100%' }} onClick={handleEditClick}>
                Edit existing Experiences
              </Button>
            </Paper>
          </Box>

          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Typography variant="h6">Or</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">Journeys</Typography>
                <Button variant="contained" color="primary" style={{ width: '100%' }} onClick={handleCreateJourneyClick}>
                  Create new
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">Hotspots</Typography>
                <Button variant="contained" color="primary" style={{ width: '100%' }}>
                  Create new
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">Checklists</Typography>
                <Button variant="contained" color="primary" style={{ width: '100%' }}>
                  Create new
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">Microsurvey</Typography>
                <Button variant="contained" color="primary" style={{ width: '100%' }}>
                  Create new
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">Launcher</Typography>
                <Button variant="contained" color="primary" style={{ width: '100%' }}>
                  Create new
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Builder;

import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { Container, List, ListItem, ListItemText, Typography, Box } from "@mui/material";
import { useAuth } from "../auth/AuthProvider";
import { User } from "../../models/User";


// import { createGlobalStyle } from 'styled-components';

// const GlobalStyle = createGlobalStyle<{ isHidden: boolean }>`
//   body {
//     overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'auto')};
//   }
// `;

const Settings = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();
  const isHidden = location.pathname === "/settings/team";
  const { signOut, userDetails } = useAuth();
  const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");

  const [user, setUser] = useState<User | null>(null);
	
	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString) { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					setUser(parsedUser);	
					if (parsedUser) {
						const OrgId = parsedUser.OrganizationId ?? '';
						setOrganizationId(OrgId);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		else if (userDetails) {
			setUser(userDetails);	
			if (userDetails) {
				const OrgId = userDetails.OrganizationId ?? '';
				setOrganizationId(OrgId);
			}
		}
	}, []);


	return (
		<>
			<Container className="sidemenubar">
				<Box className={`qadpt-setsidebar ${sidebarOpen ? "sidebar-open" : "sidebar-closed"}`}>
					<div className="qadpt-sidebarlist">Settings</div>
					<List className="qadpt-sidebarele">
						{[
							{ text: "Team", path: `/${OrganizationId}/team` },
							{ text: "Roles", path: `/${OrganizationId}/roles` },
							{ text: "Account", path: `/${OrganizationId}/accounts` },
							{ text: "Domain", path: "/settings/domains" },
							{ text: "Rights", path: "/settings/rights" },
							{ text: "Alerts", path: "/settings/alerts" },
							{ text: "Billing", path: "/settings/billing" },
							{ text: "Installation", path: "/settings/install" },
							{text:"Activity Log", path:"settings/activitylog"}
						].map((item, index) => (
							<ListItem
								key={index}
								component={NavLink}
								to={item.path}
								className={`qadpt-sidebarinput ${location.pathname === item.path ? "qadpt-sidebarinput-active" : ""}`}
							>
								<ListItemText
									primary={
										<Typography
											className={`qadpt-sidebarval ${location.pathname === item.path ? "active" : ""}`}
											// sx={{
											// 	fontFamily: "Poppins",
											// 	fontSize: "14px",
											// 	fontWeight: 400,
											// 	lineHeight: "21px",
											// 	letterSpacing: "0.3px",
											// 	textAlign: "left",
											// 	color: location.pathname === item.path ? "#ffffff" : "#202224",
											// 	marginLeft: "-2px",
											// }}
										>
											{item.text}
										</Typography>
									}
								/>
							</ListItem>
						))}
					</List>
				</Box>
			</Container>
		</>
	);
};

export default Settings;

import React, { createContext, useContext, useState, ReactNode } from "react";

interface RtlContextType {
	isRtl: boolean;
	toggleRtl: () => void;
}

const RtlContext = createContext<RtlContextType | undefined>(undefined);

interface RtlProviderProps {
	children: ReactNode;
}

export const RtlProvider: React.FC<RtlProviderProps> = ({ children }) => {
	const [isRtl, setIsRtl] = useState(false);

	const toggleRtl = () => {
		setIsRtl((prevIsRtl) => !prevIsRtl);
	};

	return <RtlContext.Provider value={{ isRtl, toggleRtl }}>{children}</RtlContext.Provider>;
};

export const useRtl = (): RtlContextType => {
	const context = useContext(RtlContext);
	if (!context) {
		throw new Error("useRtl must be used within an RtlProvider");
	}
	return context;
};

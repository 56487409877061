import React, { useState, useEffect, useRef } from 'react';
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import {
  Container, Typography, Button, TextField, IconButton, InputAdornment,
  RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Avatar,
  Grid, Box, FormGroup, Switch, MenuItem, Select,
  Snackbar,
  Alert
} from '@mui/material';
import { CameraAlt, Edit } from '@mui/icons-material';
import { UpdateProfile, GetUserDetail } from "../../services/ProfileSettingPageService";
import { useLocation } from 'react-router-dom';
import { avatar } from '../../assets/icons/icons';
import ChangePasswordPopup from '../adminMenu/changepassword';
import { getOrganizationById } from "../../services/OrganizationService";
import { Organization } from '../../models/Organization';
import { GetUserDetailsById, getAllUsers } from '../../services/UserService';
import { useAuth } from "../auth/AuthProvider";
import { JSEncrypt } from "jsencrypt";
let profilePhotoData: string | null = null;
let initialssData: string;
const publicKey = process.env.REACT_APP_PUBLIC_ENCRYPT_KEY; 
const ProfileSettings = ({ selectedLanguageProp }: { selectedLanguageProp: any }) => {
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [contactEditable, setContactEditable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { signOut ,userDetails} = useAuth();
  const [isChangePasswordPopupOpen, setChangePasswordPopupOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
const handleSnackbarClose = () => {
  setSnackbarOpen(false);
};
 const [currentProfileData, setCurrentProfileData] = useState({
  UserId: '',
  FirstName: '',
  LastName: '',
  UserName: '',
   EmailId: '',
   Password: '',
   confirmpassword: '',
  ContactNumber: '',
  OrganizationId: '',
  ProfilePhoto: '',
  UserType: '',
  Gender: '',
  DateofBirth: '',
  TimeZone: '',
   LoginType: '',
   DateOfBirth: '',
  IsBlocked: false
 });

const [updatedProfileData, setUpdatedProfileData] = useState({ ...currentProfileData });
const [errors, setErrors] = useState({
  FirstName: '',
  LastName: '',
  ContactNumber: '',
  DateofBirth:'',
});
useEffect(() => {
  const unsubscribe = subscribe(setSidebarOpen);
  return () => unsubscribe();
}, []);
const [selectedImage, setSelectedImage] = useState<File | null>(null); 
const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null); 

useEffect(() => {
  const fetchUserDetails = async () => {
    const userData = await GetUserDetailsById(userDetails?.UserId);
    if (userData) {
      setCurrentProfileData({
        ...currentProfileData, 
        ...userData.data
      });
      setUpdatedProfileData({
        ...updatedProfileData,
        ...userData.data,
      });
      if (userData?.data?.ProfilePhoto) {
        setProfileImageUrl(userData?.data?.ProfilePhoto);  
      }
    }
  };

  fetchUserDetails();
}, [userDetails]);
  
    useEffect(() => {
    const fetchOrganization = async (userDetails:any) => {
     
      var orgDetails = await getOrganizationById(userDetails?.OrganizationId);
      setOrganizationDetails(orgDetails);
     
    };
    fetchOrganization(userDetails);
    }, [userDetails]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    
    const handleDateChange = (date: any) => {
      setSelectedDate(date);
      
      setUpdatedProfileData((prev: any) => ({ ...prev, DateofBirth: date ? date.format("MM/DD/YYYY") : "" }));
      if (!date) {
        setErrors((prev) => ({ ...prev, DateofBirth: "Date of Birth is required." }));
      } else {
        setErrors((prev) => ({ ...prev, DateofBirth: "" }));
      }
    };
const [organizationDetails, setOrganizationDetails] = useState<Organization | null>(null);
const handleCameraClick = () => {
  if (fileInputRef.current) {
    fileInputRef.current.click();
  }
};
  const [fileError, setFileError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const maxSizeMB = 5;
      if (file.size > maxSizeMB * 1024 * 1024) {
        setFileError(`File size exceeds ${maxSizeMB}MB`);
        return;
      }

      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        setFileError('Only .jpeg and .png files are allowed');
        return;
      }

      setFileError(null); // Clear any previous error messages
      setSelectedImage(file);
      toBase64(file).then(base64 => {
        setUpdatedProfileData({ ...updatedProfileData, ProfilePhoto: base64 });
      });
    }
  };

  const toBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

const handleInputChange = (event: any) => {
  const { name, value } = event.target;

  let errorMessage = '';

  if (name === 'FirstName') {
    if (value.length < 3 || value.length > 50) {
      errorMessage = 'First Name must be between 3 and 50 characters.';
    } else if (!/^[a-zA-Z]+$/.test(value)) {
      errorMessage = 'First Name should contain only alphabets.';
    }
  } else if (name === 'LastName') {
    if (value.length < 3 || value.length > 50) {
      errorMessage = 'Last Name must be between 3 and 50 characters.';
    } else if (!/^[a-zA-Z]+$/.test(value)) {
      errorMessage = 'Last Name should contain only alphabets.';
    }
  } else if (name === 'ContactNumber') {
    if (!validatePhoneNumber(value)) {
      errorMessage = 'Phone number should contain only digits.';
    }
    else if (value.length !== 10) {
      errorMessage = 'Phone number must be exactly 10 digits long.';
    }

  }
  setErrors((prevErrors) => ({
    ...prevErrors,
    [name]: errorMessage,
  }));

  setUpdatedProfileData((prevData) => ({
    ...prevData,
    [name as string]: value,
  }));
  
};

const validatePhoneNumber = (number: string): boolean => {
  const phoneNumberPattern = /^[0-9]*$/;
  return phoneNumberPattern.test(number);
};


const handleSubmit = async (event: React.FormEvent) => {
  event.preventDefault();
  if (!validatePhoneNumber(updatedProfileData.ContactNumber)) {
    console.error('Invalid phone number');
    return;
  }
  try {
    const response = await UpdateProfile(updatedProfileData);
    setCurrentProfileData(updatedProfileData);
    if (currentProfileData !== updatedProfileData) {
      const firstNameInitials = currentProfileData.FirstName ? updatedProfileData.FirstName.substring(0, 1).toUpperCase() : '';
      const lastNameinitials = currentProfileData.LastName ? updatedProfileData.LastName.substring(0, 1).toUpperCase() : '';
      const finalData = firstNameInitials + lastNameinitials;
      initialssData = finalData;
    }
    if (response.Success) {
      setSnackbarMessage("Profile Updated Successfully");
      setSnackbarSeverity("success");
    } else {
      setSnackbarMessage('Failed to Update Profile: ' + response.ErrorMessage);
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 2000);
  } catch (error) {
    console.error('Error updating profile:', error);
  }
};
useEffect(() => {
  profilePhotoData = updatedProfileData.ProfilePhoto;
}, [updatedProfileData.ProfilePhoto]);


  const hasErrors = () => {
  return Object.values(errors).some(error => error !== '');
};

  const hasChanges = () => {
  return JSON.stringify(currentProfileData) !== JSON.stringify(updatedProfileData);
};

const handlePasswordChangeClick = () => {
  setChangePasswordPopupOpen(true);
};

const closeChangePasswordPopup = () => {
  setChangePasswordPopupOpen(false);
};

const handleContactBlur = () => {
  if (updatedProfileData.ContactNumber) {
    setContactEditable(false);
  }
};

const getYesterdayDate = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today.toISOString().split('T')[0];
};
  return (
    <div className='.page-container'>
     <Box className={`qadpt-prfsidebar ${sidebarOpen ? 'open' : 'closed'}`}
>
  <Typography
    className='qadpt-prftitle'
  >
    User Profile
  </Typography>

  <Button
  type="submit"
  className={`qadpt-prfbtn ${hasErrors() || !hasChanges() || !!fileError ? 'qadpt-prfbtn-disabled' : 'qadpt-prfbtn-enabled'}`}
  disabled={hasErrors() || !hasChanges() || !!fileError}
  onClick={handleSubmit}
>
  Save Changes
</Button>

      </Box>


      <Box
        className={`qadpt-prfbox ${sidebarOpen ? 'open' : 'closed'}`}
      >
        <Box className='qadpt-subtle'>
          
            Profile
          
        </Box>


        <form noValidate style={{ marginLeft: "95px" }} onSubmit={handleSubmit}>
        <Box className="qadpt-prfimgcon" style={{ position: 'relative', textAlign: 'center' }}>
  {selectedImage ? (
    <img
      src={URL.createObjectURL(selectedImage)} 
      alt="Profile"
      className="qadpt-sltimg"
    />
  ) : profileImageUrl ? (
    <img
      src={profileImageUrl}  
      alt="Profile"
      className="qadpt-sltimg"
    />
  ) : (
    <img
      src={avatar}  
      alt="Profile"
      className="qadpt-prfimg"
    />
  )}

  <IconButton
    className="qadpt-editcont"
    onClick={handleCameraClick}
  >
    <Edit className="qadpt-editicon" />
  </IconButton>

  <input
    type="file"
    accept="image/*"
    style={{ display: 'none' }}
    ref={fileInputRef}
    onChange={handleFileChange}
  />

  {fileError && (
    <span 
      className='qadpt-file-error'
    >
      {fileError}
    </span>
  )}
</Box>





          <Grid container spacing={2} sx={{ marginTop: "35px", marginLeft: '-90px' }}>
          <Grid item xs={6} md={4.7}>
  <Box className='qadpt-txtctrl'>First Name
    <TextField
      name="FirstName"
      value={updatedProfileData.FirstName}
      onChange={handleInputChange}
      fullWidth
      margin="normal"
      variant="outlined"
      className='qadpt-prffldval'
      required
      error={Boolean(errors.FirstName)}
      helperText={errors.FirstName}
    />
  </Box>
</Grid>

<Grid item xs={6} md={4.7}>
  <Box className='qadpt-txtctrl'>Last Name
    <TextField
      name="LastName"
      value={updatedProfileData?.LastName}
      onChange={handleInputChange}
      fullWidth
      margin="normal"
      variant="outlined"
      className='qadpt-prffldval'
      required
      error={Boolean(errors.LastName)}
      helperText={errors.LastName}
    />
  </Box>
</Grid>


            <Grid item xs={6} md={4.7}>
            <Box className='qadpt-txtctrl'>Email
                <TextField
                  name="EmailId"
                  value={updatedProfileData?.EmailId}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  className='qadpt-prffldval'
                  required
                  disabled
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={4.7}>
  <Box className='qadpt-txtctrl'>Phone Number
    <TextField
      name="ContactNumber"
      value={updatedProfileData?.ContactNumber}
      onChange={handleInputChange}
      onBlur={handleContactBlur}
      fullWidth
      margin="normal"
      variant="outlined"
      className='qadpt-prffldval'
      required
      inputProps={{ type: 'tel', pattern: '[0-9]*' }}
      error={Boolean(errors.ContactNumber)}
      helperText={errors.ContactNumber}
    />
  </Box>
</Grid>

            <Grid item xs={4.7}>
            <Box className='qadpt-txtctrl'>Gender
                <TextField
                  select
                  name="Gender"
                  value={updatedProfileData?.Gender}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  className='qadpt-prffldval'
                  required
    >
      <MenuItem value="Male">Male</MenuItem>
      <MenuItem value="Female">Female</MenuItem>
      <MenuItem value="Preferred Not to say ">Preferred Not to say </MenuItem>
    </TextField>
              </Box>
            </Grid>
            <Grid
							item
							xs={6} md={4.7}
            >
              <Box className='qadpt-txtctrl'>
							Date of birth
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
                    //maxDate={dayjs().subtract(10, 'year').endOf('day')}// 10 years
                    maxDate={dayjs().subtract(1, 'day')}
									value={updatedProfileData?.DateofBirth}
                  onChange={handleDateChange}
                 
									renderInput={(params) => (
										<TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      className='qadpt-prffldval'
											error={Boolean(errors.DateofBirth)}
											helperText={errors.DateofBirth}
											required
										/>
									)}
								/>
              </LocalizationProvider>
              </Box>
						</Grid>
            <Grid style={{ marginTop: '103px',marginLeft:"-841px" }}>
        <Button
          onClick={handlePasswordChangeClick}
          className='qadpt-prfchgpwd'
          
        >
         
            Change Password
        </Button>
      </Grid>

      <ChangePasswordPopup
        open={isChangePasswordPopupOpen}
        onClose={closeChangePasswordPopup}
      />
  
          </Grid>
        </form>
      </Box>
      <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={handleSnackbarClose}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    sx={{ zIndex: 10000,marginTop:4}} 
>
    <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
    >
        {snackbarMessage}
    </Alert>
</Snackbar>
    </div>
  );
};


const getProfilePhoto = () => profilePhotoData;

export default ProfileSettings;

export {getProfilePhoto, initialssData };

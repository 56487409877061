
// -----------------------Account APIs -----------------------
import { Status } from "../models/Status";
import { adminApiService, userApiService }  from "./APIService";


let AccountDeleteDetails = {
	AccountId: "",
	AccountName: "",
	AccountType: "",
	CreatedBy: "",
	CreatedDate: "",
	OrganizationId: "",
	UpdatedBy: "",
	UpdatedDate: "",
	Active: Boolean(true),
};

const adminUrl = process.env.REACT_APP_ADMIN_API

export const GetAllAccounts = async (setModels: any, setLoading: any) => {
	try {
		const response = await adminApiService.get("/Account/GetAllAccounts");
		let apiData = response.data;

		if (Array.isArray(apiData)) {
			apiData = apiData.map((account) => ({
				...account,
				CreatedDate: account.CreatedDate.split("T")[0],
				UpdatedDate: account.UpdatedDate ? account.UpdatedDate.split("T")[0] : null,
			}));
			setModels(apiData);
		} else {
			console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching Accounts:", error);
	} finally {
		//setLoading(false);
	}
};

export const GetAccountsList = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	orderByField: any,
	filters: any
) => {
	try {
		setLoading(true);
		const requestBody = {
			skip,
			top,
			orderByFields: orderByField, // Assuming an empty string for now, adjust as necessary
			filters: filters ? filters : "", // Assuming an empty array for now, adjust as necessary
			organizationId: OrganizationId,
		};
		const response = await adminApiService.post(`/Account/GetAccountsByOrgId`, requestBody);
		// const response = await adminApiService.get(
		// 	`/Account/GetAccountsByOrgId?organizationId=${OrganizationId}&skip=${skip}&top=${top}`
		// );
		let apiData = response.data.results;
		setTotalcount(response.data._count);

		if (Array.isArray(apiData)) {
			apiData = apiData.map((account) => ({
				...account,
				CreatedDate: account.CreatedDate.split("T")[0],
				UpdatedDate: account.UpdatedDate ? account.UpdatedDate.split("T")[0] : null,
			}));
			setModels(apiData);
		} else {
			console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching Accounts:", error);
	} finally {
		//setLoading(false);
	}
};

// In AccountService

// export const SubmitCreateAccount = async (
// 	setLoading: any,
// 	setShowPopup: any,
// 	setModels: any,
// 	inputs: any,
// 	setSnackbarMessage: any,
// 	setSnackbarSeverity: any,
// 	setSnackbarOpen: any,
// 	OrganizationId: any,
// 	skip: any,
// 	top: any,
// 	setTotalcount: any,
// 	openSnackbar: any
// ) => {
// 	setLoading(true);
// 	try {
// 		const response = await fetch(`${adminUrl}/Account/CreateAccount`, {
// 			method: "POST",
// 			headers: {
// 				"Content-Type": "application/json",
// 			},
// 			body: JSON.stringify(inputs),
// 		});

// 		if (!response.ok) {
// 			setSnackbarMessage("Account created successfully");
// 			setSnackbarSeverity("success");
// 			setSnackbarOpen(true);
// 			throw new Error("Network response was not ok");
// 		}

// 		setSnackbarMessage("Account created successfully");
// 		setSnackbarSeverity("success");
// 		setSnackbarOpen(true);
// 		setShowPopup(false);
// 		GetAccountsList(setModels, setLoading, OrganizationId, skip, top, setTotalcount);

// 		//GetAllAccounts(setModels, setLoading);
// 	} catch (error: any) {
// 		setSnackbarMessage(`Error creating Account: ${error.message}`);
// 		setSnackbarSeverity("error");
// 		setSnackbarOpen(true);
// 	} finally {
// 		//setLoading(false);
// 	}
// };

export const SubmitCreateAccount = async (
	setLoading: any,
	setShowPopup: any,
	setModels: any,
	inputs: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	openSnackbar: any,
	orderByField: any,
	filters: any
) => {
	try {
		setLoading(true);
		const response = await fetch(`${adminUrl}/Account/CreateAccount`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(inputs),
		});

		if (response.ok) {
			const data = await response.json();
			if (data.Success) {
				openSnackbar(data.SuccessMessage, "success");
			} else {
				openSnackbar(data.ErrorMessage, "error");
			}
			setShowPopup(false);
			GetAccountsList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByField, filters);
		} else {
			console.error("Failed to create account");
		}
	} catch (error: any) {
		console.error("Error creating account:", error);
		openSnackbar(`Error creating account: ${error.message}`, "error");
	} finally {
		setLoading(false);
	}
};

export const SubmitAccountDetails = async (
	setLoading: any,
	setModels: any,
	setShowEditPopup: any,
	AccountDetails: any,
	organizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	openSnackbar: any,
	orderByField: any,
	filters: any
) => {
	try {
		const response = await fetch(`${adminUrl}/Account/Update`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(AccountDetails),
		});

		if (response.ok) {
			// Handle successful update (e.g., show a success message, close the popup)
			//GetAllAccounts(setModels, setLoading);
			const data = await response.json();
			if (response.ok && data.Success) {
				console.log(data);
				//setMessage(`Success: ${data.message}`);
				openSnackbar(data.SuccessMessage, "success");
			} else {
				//setMessage(`Error: ${data.message}`);
				openSnackbar(data.ErrorMessage, "error");
			}
			GetAccountsList(setModels, setLoading, organizationId, skip, top, setTotalcount, orderByField, filters);
			setShowEditPopup(false);
		} else {
			// Handle error
			//setShowEditPopup(false);
			//GetAllAccounts(setModels, setLoading);
		}
	} catch (error: any) {
		console.log("account edited not succesfull", error);
	} finally {
		//setLoading(false);
	}
};

export const fetchDeleteAccountDetails = async (
	accountidedit: any,
	setLoading: any,
	setModels: any,
	setShowDeletePopup: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,
	setSnackbarOpen: any,
	openSnackbar: any,
	orderByField: any,
	filters: any
) => {
	try {
		const response = await fetch(`${adminUrl}/Account/GetAccountById?accountId=${accountidedit}`);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const data = await response.json();
		AccountDeleteDetails = {
			AccountId: data.AccountId,
			AccountName: data.AccountName,
			AccountType: data.AccountType,
			CreatedBy: data.CreatedBy,
			CreatedDate: data.CreatedDate,
			OrganizationId: data.OrganizationId,
			UpdatedBy: data.UpdatedBy,
			UpdatedDate: data.UpdatedDate,
			Active: false,
		};
		DeleteAccountDetails(
			setLoading,
			setModels,
			setShowDeletePopup,
			AccountDeleteDetails,
			OrganizationId,
			skip,
			top,
			setTotalcount,
			setSnackbarMessage,
			setSnackbarSeverity,
			setSnackbarOpen,
			openSnackbar,
			orderByField,
			filters
		);
	} catch (error) {
		console.error("Failed to fetch user details:", error);
	}
};

export const DeleteAccountDetails = async (
	setLoading: any,
	setModels: any,
	setShowDeletePopup: any,
	AccountDeleteDetails: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,
	setSnackbarOpen: any,
	openSnackbar: any,
	orderByField: any,
	filters: any
) => {
	try {
		const response = await fetch(`${adminUrl}/Account/Delete`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(AccountDeleteDetails),
		});

		if (response.ok) {
			// Handle successful update (e.g., show a success message, close the popup)
			const data = await response.json();
			if (response.ok && data.Success) {
				console.log(data);
				//setMessage(`Success: ${data.message}`);
				openSnackbar(data.SuccessMessage, "success");
			} else {
				//setMessage(`Error: ${data.message}`);
				openSnackbar(data.ErrorMessage, "error");
			}
			GetAccountsList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByField, filters);
			setShowDeletePopup(false);
		}
	} catch (error: any) {
		setSnackbarMessage(`Error Deleting user: ${error.message}`);
		setSnackbarSeverity("error");
		setSnackbarOpen(true);
	}
};

// ---------------------Guide APIs -------------------------

export const saveGuide = async (guideData : any) => {
    const formattedGuideData = {
        GuideId: guideData.GuideId,
        Title: guideData.Title,
        Content: guideData.Content,
        OrganizationId: guideData.OrganizationId,
        CreatedDate: guideData.CreatedDate,
        UpdatedDate: guideData.UpdatedDate,
        CreatedBy: guideData.CreatedBy,
        UpdatedBy: guideData.UpdatedBy,
        GuideStep: guideData.GuideStep.map((step : any) => ({
            StepTitle: step.StepTitle,
            Text: step.Text,
            Element: step.Element,
            On: step.On,
            Image: step.Image,
            BackgroundColor: step.BackgroundColor,
            Id: step.Id,
            Arrow: step.Arrow,
            Classes: step.Classes,
            IsClickable: step.IsClickable,
        }))
    };

    try {
        const response = await adminApiService.post<Status>("/Guide/Saveguide", formattedGuideData);
        return response.data;
    } catch (error) {
        console.error("Error saving guide:", error);
        throw error;
    }
};


export const getAllGuides = async (): Promise<any[]> => {
	try {
	  const response = await adminApiService.get("/Guide/GetAllguides");
	  return response.data;
	} catch (error) {
	  console.error("Error fetching guides:", error);
	  throw error;
	}
  };
  export const deleteGuide = async (guideId: string): Promise<void> => {
    try {
        const response = await adminApiService.post(`/Guide/Deleteguide?guideId=${guideId}`);
        if (response.status === 204) {
            console.log('Guide deleted successfully');
        } else {
            console.error('Failed to delete guide');
        }
    } catch (error) {
        console.error('Error deleting guide:', error);
        throw error;
    }
};


export const GetAllAccountsList = async (
	skip: number,
	top: number,
	orderByFields: string,
	filters: any[],
	organization: string,
	//setTotalcount: (count: number) => void
  ): Promise<any[]> => {
	try {
	  const response = await adminApiService.post("/Account/GetAllAccounts", {
		skip,
		top,
		orderByFields,
		filters, 
		organization
	  });
	  const apiData = response.data.results;
	  //setTotalcount(response.data._count);
	  if (Array.isArray(apiData)) {
		return apiData;
	  } else {
		console.error("Invalid data format from API:", apiData);
		return [];
	  }
	} catch (error) {
	  console.error("Error fetching Accounts:", error);
	  return [];
	}
  };
  
  



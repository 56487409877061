  
  
import React, { useState } from 'react';

import { isSidebarOpen } from "../adminMenu/sidemenustate";
import { Container,Box } from '@mui/material';

const AlertSettings = () => {
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  return (
    <Container>
    <div className="qadpt-midpart setng-box">
         <div className="qadpt-content-block">
         <div className="qadpt-head">
     <div className="qadpt-title-sec">
       <div className="qadpt-title">Alert Settings</div>
       
     </div>
     <div className="qadpt-right-part">
               
       </div>
     <div>
     </div>
    </div> 
        <Box className="qadpt-content-box">
              <div className="qadpt-grd-head">						
                     <Box className="slt-acc-drp">                        
                     </Box>               
                     <div className="qadpt-usrfilter">                  
                     </div>
              </div>        
                       
          </Box>
        </div>
        </div>
 </Container>
    
    );
  };
  

export default AlertSettings
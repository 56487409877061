import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { organizationsList } from "../organization/orgData";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { Box } from "@mui/material";
interface CustomPaginationProps {
	pageCount: number;
	page: number;
	onPageChange: (newPage: number) => void;
	onPageSizeChange: (newPageSize: number) => void;
	rowsPerPageOptions: number[];
	totalRows: number;
}

interface CustomGridProps {
	rows: any[];
	columns: any[];
	pageSize: number;
	totalRows: number;
	onPageChange: (newPage: number, newPageSize: number) => void;
	onPageSizeChange: (newPageSize: number, newSkip: number) => void;
	rowsPerPageOptions: number[];
	Toolbar: React.FC<any>;
}

const CustomGrid: React.FC<CustomGridProps> = ({
	rows,
	columns,
	pageSize,
	totalRows,
	onPageChange,
	onPageSizeChange,
	rowsPerPageOptions,
	Toolbar,
	//getRowId
}) => {
	const [gridData, setGridData] = useState(rows);
	//const [rowid, setRowid] = useState(getRowId);
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [pagination, setPagination] = useState({
		page: 0,
		pageSize,
		pageCount: Math.ceil(totalRows / pageSize),
	});

	var currentURL = window.location.href;
	var lastValue = currentURL.substring(currentURL.lastIndexOf("/") + 1);

	let totalrowss: any;
	const fetchGridData = async (skip: number, top: number) => {
		try {
			const data = organizationsList.data;
			const dataWithSNO = data.map((item: any, index: any) => ({
				...item,
				SNO: index + 1,
			}));
			setGridData(dataWithSNO); // Update the entire grid data
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		setGridData(rows);
		setPagination((prev) => ({
			...prev,
			pageCount: Math.ceil(totalRows / pageSize),
		}));
	}, [rows, pageSize, totalRows]);

	useEffect(() => {
		setGridData(rows);
	}, [rows]);

	const handlePageChange = (newPage: number) => {
		const pageno = pagination.pageSize;
		const newSkip = pageno * newPage;
		setPagination((prev) => ({ ...prev, page: newPage }));
		onPageChange(newPage, newSkip);
		fetchGridData(newSkip, pageno); // Fetch data for the new page
	};

	const handlePageSizeChange = (newPageSize: number) => {
		const newSkip = pagination.page * newPageSize;
		setPagination((prev) => ({
			...prev,
			pageSize: newPageSize,
			page: 0,
			pageCount: Math.ceil(totalRows / newPageSize),
		}));
		onPageSizeChange(newPageSize, newSkip);
		fetchGridData(newSkip, newPageSize); // Fetch data for the first page with the new page size
	};

	const formatDate = (dateString: string) => {
		const dateObject = new Date(dateString);
		const year = dateObject.getFullYear();
		const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
		const day = dateObject.getDate().toString().padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const modifiedColumns = columns.map((column) => {
		if (
			column.field === "CreatedDate" ||
			column.field === "Created_Date" ||
			column.field === "CreateDate" ||
			column.field === "LastUsed"
		) {
			return {
				...column,
				valueFormatter: (params: any) => formatDate(params),
			};
		}
		return {
			...column,
			flex: 1, // Adjusts column width automatically
		};
		//return column;
	});

	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
	}, []);

	return (
		<div>
			<Box
				className="userlistGrid"
				sx={{
					height: 467,
					width: sidebarOpen ? "100%" : "100%",
					marginTop: 2,
					marginLeft: sidebarOpen ? "1px" : "1px",
					top: "-20px",
					position: "relative",
				}}
			>
				<DataGrid
					sx={{ border: "1px solid grey !important", borderRadius: "9px" }}
					//slots={{ toolbar: GridToolbar }}
					style={{
						backgroundColor: "#FFFFFF",
						fontSize: "15px",
						marginLeft: "4px",
						border: "1px solid black !important",
					}}
					rows={gridData}
					columns={modifiedColumns}
					rowCount={totalRows}
					getRowId={(row) =>
						row.AccountId ||
						row.Account_ID ||
						row.AccountName ||
						row.id ||
						row.Created_Date ||
						row.UserId ||
						row.Id ||
						row.OrganizationId ||
						row.ImageId
					}
					pageSizeOptions={rowsPerPageOptions}
					disableRowSelectionOnClick={true}
				/>
			</Box>
		</div>
	);
};

export default CustomGrid;


// ---------------------------Singup -----------------------------------------

import {adminApiService,userApiService,adminUrl,JToken} from "./APIService";
import { RegistrationPayload } from "../models/RegistrationPayload";
import { Status } from "../models/Status";

// Function for self sign-up
export const selfSignUp = async (userData: JToken): Promise<Status> => {
	try {
		const response = await userApiService.post<Status>("/Organization/SelfSignUp", userData);
		return response.data;
	} catch (error) {
		console.error("Error in self sign-up:", error);
		throw error;
	}
};


export const registerUser = async (userData: RegistrationPayload): Promise<Status> => {
	try {
		const response = await userApiService.post<Status>("/User/Post", userData, {
			params: {
				additionalProp1: "string",
				additionalProp2: "string",
				additionalProp3: "string",
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error registering user:", error);
		throw error;
	}
};
  
  
import React from 'react';


const NotificationSettings = () => {
    return (
      <h1>Notification Settings  Page</h1>
    );
  };
  

export default NotificationSettings
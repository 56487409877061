import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "../../SnackbarContext";
import { JSEncrypt } from "jsencrypt";
import { TextField, Button, FormControl, SelectChangeEvent, Box, Grid, ButtonGroup } from "@mui/material";
import { fetchUsersList, getAllUsers, SubmitCreateUser } from "../../services/UserService";
import { AnyCnameRecord } from "dns";
import { useAuth } from "../auth/AuthProvider";
import { User } from "../../models/User";
import { error } from "console";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


type InputFields = {
	FirstName: string;
	LastName: string;
	EmailId: string;
	UserType: string;
	Password: string;
	ContactNumber: string;
	Gender: string;
	DateofBirth: string;
	OrganizationId: string;
};

const generatePassword = () => {
	const length = 12;
	const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	const lowercase = "abcdefghijklmnopqrstuvwxyz";
	const numbers = "0123456789";
	const specialChars = "!@#$%^&*";
	let password =
		uppercase[Math.floor(Math.random() * uppercase.length)] +
		lowercase[Math.floor(Math.random() * lowercase.length)] +
		numbers[Math.floor(Math.random() * numbers.length)] +
		specialChars[Math.floor(Math.random() * specialChars.length)];
	const allChars = uppercase + lowercase + numbers + specialChars;
	for (let i = password.length; i < length; i++) {
		password += allChars[Math.floor(Math.random() * allChars.length)];
	}
	
	return password
		.split("")
		.sort(() => Math.random() - 0.5)
		.join("");
};
type ErrorFields = Partial<InputFields>;
type TouchedFields = Partial<Record<keyof InputFields, boolean>>;
const publicKey = process.env.REACT_APP_PUBLIC_ENCRYPT_KEY;

export default function UserCreate(props: any) {
	const {
		setModels,
		setLoading,
		setShowPopup,
		setShowEditPopup,
		skip,
		top,
		setTotalcount,
		orderByFields,
		filters,
	} = props;
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");
	const { openSnackbar } = useSnackbar();
	const [inputs, setInputs] = useState<InputFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		UserType: "User",
		Password: generatePassword(),
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: OrganizationId,
	});

	const [errors, setErrors] = useState<ErrorFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		UserType: "",
		Password: "",
		//ConfirmPassword: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: "",
	});

	const [apierror, setApiError] = useState("");
	const [isTouched, setIsTouched] = useState<TouchedFields>({
		FirstName: false,
		LastName: false,
		EmailId: false,
		UserType: false,
		Password: false,
		//ConfirmPassword: false,
		ContactNumber: false,
		Gender: false,
		DateofBirth: false,
		OrganizationId: false,
	});
	const [showPassword, setShowPassword] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	// const encryptData = (data: any) => {
	// 	const encrypt = new JSEncrypt();
	// 	encrypt.setPublicKey(publicKey ? publicKey : "");
	// 	const encryptedData = encrypt.encrypt(data);
	// 	return encryptedData;
	// };
	const [emails, setEmails] = useState<string[]>([]);
	const [contactNumbers, setContactNumbers] = useState<string[]>([]);
	const [formValid, setFormValid] = useState(false);


  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const users = await getAllUsers();
        if (users) {
          const numbers = users.map(user => user.ContactNumber);
          const userEmails = users.map(user => user.EmailId);
          setContactNumbers(numbers);
          setEmails(userEmails);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);
	const validateInputs = () => {
		const { FirstName, LastName, EmailId, ContactNumber, Password } = inputs;
		const newErrors: Partial<InputFields> = {};
	
		// First Name Validation
	   
		if (FirstName.length < 3 || FirstName.length > 50) {
		  newErrors.FirstName = 'First Name should contain 3-50 characters';
		}  else if (!/^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(FirstName)) {
		  newErrors.FirstName = 'First Name can only contain alphabets, spaces, apostrophes, and hyphens';
	  }  else {
		  newErrors.FirstName = '';
		}
	
		// Last Name Validation
	  
		 if (LastName.length < 3 || LastName.length > 50) {
		  newErrors.LastName = 'Last Name should contain 3-50 characters';
		}  else if (!/^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(LastName)) {
		  newErrors.LastName = 'Last Name can only contain alphabets, spaces, apostrophes, and hyphens';
	  } else {
		  newErrors.LastName = '';
		}
	
		// Email Validation
		if (EmailId.length == 0) {
		  newErrors.EmailId = 'Email is Required';
		}
		else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/.test(EmailId)) {
		  newErrors.EmailId = 'Email is invalid';
		} else if (emails.includes(EmailId)) {
		  newErrors.EmailId = 'Email already exists';
		} else {
		  newErrors.EmailId = '';
		}
	
		// Contact Number Validation
		if (!/^\d+$/.test(ContactNumber)) {
		  newErrors.ContactNumber = 'Contact Number should contain only numbers';
		} else if (ContactNumber.length !== 10) {
		  newErrors.ContactNumber = 'Contact Number should be 10 digits';
		} else if (contactNumbers.includes(ContactNumber)) {
		  newErrors.ContactNumber = 'Contact Number already exists';
		} else {
		  newErrors.ContactNumber = '';
		}
	
		// Password Validation
		if (Password.length < 8 || Password.length > 12) {
		  newErrors.Password = 'Password must be 8-12 characters long';
		} else if (!/[A-Z]/.test(Password)) {
		  newErrors.Password = 'Password must contain one uppercase letter';
		} else if (!/[a-z]/.test(Password)) {
		  newErrors.Password = 'Password must contain one lowercase letter';
		} else if (!/\d/.test(Password)) {
		  newErrors.Password = 'Password must contain one number';
		} else if (!/[!@#$%^&*]/.test(Password)) {
		  newErrors.Password = 'Password must contain one special character';
		} else {
		  newErrors.Password = '';
		}
	
	
		setErrors(newErrors);
		setFormValid(Object.keys(newErrors).every(key => newErrors[key as keyof InputFields] === ''));
		
		return Object.keys(newErrors).every(key => newErrors[key as keyof InputFields] === '');
	  };

	const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setInputs((prev) => ({ ...prev, [name]: value }));
		let processedValue = value;
		let error = "";

		if (name === "FirstName" || name === "LastName") {
			processedValue = value.replace(/[^a-zA-Z]/g, "");
		}

		setInputs((prev) => ({ ...prev, [name]: processedValue }));

		if (name === "FirstName" && processedValue.length < 3) {
			error = "First Name must be at least 3 characters.";
		}
		if (name === "LastName" && processedValue.length < 3) {
			error = "Last Name must be at least 3 characters.";
		}

		// if (name === "Password") {
		// 	const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/;
		// 	if (!passwordRegex.test(value)) {
		// 		error =
		// 			"Password must contain 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be 8-20 characters long.";
		// 	}
		// 	if (value.length < 8 || value.length > 12) {
		// 		error = "Password must be 8-12 characters long";
		// 	} else if (!/[A-Z]/.test(value)) {
		// 		error = "Password must contain one uppercase letter";
		// 	} else if (!/[a-z]/.test(value)) {
		// 		error = "Password must contain one lowercase letter";
		// 	} else if (!/\d/.test(value)) {
		// 		error = "Password must contain one number";
		// 	} else if (!/[!@#$%^&*]/.test(value)) {
		// 		error = "Password must contain one special character";
		// 	} else {
		// 		error = "";
		// 	}
		// }
		// if (name === "ConfirmPassword") {
		// 	if (value !== inputs.Password) {
		// 		error = "Passwords do not match.";
		// 	}
		// }

		if (name === "ContactNumber") {
			const numericValue = value.replace(/\D/g, ""); // Remove all non-numeric characters
			if (numericValue.length !== 10) {
			  error = "Contact Number must be exactly 10 digits.";
			}
			processedValue = numericValue;
			setInputs((prev) => ({ ...prev, [name]: processedValue }));
		  }

		if (name === "EmailId") {
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (value && !emailRegex.test(value)) {
				error = "Invalid email format.";
			}
		
		}
		setIsValid(true);
		setErrors((prev) => ({ ...prev, [name]: error }));
	};

	const handleSelectChange = (event: SelectChangeEvent<string>) => {
		const { name, value } = event.target;
		setInputs((prev) => ({ ...prev, [name as string]: value }));

		let error = "";
		if (value === "") {
			error = `${name} is required.`;
		}
		setErrors((prev) => ({ ...prev, [name as string]: error }));
	};

	// useEffect(() => {
	// 	if (!inputs.ContactNumber) {
	// 		setInputs((prev) => ({ ...prev, ContactNumber: "+91-" }));
	// 	}
	// }, []);

	useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString) { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					if (parsedUser) {
						const OrgId = parsedUser.OrganizationId ?? '';
						setOrganizationId(OrgId);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		
	}, []);

	const [selectedDate, setSelectedDate] = useState(dayjs());

	const handleDateChange = (date: any) => {
		setSelectedDate(date);
		setInputs((prev) => ({ ...prev, DateofBirth: date ? date.format("MM/DD/YYYY") : "" }));
		if (!date) {
			setErrors((prev) => ({ ...prev, DateofBirth: "Date of Birth is required." }));
		} else {
			setErrors((prev) => ({ ...prev, DateofBirth: "" }));
		}
	};

	const handleGenderChange = (event: { target: { name: string; value: string } }) => {
		const value = event.target.value;
		setIsValid(true);
		setInputs((prev: any) => ({
			...prev,
			Gender: value,
		}));
	};
	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const newErrors: ErrorFields = {};
		let isValid = true;
		let errorMessages = "";
		if (!validateInputs()) {
			return;
		}
		try {
			SubmitCreateUser(
				setLoading,
				setShowPopup,
				setModels,
				setShowEditPopup,
				inputs,
				OrganizationId,
				skip,
				top,
				setTotalcount,
				openSnackbar,
				orderByFields,
				filters,setApiError,
			);
		} catch (error: any) {
			openSnackbar("Failed to create user.", "error");
		}
		// (Object.keys(inputs) as (keyof InputFields)[]).forEach((key) => {
		// 	const value = inputs[key];

		// 	if (!value) {
		// 		newErrors[key] = `${key} is required.`;
		// 		isValid = false;
		// 	} else if (key === "FirstName") {
		// 		if (value.length < 3 || value.length > 50) {
		// 			newErrors[key] = "First Name must be between 3 and 50 characters.";
		// 			isValid = false;
		// 		}
		// 		if (/[^a-zA-Z]/g.test(value)) {
		// 			newErrors[key] = "First Name can only contain letters.";
		// 			isValid = false;
		// 		}
		// 	} else if (key === "LastName") {
		// 		if (value.length < 3 || value.length > 50) {
		// 			newErrors[key] = "Last Name must be between 3 and 50 characters.";
		// 			isValid = false;
		// 		}
		// 		if (/[^a-zA-Z]/g.test(value)) {
		// 			newErrors[key] = "Last Name can only contain letters.";
		// 			isValid = false;
		// 		}
		// 	}
				
	
		// 	// else if (key === "Password") {
		// 	// 	const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/;
		// 	// 	if (!passwordRegex.test(value)) {
		// 	// 		newErrors[key] =
		// 	// 			"Password must be 8-20 characters long, contain uppercase and lowercase letters, a digit, and a special character.";
		// 	// 		isValid = false;
		// 	// 	}
		// 	// } else if (key === "ConfirmPassword") {
		// 	// 	if (value !== inputs.Password) {
		// 	// 		newErrors[key] = "Passwords do not match.";
		// 	// 		isValid = false;
		// 	// 	}
		// 	//}
		// 	else if (key === "ContactNumber") {
		// 		const contactNumberRegex = /^\d{10}$/; // Regex for exactly 10 digits
		// 		if (!contactNumberRegex.test(value)) {
		// 		  newErrors[key] = "Contact Number must be exactly 10 digits.";
		// 		  isValid = false;
		// 		}
		// 	} else if (key === "EmailId") {
		// 		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		// 		if (!emailRegex.test(value)) {
		// 			newErrors[key] = "Invalid email format.";
		// 			isValid = false;
		// 		}
		// 	}
		// });

		// errorMessages = Object.values(newErrors).filter(Boolean).join(", ");

		// // Encrypt the password before creating the user
		// // const encryptedPassword = encryptData(inputs.Password);
		// // const userCreateCommand = {
		// // 	...inputs,
		// // 	Password: encryptedPassword,
		// // };

		// setErrors(newErrors);

		// if (isValid) {
		// 	setLoading(true);
		// 	console.log(OrganizationId);

		// 	try {
		// 		SubmitCreateUser(
		// 			setLoading,
		// 			setShowPopup,
		// 			setModels,
		// 			setShowEditPopup,
		// 			inputs,
		// 			OrganizationId,
		// 			skip,
		// 			top,
		// 			setTotalcount,
		// 			openSnackbar,
		// 			orderByFields,
		// 			filters,setApiError,
		// 		);
		// 	} catch (error: any) {
		// 		openSnackbar("Failed to create user.", "error");
		// 	}
		// }
		//else {
		// 	openSnackbar(errorMessages, "error");
		// }
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	return (
		<div className="qadpt-usercreatepopup">
			<div className="qadpt-title-sec">
							<div className="qadpt-title">Create Member</div>
							<div className="qadpt-description">create a new user to your plan</div>
				<svg
					onClick={() => setShowPopup(false)}
					className="qadpt-closeicon"
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					width="24"
					height="24"
					viewBox="0 0 50 50"
				>
					<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
				</svg>
				</div>
				<form
					onSubmit={handleSubmit}
					noValidate
				>
			<Box className="qadpt-formcontent">
				
				
						<Grid item xs={12} sm={12}>
	<div className="qadpt-usrname">
		<div>
			First Name
			<TextField
				name="FirstName"
				value={inputs.FirstName}
				onChange={handleTextFieldChange}
				placeholder="Enter your first name"
				helperText={errors.FirstName}
				variant="outlined"
				error={!!errors.FirstName}
				inputProps={{ minLength: 3, maxLength: 50 }}
				className="qadpt-userfields"
			/>
		</div>

		<div>
			Last Name
			<TextField
				name="LastName"
				value={inputs.LastName}
				onChange={handleTextFieldChange}
				placeholder="Enter your last name"
				helperText={errors.LastName}
				variant="outlined"
				error={!!errors.LastName}
				inputProps={{ minLength: 3, maxLength: 50 }}
				className="qadpt-userfields"
			/>
		</div>
	</div>
</Grid>

						<Grid
							item
							xs={12}
							sm={6}
						>
							{" "}
							Email Address
							<TextField
								name="EmailId"
								value={inputs.EmailId}
								onChange={handleTextFieldChange}
								//label="Email"
								error={Boolean(errors.EmailId)}
								helperText={
									errors.EmailId && (
										<>
											<ErrorOutlineIcon fontSize="small" style={{ marginRight: 4 }} />
											{errors.EmailId}
										</>
									)
								}
								//helperText={errors.EmailId}
								//helperText={apierror?apierror:errors.EmailId}
								placeholder="Enter your email address"
								variant="outlined"
								//error={!!errors.EmailId}
								required
								className="qadpt-userfields"
								// sx={{
								// 	"& .MuiFormHelperText-root": {
								// 		color: errors.EmailId ? "red" : "inherit",
								// 	},
								// 	"& .MuiOutlinedInput-root": {
								// 		padding: "0px",
								// 	},
								// 	"& .MuiInputBase-input": {
								// 		height: "0em",
								// 	},
								// 	width: "200px",
								// 	marginBottom: "-2px",
								// }}
							/>

						</Grid>
					
						{/* <Grid
							item
							xs={12}
							sm={6}
						>
							<p></p>Password
							<TextField
								name="Password"
								type={showPassword ? "text" : "password"}
								value={inputs.Password}
								onChange={handleTextFieldChange}
								//label="Password"
								placeholder="Abcd@123"
								helperText={errors.Password}
								variant="outlined"
								error={!!errors.Password}
								required
								inputProps={{ minLength: 8, maxLength: 20 }}
								sx={{
									"& .MuiFormHelperText-root": {
										color: errors.Password ? "red" : "inherit",
									},
									"& .MuiOutlinedInput-root": {
										padding: "0px",
									},
									"& .MuiInputBase-input": {
										height: "0em",
									},
									width: "200px",
									marginBottom: "-2px",
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleTogglePasswordVisibility}
												onMouseDown={(event) => event.preventDefault()}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
						>
							Confirm Password
							<TextField
								name="ConfirmPassword"
								type={showPassword ? "text" : "password"}
								value={inputs.ConfirmPassword}
								onChange={handleTextFieldChange}
								//label="Confirm Password"
								placeholder="Abcd@123"
								helperText={errors.ConfirmPassword}
								variant="outlined"
								error={!!errors.ConfirmPassword}
								required
								inputProps={{ minLength: 8, maxLength: 20 }}
								sx={{
									"& .MuiFormHelperText-root": {
										color: errors.Password ? "red" : "inherit",
									},
									"& .MuiOutlinedInput-root": {
										padding: "0px",
									},
									"& .MuiInputBase-input": {
										height: "0em",
									},
									width: "200px",
									marginBottom: "-2px",
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleTogglePasswordVisibility}
												onMouseDown={(event) => event.preventDefault()}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Grid> */}
						<Grid
							item
							xs={12}
							sm={6}
					>
							Contact Number
							<TextField
							name="ContactNumber"
								value={inputs.ContactNumber}
								onChange={handleTextFieldChange}
							placeholder="Enter 10 digits"
							variant="outlined"
								//helperText={errors.ContactNumber}
								helperText={
									errors.ContactNumber && (
										<>
											<ErrorOutlineIcon fontSize="small" style={{ marginRight: 4 }} />
											{errors.ContactNumber}
										</>
									)
								}
							error={!!errors.ContactNumber}
							required
							inputProps={{
								inputMode: "numeric",
								pattern: "\\d{10}", // Enforce 10 digits
								maxLength: 10, // Maximum of 10 characters
							}}
							/* Contact Number
							<TextField
							name="ContactNumber"
								value={inputs.ContactNumber}
								onChange={(event) => {
									let value = event.target.value;
									let cleanedValue = value.replace(/[^0-9+]/g, "");
									if (!cleanedValue.startsWith("+")) {
										cleanedValue = `+${cleanedValue}`;
									}
									if (cleanedValue.startsWith("+") && cleanedValue.length > 3 && !cleanedValue.includes("-")) {
										cleanedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3)}`;
									}
									setInputs((prev) => ({ ...prev, ContactNumber: cleanedValue }));
								}}
								placeholder="+91-XXXXXXXXXX"
								variant="outlined"
								helperText={errors.ContactNumber}
								error={!!errors.ContactNumber}
								required
								inputProps={{
									inputMode: "numeric",
									pattern: "\\+\\d{2}-\\d{10}",
									maxLength: 14,
								}} */
								className="qadpt-userfields"
								// sx={{
								// 	"& .MuiFormHelperText-root": {
								// 		color: errors.ContactNumber ? "red" : "inherit",
								// 	},
								// 	"& .MuiOutlinedInput-root": {
								// 		padding: "0px",
								// 	},
								// 	"& .MuiInputBase-input": {
								// 		height: "0em",
								// 	},
								// 	width: "200px",
								// 	marginBottom: "-2px",
								// }}
							/>

						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
						>
							Date of birth
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									//label="Date of Birth"
									maxDate={dayjs()}
									value={selectedDate}
									onChange={handleDateChange}
									renderInput={(params) => (
										<TextField
											{...params}
											error={Boolean(errors.DateofBirth)}
											helperText={errors.DateofBirth}
											variant="outlined"
											required
											className="qadpt-userfields"
											// sx={{
											// 	"& .MuiFormHelperText-root": {
											// 		color: errors.DateofBirth ? "red" : "inherit",
											// 	},
											// 	"& .MuiOutlinedInput-root": {
											// 		padding: "0px",
											// 	},
											// 	"& .MuiInputBase-input": {
											// 		height: "0em",
											// 	},
											// 	width: "430px",
											// 	marginBottom: "-2px",
											// }}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid
							item
							xs={12}
							sm={8}
							// className="qadpt-genderposition"
						>
							{/* <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel> */}
							Gender
							<FormControl
								variant="outlined"
								sx={{ m: 1, minWidth: 120 }}
							>
								 <ButtonGroup
    variant="outlined"
    aria-label="Basic button group"
    className="qadpt-genderbuttons"
  >
    <Button
      className={inputs.Gender === "Male" ? "selected" : ''}
      onClick={() =>
        handleGenderChange({
          target: { name: "Gender", value: "Male" },
        })
      }
      variant={inputs.Gender === "Male" ? "contained" : "outlined"}
    >
      Male
    </Button>
    <Button
      className={inputs.Gender === "Female" ? "selected" : ''}
      onClick={() =>
        handleGenderChange({
          target: { name: "Gender", value: "Female" },
        })
      }
      variant={inputs.Gender === "Female" ? "contained" : "outlined"}
    >
      Female
    </Button>
    <Button
      className={inputs.Gender === "Other" ? "selected" : ''}
      onClick={() =>
        handleGenderChange({
          target: { name: "Gender", value: "Other" },
        })
      }
      variant={inputs.Gender === "Other" ? "contained" : "outlined"}
    >
      Prefer not to share
    </Button>
  </ButtonGroup>
							</FormControl>
						</Grid>

			</Box>
			<div className="qadpt-submitbutton">
						{/* <Button
							variant="contained"
							color="primary"
							onClick={() => setShowPopup(false)}
						>
							Cancel
						</Button> */}

						<Button
							type="submit"
							variant="contained"
							disabled={isValid === false}
							// sx={{
							// 	backgroundColor: "#5F9EA0",
							// 	borderRadius: "20px",
							// 	"&:hover": {
							// 		backgroundColor: "#53868B", // Slightly darker shade for hover
							// 	},
							// }}
						>
							Submit
						</Button>
				</div>
				</form>

		</div>
	);
}

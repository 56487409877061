import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { User, UserManager } from 'oidc-client-ts';
import userManager from './UseAuth';
import { GetUserDetailsById } from '../../services/UserService';
import { LoginUserInfo } from '../../models/LoginUserInfo';
import jwt_decode from "jwt-decode";
import { User as LoginUser } from '../../models/User';
import { useNavigate, useLocation } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';

interface AuthContextType {
  user: User | null;
  userDetails: LoginUser | null;
  signIn: () => void;
  signOut: () => void;
  loggedOut: boolean;

}
let userLocalData: { [key: string]: any } = {}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}
let initialsData: string;
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [userDetails, setUserDetails] = useState<LoginUser | null>(location.state?.userDetail || null);
  const [loggedOut, setLoggedOut] = useState<boolean>(false);

  const navigate = useNavigate();
 // setUserDetails(location.state?.userDetails);
   useEffect(() => {
    if (location.pathname === "/superadmin/organizations") {
       const details = location.state?.userDetail;
       setUserDetails(details);
       const firstNameInitials =  userDetails &&  userDetails.FirstName ? userDetails.FirstName.substring(0, 1).toUpperCase() : '';
       const lastNameinitials =  userDetails &&  userDetails.LastName ? userDetails.LastName.substring(0, 1).toUpperCase() : '';
       const finalData = firstNameInitials + lastNameinitials;
       initialsData = finalData;
     } 
   }, [location]);
  

  useEffect(() => {
    const initializeUser = async () => {
      if (loggedOut) return;  // Skip reinitialization if user has logged out

      try {
        const user = await userManager.getUser();
        if (user) {
          setUser(user);
  
          if (user.access_token) {     
            const decodedToken = jwt_decode<LoginUserInfo>(user.access_token);
            const userResponse = await GetUserDetailsById(decodedToken.UserId);
            setUserDetails(userResponse?.data ?? null);
            const firstNameInitials =  userResponse?.data && userResponse?.data.FirstName ? userResponse?.data.FirstName.substring(0, 1).toUpperCase() : '';
            const lastNameinitials = userResponse?.data && userResponse?.data.LastName ? userResponse?.data.LastName.substring(0, 1).toUpperCase() : '';
            const finalData = firstNameInitials + lastNameinitials;
            initialsData = finalData;
            if (location.pathname === "/superadmin/organizations") {
              const details = location.state?.userDetail;
              setUserDetails(details ?? null);
             
            }
            localStorage.setItem("userType", userResponse?.data?.UserType ?? "");
            userLocalData["user"] = JSON.stringify(userResponse?.data)
            localStorage.setItem("userInfo", JSON.stringify(userLocalData));

          }
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
        userManager.signoutRedirect();
      }
    };
  
    initializeUser();
  
    userManager.events.addUserLoaded(async (loadedUser) => {
      if (loggedOut) return; 
      setUser(loadedUser);
      if (loadedUser.access_token) {
        const decodedToken = jwt_decode<LoginUserInfo>(loadedUser.access_token);
        const userResponse = await GetUserDetailsById(decodedToken.UserId);
        setUserDetails(userResponse?.data ?? null);
        const firstNameInitials =   userResponse?.data && userResponse?.data.FirstName ? userResponse?.data.FirstName.substring(0, 1).toUpperCase() : '';
          const lastNameinitials =  userResponse?.data && userResponse?.data.LastName ? userResponse?.data.LastName.substring(0, 1).toUpperCase() : '';
          const finalData = firstNameInitials + lastNameinitials;
          initialsData = finalData;
        userLocalData["oidc-info"] = JSON.stringify(loadedUser);    
        userLocalData["user"]= JSON.stringify(userResponse?.data )

        if (location.pathname === "/superadmin/organizations") {
          const details = location.state?.userDetail;
          setUserDetails(details);
          
        }
        localStorage.setItem("userInfo", JSON.stringify(userLocalData));

      }
    });
  
    userManager.events.addUserUnloaded(() => {
      setUser(null);
      setUserDetails(null);
      localStorage.clear();
      document.cookie.split(";").forEach(cookie => {
        const [name] = cookie.split("=");
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
      setLoggedOut(true);  // Indicate the user has logged out
      const redirectPath = process.env.REACT_APP_IDS_API;
      if (redirectPath) {
        window.location.href = redirectPath;
      }
    });
  }, []);

  const signOut = () => {
    const logeduserType = localStorage.getItem('userType');
    if (logeduserType?.toLowerCase()!== "superadmin") {      
      setUser(null);
      setUserDetails(null);
      localStorage.clear();
      document.cookie.split(";").forEach(cookie => {
        const [name] = cookie.split("=");
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
      setLoggedOut(true);  
      localStorage.setItem('logout-event', Date.now().toString());
      sessionStorage.clear()
      userManager.signoutRedirect();    
    }
    else {      
      sessionStorage.clear()
      navigate("/admin/adminlogin")
    }
  };
  
  const signIn = () => {
    setLoggedOut(false);  
    userManager.signinRedirect();
  };

  return (
    <AuthContext.Provider value={{ user, userDetails, signIn, signOut,loggedOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  let context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  if (context?.user) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
    userLocalData["oidc-info"] = JSON.stringify(context.user);    

    if (userInfo['user']) {
      userLocalData["user"] =  JSON.stringify(userInfo['user'])
    }   
  } 
  else {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
    if (userInfo['oidc-info'] && userInfo['user']) {
      context = { ...context, user: JSON.parse(userInfo['oidc-info']) };
      context.userDetails =  JSON.parse(userInfo['user'])
      context.loggedOut = false;
    }
  }

  return context;
};

export {initialsData}
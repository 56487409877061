import React, { useState, useRef } from 'react';
import {
    Container, Typography, Button,
    TextField, IconButton, InputAdornment,
    RadioGroup, FormControlLabel, Radio,
    Checkbox, FormControl, FormLabel,
    Avatar, CircularProgress
} from '@mui/material';
import { Visibility, VisibilityOff, CameraAlt, ArrowBack } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import Registraionimage from '../../assets/icons/quickadopt.svg';
import { Status } from '../../models/Status';
import { registerUser } from '../../services/Signup';

const RegistrationPage: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [firstnameError, setFirstNameError] = useState('');
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [agreed, setAgreed] = useState(false);
    const [step, setStep] = useState(1);
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [contactNumberError, setContactNumberError] = useState('');
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };




    const handleNext = () => {
        const passwordValidationError = validatePassword(password);
        if (step === 1 && firstName && lastName && email && password && !passwordValidationError) {
            setStep(2);
        } else {
            if (!firstName || !lastName || !email || !password) {
                setErrorMessage('Please fill in all required fields.');
            }
            if (passwordValidationError) {
                setPasswordError(passwordValidationError);
            }
        }
    };

    const generateCustomUserId = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const datePart = `${day}${month}${year}`;
        const guidPart = uuidv4();
        return `${datePart}-${guidPart}`;
    };

    const handleSubmit = async () => {
        const contactNumberValidationError = validateContactNumber(contactNumber);
        if (contactNumberValidationError) {
            setContactNumberError(contactNumberValidationError);
        }
        if (step === 2 && contactNumber && dateOfBirth && gender && agreed && !contactNumberValidationError) {
            setLoading(true);
            setErrorMessage('');
            setSuccessMessage('');

            const reqObj = {
                UserId: generateCustomUserId(),
                FirstName: firstName,
                LastName: lastName,
                UserName: email,
                EmailId: email,
                Password: password,
                ContactNumber: contactNumber,
                ProfilePhoto: selectedImage ? await toBase64(selectedImage) : '',
                UserType: 'User',
                Gender: gender,
                DateofBirth: dateOfBirth,
                TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                RTL: false,
                LoginType: 'Standard',
                EmailConfirmed: false,
                AdminDeactivated: false,
            };

            try {
                const status: Status = await registerUser(reqObj);
                setLoading(false);

                if (status.success) {
                    setSuccessMessage(status.successMessage || 'Registered successfully');
                } else {
                    setErrorMessage(status.errorMessage || 'Registration failed');
                }
            } catch (error) {
                setLoading(false);
                setErrorMessage('An error occurred. Please try again.');
            }
        } else {
            setErrorMessage('Please fill in all required fields.');
        }
    };

    const handleCameraClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(event.target.files[0]);
        }
    };

    const toBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };
    const validatePassword = (password: string) => {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        if (!regex.test(password)) {
            return 'Password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character.';
        }
        return '';
    };
    const handlePasswordBlur = () => {
        setPasswordError(validatePassword(password));
    };
    const ValidateEmailAddress = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(email)) {
            return 'Invalid email address format.';
        }
        const allowedDomains = ['gmail.com', 'outlook.com'];
        const domain = email.split('@')[1];
        if (!allowedDomains.includes(domain)) {
            return 'Only @gmail.com and @outlook.com addresses are allowed.';
        }

        return '';
    }

    const handleEmailAddressBlur = () => {
        setEmailError(ValidateEmailAddress(email));
    }
    const validateContactNumber = (contactNumber: string) => {
        const regex = /^\d{10}$/;
        if (!regex.test(contactNumber)) {
            return 'Contact number must be exactly 10 digits.';
        }
        return '';
    };
    const handleContactNumberBlur = () => {
        setContactNumberError(validateContactNumber(contactNumber));
    };

    const validateFirstName = (firstName: string) => {
        const regex = /^[a-zA-Z]{3,}$/;
        if (!regex.test(firstName)) {
            return 'First Name should contain  at least 3 characters long.';
        }
        return '';
    };

    const handleFirstNameBlur = () => {
        setFirstNameError(validateFirstName(firstName));
    }
    const validateLastName = (lastName: string) => {
        const regex = /^[a-zA-Z]{3,}$/;
        if (!regex.test(firstName)) {
            return 'Last Name should contain  at least 3 characters long.';
        }
        return '';
    };
    const handleLastNameBlur = () => {
        setLastNameError(validateLastName(lastName));
    }


    return (
        <React.Fragment>
            <Container component="main" maxWidth="lg" style={{ position: 'relative', height: '100vh' }}>
                {step === 1 ? (
                    <div style={{ position: 'absolute', top: '70px', left: '955px' }}>
                        <Typography color="textSecondary">
                            Already have an account?{' '}
                            <Link to="/login" style={{ color: 'blue', textDecoration: 'none' }}>Sign In</Link>
                        </Typography>
                    </div>
                ) : ""}
                <div className='lside-bgimg'>
                    <img
                        src={Registraionimage}
                        alt="Organization Logo"
                        style={{
                            position: 'absolute',
                            top: "70px",
                            left: "16px",
                            width: '254px'
                        }}
                    />
                </div>
                <div className="circleOne" />
                <div className="circleTwo" />
                <div className="circleThree" />
                <div className="circleFour" />
                <div className="circleFive" />
                <div className="circleSix" />
                <div style={{ position: 'absolute', top: '57%', right: '11%', transform: 'translateY(-50%)', width: '30%' }}>
                    {step === 1 ? (
                        <>
                            <Typography variant="h5" align="left">
                                Register
                            </Typography>
                            <Typography variant="body2" align="left" color="textSecondary" fontSize="15px" style={{ marginTop: '8px' }}>
                                Please tell a bit about you, so that we can register your account.
                            </Typography>
                            <form noValidate>
                                <div style={{ display: 'flex', gap: '16px' }}>
                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        autoComplete="given-name"
                                        required
                                        autoFocus
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            setFirstNameError(validateFirstName(e.target.value));
                                        }}
                                        error={!!firstnameError}
                                        helperText={firstnameError}
                                        onBlur={handleFirstNameBlur}
                                    />
                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        required
                                        autoComplete="family-name"
                                        value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            setLastNameError(validateFirstName(e.target.value));
                                        }}
                                        error={!!lastNameError}
                                        helperText={lastNameError}
                                        onBlur={handleLastNameBlur}
                                    />
                                </div>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    required
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError(ValidateEmailAddress(e.target.value));
                                    }}
                                    error={!!emailError}
                                    helperText={emailError}
                                    onBlur={handleEmailAddressBlur}
                                />
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="new-password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordError(validatePassword(e.target.value));
                                    }}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    onBlur={handlePasswordBlur}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    style={{ margin: '24px 0px 16px' }}
                                >
                                    Next
                                </Button>
                            </form>
                        </>
                    ) : (
                        <>
                            <Typography variant="h5" align="left">
                                Setup your profile
                            </Typography>
                            <Typography variant="body2" align="left" color="textSecondary" fontSize="15px" style={{ marginTop: '8px' }}>
                                Please tell a bit about you, so that we can register your account.
                            </Typography>
                            <form noValidate>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                    <div style={{ marginRight: '16px' }}>
                                        <IconButton color="primary" onClick={handleCameraClick}>
                                            {selectedImage && (
                                                <div style={{ marginTop: '16px' }}>
                                                    <Avatar
                                                        src={URL.createObjectURL(selectedImage)}
                                                        alt="Selected"
                                                        style={{ width: '54px', height: '54px' }}
                                                    />
                                                </div>
                                            )}
                                            <CameraAlt />
                                        </IconButton>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <div>
                                        <Typography variant="body2" color="Primary">
                                            Upload your photo
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Photos help your teammates recognize you in quixy.
                                        </Typography>
                                    </div>
                                </div>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    id="contactNumber"
                                    label="Contact Number"
                                    name="contactNumber"
                                    required
                                    value={contactNumber}
                                    onChange={(e) => {
                                        setContactNumber(e.target.value);
                                        setContactNumberError(validateContactNumber(e.target.value));
                                    }}
                                    error={!!contactNumberError}
                                    helperText={contactNumberError}
                                    onBlur={handleContactNumberBlur}
                                />
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    id="dateOfBirth"
                                    label="Date of Birth"
                                    name="dateOfBirth"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    value={dateOfBirth}
                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                />
                                <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup row aria-label="gender" name="gender" value={gender} onChange={(e) => setGender((e.target as HTMLInputElement).value)}>
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)} name="agreed" />}
                                    label={<Typography>I agree with the <Link to="/terms" style={{ color: 'blue', textDecoration: 'none' }}>Terms and Conditions</Link></Typography>}
                                    style={{ marginTop: '16px' }}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    style={{ margin: '24px 0px 16px' }}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Create account'}
                                </Button>
                                <IconButton style={{ position: 'absolute', top: '0px', left: '-142px' }} color="primary" onClick={() => setStep(1)}>
                                    <ArrowBack />
                                </IconButton>
                            </form>
                        </>
                    )}
                </div>
            </Container>
        </React.Fragment>
    );
};

export default RegistrationPage;

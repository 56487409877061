import React, { useState, useEffect } from "react";
import CreateAccount from "./AccountCreate";
import {
	GridColDef,
	GridRenderCellParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	DataGrid,
	GridPaginationModel,
	GridColumnMenuProps,
} from "@mui/x-data-grid";
import { Button, Menu, MenuItem, FormControlLabel, IconButton, Snackbar, Alert, Box, TextField,	Container, } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import loader from "../../assets/loader.gif";
import CustomGrid from "../common/Grid";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
//import { AccountData } from "./AccountData";
import EditAccount from "./AccountEdit";
import { GetAllAccounts, fetchDeleteAccountDetails, GetAccountsList } from "../../services/AccountService";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { organizationsList } from "../organization/orgData";
import CustomColumnMenu from "../CustomColumnMenu";
import AccountCustomColumnMenu from "./AccountsColumnMenu";
import { useSnackbar } from "../../SnackbarContext";
import styles from "./AccountStle.module.scss";
// import { OrganizationId } from "../common/Home";
import { OrganizationId } from "../common/Home";
import FilterPopup from "../settings/Filterpopup";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

type InputFields = {
	AccountName: string;
	SearchInput: string;
};
type ErrorFields = Partial<InputFields>;
interface CustomDataGridProps extends React.ComponentProps<typeof DataGrid> {
	components?: {
		columnMenu?: React.ComponentType<GridColumnMenuProps>;
	};
}

interface Model {
	AccountName: string | null;
	AdminType: string;
	AccountId: string;
}
// type InputFields = {
// 	AccountId: string,
// 	AccountName: string,
// 	AccountType: string,
// 	CreatedBy: string,
// 	CreatedDate: string,
// 	Organizationid: string,
// 	UpdatedBy: string,
// 	UpdatedDate: string,
// 	Active: Boolean,
// };
let email: any;

const AccountList = () => {
	const [showPopup, setShowPopup] = useState(false);
	const [models, setModels] = useState<Model[]>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [loading, setLoading] = useState(false);
	const [accountidedit, setAccountIdEdit] = useState("");
	const [showeditPopup, setShowEditPopup] = useState(false);
	const [emailiddelete, setemailiddelete] = useState("");
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const storedOrganizationString = localStorage.getItem("organization");
	const storedOrganization = storedOrganizationString ? JSON.parse(storedOrganizationString) : null;
	const Organizationid = OrganizationId;
	const [skip, setskip] = useState(0);
	const [top, settop] = useState(0);
	const [totalcount, setTotalcount] = useState(0);
	const [searchText, setSearchText] = useState("");
	const { openSnackbar } = useSnackbar();
	const [orderByFields, setOrderByFields] = useState("");
	const [inputs, setInputs] = useState<InputFields>({
		AccountName: "",
		SearchInput: "",
	});
	const [sortModel, setSortModel] = useState([]);
	const [filters, setFilters] = useState([]);
	const handleSortModelChange = (model: any) => {
		setSortModel(model);
		const orderByField = model
			.map((item: any) => (item.sort === "desc" ? `${item.field} desc` : item.field))
			.join(", ");
		//console.log(orderByField);
		setOrderByFields(orderByField);
		// Fetch data with new sort order
		//GetAccountsList(setFilteredRows, setLoading, Organizationid, paginationModel.page * paginationModel.pageSize, paginationModel.pageSize, setTotalcount, orderByFields);
		GetAccountsList(setModels, setLoading, Organizationid, skip, top, setTotalcount, orderByField, filters);
	};
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: 15,
	});
	const [errors, setErrors] = useState<ErrorFields>({
		AccountName: "",
	});
	const [AccountDeleteDetails, setAccountDeleteDetails] = useState({
		AccountId: "",
		AccountName: "",
		AccountType: "",
		CreatedBy: "",
		CreatedDate: "",
		Organizationid: "",
		UpdatedBy: "",
		UpdatedDate: "",
		Active: Boolean(true),
	});

	// useEffect(() => {
	// 	setModels(AccountData.data);
	// }, []);

	const FetchAccounts = () => {
		setLoading(true);
		const skipcount = paginationModel.pageSize || 15;
		//console.log(paginationModel.pageSize, "pageSize");
		const limitcount = paginationModel.page * skipcount;
		const skips = limitcount;
		const top = paginationModel.pageSize;
		setskip(skips);
		settop(top);
		GetAccountsList(setModels, setLoading, Organizationid, skips, top, setTotalcount, orderByFields, filters);
	};

	useEffect(() => {
		setLoading(true);
		//GetAllAccounts(setModels, setLoading);
		FetchAccounts();
	}, [paginationModel]);

	useEffect(() => {
		if (models.length !== 0) {
			setLoading(false);
		}
	}, [models]);

	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
	}, []);

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const MatEdit = (number: any) => {
		const handleMailClick = (type: any) => {
			// some action
			//console.log(type);
		};

		const handleDeleteClick = (emailId: string, accountid: string) => {
			//.log("delete");
			setShowDeletePopup(true);
			//console.log(emailId);
			setemailiddelete(emailId);
			setAccountIdEdit(accountid);
		};
		const handleeditclick = (accountid: string) => {
			//console.log("edit");
			setShowEditPopup(true);
			//console.log(accountid);
			setAccountIdEdit(accountid);
		};
		const handleClose = () => {};

		return (
			<div>
				{/* <FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => handleMailClick("mail")}
						>
							{number.emailConfirmed === true ? (
								<MarkEmailReadIcon style={{ color: "blue" }} />
							) : (
								<MailIcon style={{ color: "blue" }} />
							)}
						</IconButton>
					}
					label={""}
				/> */}
				<FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => {
								//openEditPopup();
								handleeditclick(number.accountid);
							}}
						>
							<BorderColorOutlinedIcon style={{ color: "#7A7B7D" }} />
						</IconButton>
					}
					label={""}
				/>
				<FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => handleDeleteClick(number.emailId, number.accountid)}
						>
							<DeleteIcon style={{ color: "#7A7B7D" }} />
						</IconButton>
					}
					label={""}
				/>
			</div>
		);
	};
	const columns: GridColDef[] = [
		// {
		// 	field: "AccountId",
		// 	headerName: "Account Id",
		// 	width: 150,
		// },
		{
			field: "AccountName",
			headerName: "Account Name",
			width: 350,
			flex: 1,
			disableColumnMenu: true,
			resizable: false,
		},
		{
			field: "CreatedDate",
			headerName: "Created Date",
			width: 350,
			flex: 1,
			disableColumnMenu: true,
			renderCell: (params) => {
				const createdDate = params.value ? new Date(params.value) : null;
				return createdDate ? createdDate.toLocaleDateString('en-GB') : "";
			  },
			resizable: false,
		},

		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 200,
			flex: 1,
			disableColumnMenu: true,
			resizable: false,
			renderCell: (params) => {
				//const emailConfirmed = params.row.CreatedBy || false;
				const emailId = params.row.CreatedBy || false;
				const accountid = params.row.AccountId || false;
				email = params.row.AccountName;
				console.log(email);
				return (
					<div
					// className="d-flex justify-content-between align-items-center"
					// style={{ cursor: "pointer" }}
					>
						<MatEdit
							//emailConfirmed={emailConfirmed}
							emailId={emailId}
							index={params.row.id}
							accountid={accountid}
						/>
					</div>
				);
			},
		},
	];

	const onPageChange = (newPage: number) => {
		//console.log("Page changed to:", newPage);
	};
	const onPageSizeChange = (newPageSize: number) => {
		//console.log("Page size changed to:", newPageSize);
	};
	const handleDownloadExcel = async () => {
		try {
			// const response = await fetchorganizationlistData()
			// const url = process.env.REACT_APP_BASE_URL
			// if (response.Msg_Code === "802.171.251.01" && response.File_Path) {
			// 	const downloadLink = document.createElement("a")
			// 	downloadLink.href = `${url}${response.File_Path}`
			// 	downloadLink.download = "excel_data.xlsx"
			// 	document.body.appendChild(downloadLink)
			// 	downloadLink.click()
			// 	document.body.removeChild(downloadLink)
			// } else {
			// 	console.error("Invalid response from the server:", response)
			// 	throw new Error("Invalid response from the server")
			// }
		} catch (error) {
			console.error("Error downloading Excel:", error);
		}
	};
	// useEffect(() => {
	// 	if (showDeletePopup) {
	// 		fetchAccountDetails(accountidedit);
	// 	}
	// }, [showDeletePopup]);
	// const fetchAccountDetails = async (id: any) => {
	// 	try {
	// 		const response = await fetch(`${adminUrl}/Account/GetAccountById?accountId=${id}`);
	// 		if (!response.ok) {
	// 			throw new Error("Network response was not ok");
	// 		}
	// 		const data = await response.json();
	// 		setAccountDeleteDetails({
	// 			AccountId: data.AccountId,
	// 			AccountName: data.AccountName,
	// 			AccountType: data.AccountType,
	// 			CreatedBy: data.CreatedBy,
	// 			CreatedDate: data.CreatedDate,
	// 			Organizationid: data.Organizationid,
	// 			UpdatedBy: data.UpdatedBy,
	// 			UpdatedDate: data.UpdatedDate,
	// 			Active: false,
	// 		});
	// 	} catch (error) {
	// 		console.error("Failed to fetch user details:", error);
	// 	}
	// };

	const handleDeleteAccount = () => {
		fetchDeleteAccountDetails(
			accountidedit,
			setLoading,
			setModels,
			setShowDeletePopup,
			Organizationid,
			skip,
			top,
			setTotalcount,
			setSnackbarMessage,
			setSnackbarSeverity,
			setSnackbarOpen,
			openSnackbar,
			orderByFields,
			filters
		);
		//DeleteAccountDetails(setLoading, setModels, setShowDeletePopup, AccountDeleteDetails);
	};

	const CustomToolbar: React.FC<any> = () => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleExportMenuClose = () => {
			setAnchorEl(null);
		};

		const handleDownloadExcelClick = () => {
			handleExportMenuClose();
		};

		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
				<Button
					aria-controls="export-menu"
					aria-haspopup="true"
					onClick={handleExportMenuClick}
					style={{ marginLeft: "10px" }}
					startIcon={<SaveAltIcon />}
				>
					Export
				</Button>
				<Menu
					id="export-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleExportMenuClose}
				>
					<MenuItem
						onClick={() => {
							handleDownloadExcel();
							handleExportMenuClose();
						}}
					>
						Download Excel
					</MenuItem>
				</Menu>
			</div>
		);
	};

	console.log();
	const openPopup = () => {
		setShowPopup(true);
	};
	const handleSearch = (value: string[]) => {
		setSearchText(value.join(" "));
	};

	const globalhandleSearch = (searchValue: any) => {
		const newFilter = {
			FieldName: "AccountName",
			ElementType: "string",
			Condition: "contains",
			Value: searchValue,
			IsCustomField: false,
		};
		let skips = 0;
		let top = 0;
		const skipCount = paginationModel.pageSize || 15;
		const limitCount = paginationModel.page * skipCount;
		top = paginationModel.pageSize;
		skips = limitCount;
		setskip(skips);
		settop(top);

		try {
			GetAccountsList(setModels, setLoading, Organizationid, skip, top, setTotalcount, orderByFields, [newFilter]);
		} catch (error) {
			console.error("Failed to fetch user roles:", error);
		}
	};
	const clearSearch = () => {
		setInputs({ ...inputs, SearchInput: "" });
		GetAccountsList(setModels, setLoading, Organizationid, skip, top, setTotalcount, orderByFields, filters);
	};
	const columnNames = columns.map((col) => col.headerName);
	const actionColumns = ["Actions", "Full Name"];
	const filteredColumnNames = columnNames
		.filter((name): name is string => name !== undefined && !actionColumns.includes(name))
		.map((name) => (name === "Role Name" ? name.replace(/\s+/g, "") : name));
	const handleApplyFilters = async (filters: any) => {
		// if (selectedAccount) {
		// 	setFilters(filters);
		// 	let skips: number = 0;
		// 	let top: number = 0;
		// 	const skipcount = paginationModel.pageSize || 15;
		// 	const limitcount = paginationModel.page * skipcount;
		// 	skips = paginationModel.pageSize;
		// 	top = limitcount;
		// 	const formattedFilters = filters.map((filter: any) => ({
		// 		FieldName: filter.column,
		// 		ElementType: "string",
		// 		Condition: filter.operator,
		// 		Value: filter.value,
		// 		IsCustomField: false,
		// 	}));
		// 	setskip(skips);
		// 	settop(top);
		// 	const orderByFields = "";
		// 	try {
		// 		("");
		// 	} catch (error) {
		// 		console.error("Failed to fetch user roles:", error);
		// 	}
		// } else {
		// 	setFilters(filters);
		// 	let skips: number = 0;
		// 	let top: number = 0;
		// 	const skipcount = paginationModel.pageSize || 15;
		// 	const limitcount = paginationModel.pageSize;
		// 	skips = paginationModel.page * skipcount;
		// 	top = limitcount;
		// 	const formattedFilters = filters.map((filter: any) => ({
		// 		FieldName: filter.column,
		// 		ElementType: "string",
		// 		Condition: filter.operator,
		// 		Value: filter.value,
		// 		IsCustomField: false,
		// 	}));
		// 	setskip(skips);
		// 	settop(top);
		// 	const orderByFields = "";
		// 	try {
		// 		("");
		// 	} catch (error) {
		// 		console.error("Failed to fetch user roles:", error);
		// 	}
		// }
	};

	return (
		<Container>
			<div className="qadpt-midpart setng-box">
				<div className="qadpt-content-block">
					<div>
						<div className="qadpt-head">
							<div className="qadpt-title-sec">
								<div className="qadpt-title">Account</div>
								<div className="qadpt-description">view & Manage your accounts here</div>
							</div>
							<div className="qadpt-right-part">
								<button
									onClick={openPopup}
									className="qadpt-memberButton"
								>
									<i className="fal fa-add-plus"></i>
									<span>Create Account</span>
								</button>
							</div>
							<div></div>
						</div>
						<div>
							{showPopup ? (
								<CreateAccount
									setModels={setModels}
									setLoading={setLoading}
									showPopup={showPopup}
									setShowPopup={setShowPopup}
									orderByField={orderByFields}
								/>
							) : (
								""
							)}
						</div>

						{showeditPopup ? (
							<EditAccount
								showEditPopup={showeditPopup}
								setShowEditPopup={setShowEditPopup}
								accountidedit={accountidedit}
								GetAllAccounts={GetAllAccounts}
								setModels={setModels}
								setLoading={setLoading}
								Organizationid={Organizationid}
								skip={skip}
								top={top}
								setTotalcount={setTotalcount}
								orderByField={orderByFields}
								filters={filters}
								setFilters={setFilters}
							/>
						) : (
							""
						)}

						<div>
							<Box className="qadpt-content-box">
								<div className="qadpt-src-flt">
									<div>
									<TextField
											className={`qadpt-teamsearch ${errors.SearchInput ? "qadpt-teamsearch-error" : ""}`}
											// style={{ right: "-650px" }}
											name="SearchInput"
											value={inputs.SearchInput}
											onChange={(e) => {
												const newValue = e.target.value;
												setInputs({ ...inputs, SearchInput: newValue });
												if (newValue === "") {
													clearSearch();
													GetAccountsList(
														setModels,
														setLoading,
														Organizationid,
														skip,
														top,
														setTotalcount,
														orderByFields,
														filters
													);
												}
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
												  globalhandleSearch(inputs.SearchInput);
												}
											  }}
											placeholder="Search accounts"
											variant="outlined"
											error={!!errors.SearchInput}
											// sx={{
											// 	"& .MuiFormHelperText-root": {
											// 		color: errors.SearchInput ? "red" : "inherit",
											// 	},
											// 	"& .MuiOutlinedInput-root": {
											// 		padding: "0px",
											// 		borderRadius: "20px",
											// 	},
											// 	"& .MuiInputBase-input": {
											// 		height: "1em",
											// 		paddingLeft: "0px",
											// 	},
											// 	width: "200px",
											// 	marginBottom: "8px",
											// }}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton
															aria-label="search"
															onClick={() => globalhandleSearch(inputs.SearchInput)}
															onMouseDown={(event) => event.preventDefault()}
														>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												endAdornment: inputs.SearchInput && (
													<InputAdornment position="end">
														<IconButton
															aria-label="clear"
															onClick={() => {
																setInputs({ ...inputs, SearchInput: "" });
																clearSearch();
																GetAccountsList(
																	setModels,
																	setLoading,
																	Organizationid,
																	skip,
																	top,
																	setTotalcount,
																	orderByFields,
																	filters
																);
															}}
														>
															<ClearIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</div>{" "}
								</div>
								<DataGrid
									className="qadpt-account-grd"
									rows={models.length > 0 ? models : []} // Display models if available
									columns={columns}
									pagination
									paginationMode="server"
									slots={{
										columnMenu: (menuProps) =>
											menuProps.colDef.field === "AccountName" ? (
												<AccountCustomColumnMenu
													column={menuProps.colDef.field}
													setModels={setModels}
													setLoading={setLoading}
													skip={skip}
													top={top}
													OrganizationId={Organizationid}
													setTotalcount={setTotalcount}
													orderByFields={orderByFields}
													filters={filters}
													setFilters={setFilters}
													{...menuProps}
													options={models.map((model: any) => model.AccountName || "")}
													onSearch={handleSearch}
												/>
											) : null,
									}}
									getRowId={(row) => row.AccountId}
									paginationModel={paginationModel}
									onPaginationModelChange={(model) => {
										setPaginationModel(model);
										// FetchAccounts();
									}}
									rowCount={totalcount}
									pageSizeOptions={[15, 25, 50, 100]}
									localeText={{
										MuiTablePagination: {
											labelRowsPerPage: "Records Per Page",
										},
									}}
									disableRowSelectionOnClick={true}
									//loading={loading} // To show the DataGrid's built-in loading overlay
									sortModel={sortModel}
									onSortModelChange={handleSortModelChange}
								/>
								{models.length === 0 && (
									<div
										style={{
											position: "absolute",
											top: "50%",
											left: "50%",
											transform: "translate(-50%, -50%)",
											zIndex: 1,
										}}
									>
										<img
											src={loader}
											alt="Spinner"
											style={{
												width: "45px",
												height: "45px",
											}}
										/>
									</div>
								)}
							</Box>
						</div>

						{showDeletePopup ? (
							// <div className="AccountDelete-popup">
							// 	<div style={{ marginBottom: "20px" }}>
							// 		<div
							// 			style={{
							// 				width: "50px",
							// 				height: "50px",
							// 				backgroundColor: "#f8d7da",
							// 				borderRadius: "50%",
							// 				display: "flex",
							// 				alignItems: "center",
							// 				justifyContent: "center",
							// 				margin: "0 auto",
							// 			}}
							// 		>
							// 			<svg
							// 				xmlns="http://www.w3.org/2000/svg"
							// 				fill="#d05353"
							// 				width="24"
							// 				height="24"
							// 				viewBox="0 0 24 24"
							// 			>
							// 				<path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm0 15.5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 17.5Zm1-7.5h-2V7h2Z" />
							// 			</svg>
							// 		</div>
							// 		<h3 style={{ margin: "10px 0", fontSize: "18px", color: "#333" }}>Delete Account</h3>
							// 	</div>
							// 	<div style={{ width: "330px" }}>
							// 		<p style={{ backgroundColor: "#e4b6b0" }}>
							// 			The Account {email} cannot be restored once it is deleted ?
							// 		</p>

							// 		<button
							// 			style={{
							// 				position: "relative",
							// 				background: "#fff",
							// 				color: "#007bff",
							// 				border: "1px solid #007bff",
							// 				marginRight: "27px",
							// 				right: "-30px",
							// 				top: "-10px",
							// 			}}
							// 			onClick={() => setShowDeletePopup(false)}
							// 			className="userButton"
							// 		>
							// 			Cancel
							// 		</button>

							// 		<button
							// 			style={{
							// 				position: "relative",
							// 				right: "-80px",
							// 				top: "-10px",
							// 				color: "white",
							// 				backgroundColor: "#D05353",
							// 			}}
							// 			className="userButton"
							// 			onClick={handleDeleteAccount}
							// 		>
							// 			Delete
							// 		</button>
							// 	</div>
							// </div>
							<div className="qadpt-acctdelete-popup">
							<div>
								<div className="qadpt-delete-icon">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="#d05353"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm0 15.5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 17.5Zm1-7.5h-2V7h2Z" />
									</svg>
								</div>
								<div className="qadpt-delete-title">Delete Account</div>
							</div>
						
								<div className="qadpt-delete-warning">
									The Account {email} cannot be restored once it is deleted.
								</div>
								<div className="qadpt-buttons">
  									<button
   										 onClick={() => setShowDeletePopup(false)}
    									 className="qadpt-cancel-button"
  										>
    									Cancel
  									</button>

  									<button
    									className="qadpt-delete-button"
    									onClick={handleDeleteAccount}
  									>
   									 Delete
  									</button>
								</div>

						</div>
						
						) : (
							""
						)}
					</div>
					<Snackbar
						style={{ zIndex: "9999", position: "relative", top: "-500px", width: "500px", right: "-600px" }}
						open={snackbarOpen}
						autoHideDuration={4000}
						onClose={handleSnackbarClose}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
					>
						<Alert
							onClose={handleSnackbarClose}
							severity={snackbarSeverity}
							sx={{ width: "100%" }}
						>
							{snackbarMessage}
						</Alert>
					</Snackbar>
				</div>
			</div>
		</Container>
	);
};

export default AccountList;
import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(180deg, rgba(246, 238, 238, 1) 0%, rgba(246, 238, 238, 1) 100%)',
        overflow: 'hidden',
        display: 'flex',
        // flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
